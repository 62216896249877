import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-page-header-container',
  templateUrl: './page-header-container.component.html',
  styleUrls: [],
})
export class PageHeaderContainerComponent {
  @Input() displayHr = true;
}
