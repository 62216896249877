<shared-modal>
  <ng-container modalHeader>
    <h4 class="modal-title">Send Activation Link</h4>
  </ng-container>
  <ng-container modalBody>
    <div class="m-b-16">
      <p>Send <b>{{userName}}</b> the following link to sign in and active their account:</p>
    </div>
    <p><i class="text-break-all">{{link}}</i></p>
    <div class="btn-group-flex">
      <button type="button" (click)="close()" class="btn btn-cancel">Cancel</button>
      <button type="button" (click)="copyLink()" class="btn btn-primary">Copy link</button>
    </div>
  </ng-container>
</shared-modal>
