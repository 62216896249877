export class ItemStatistics {
  casesCount: number;
  casesCountPercentage: number;
  maxRepetition: number;
  averageRepetition: number;
  totalDuration: number;
  meanDuration: number;
  meanDurationPercentage: number;
  minDuration: number;
  maxDuration: number;
  averagePrice: number;
  averagePricePercentage: number;
  totalYearCosts: number;
}
