<svg *ngIf="isInProgress" viewBox="0 0 32 32" class="progress__svg">
  <circle [attr.r]="radius" cx="16" cy="16" stroke-width="8" class="progress__meter" />
  <circle [style.strokeDasharray]="circumference" [style.strokeDashoffset]="(1 - totalProgress) * circumference" [attr.r]="radius" cx="16" cy="16" stroke-width="8" class="progress__value" />
</svg>

<svg-icon *ngIf="status === ProcessingItemStatus.Uploaded" key="uploaded" class="color-success"></svg-icon>
<svg-icon *ngIf="status === ProcessingItemStatus.Successful || status === ProcessingItemStatus.DeletedSuccessfully" key="done-circle" class="color-success"></svg-icon>
<svg-icon
  *ngIf="status === ProcessingItemStatus.Error || status === ProcessingItemStatus.Deleted"
  key="error-circle"
  [class.color-warning]="isFatalError === false"
  [class.color-danger]="isFatalError !== false"
></svg-icon>
<svg-icon *ngIf="status === ProcessingItemStatus.Cancelled" key="canceled-circle" class="color-danger"></svg-icon>
