import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-empty-data-filter',
  templateUrl: './empty-data-filter.component.html',
  styleUrls: ['./empty-data-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmptyDataFilterComponent {
  @Input() dateLabel: string;
  @Input() minHeight: string;
}
