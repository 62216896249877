<div class="flex flex-row flex-gap-16-8" *ngIf="filterDefinition$ | async as filterDefinition">
  <div *ngFor="let filter of filterDefinition.filters" class="select-content-width">
    <shared-select-with-search
      *ngIf="filter.type === FilterItemType.Select || filter.type === FilterItemType.MultiSelect"
      selectClass="pointee-mat-select"
      [multiple]="filter.type === FilterItemType.MultiSelect"
      [selectAllText]="filter.allItemsLabel"
      [placeholder]="filter.placeholder"
      [items]="filterSelectionMap.get(filter.name).items"
      [fixedItems]="filterSelectionMap.get(filter.name).fixedItems"
      [selection]="filterSelectionMap.get(filter.name).selection"
      (selectionChange)="onFilterValueChanged($event, filter)"
      (closed)="onFilterClosed($event, filter)"
    ></shared-select-with-search>
  </div>
</div>
