import { Injectable } from '@angular/core';
import { ApiConversionHelper } from '../../helpers/api-conversion.helper';
import { DateHelper } from '../../helpers/date.helper';
import { MetricFilters } from '../../types/metric-filters.type';
import { ProcessVersion } from '../../types/process-version.type';
import { BaseApiService } from './base-api.service';

@Injectable({ providedIn: 'root' })
export class ProcessVersionsApiService extends BaseApiService {
  protected get baseUrl(): string {
    return `${super.baseUrl}/process-versions`;
  }

  async getProcessVersions(from: Date, to: Date, filters?: MetricFilters): Promise<ProcessVersion[]> {
    const result = await this.get<ProcessVersion[]>(`${DateHelper.formatApiDateOnly(from)}/${DateHelper.formatApiDateOnly(to)}`, filters);
    ApiConversionHelper.convertDates(result, ['timeStamp']);
    return result;
  }
}
