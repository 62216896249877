export class MapHelper {
  static filter<TKey, TValue>(map: Map<TKey, TValue>, predicate: (value: TValue) => boolean): TValue[] {
    const result: TValue[] = [];
    map.forEach(v => {
      if (predicate(v)) {
        result.push(v);
      }
    });
    return result;
  }

  static reduce<TKey, TValue, TResult>(map: Map<TKey, TValue>, func: (a: TResult, b: TValue) => TResult, initialValue: TResult): TResult {
    let result = initialValue;
    map.forEach(v => (result = func(result, v)));
    return result;
  }

  static every<TKey, TValue>(map: Map<TKey, TValue>, predicate: (value: TValue) => boolean): boolean {
    let result = true;
    map.forEach(v => (result = result && predicate(v)));
    return result;
  }

  static map<TKey, TValue, TResult>(map: Map<TKey, TValue>, func: (value: TValue) => TResult): TResult[] {
    const result: TResult[] = [];
    map.forEach(v => result.push(func(v)));
    return result;
  }

  static toArray<TKey, TValue>(map: Map<TKey, TValue>): TValue[] {
    return !map ? [] : [...map.values()];
  }

  static toDictionary<TKey, TValue>(array: TValue[], getKey: (i: TValue) => TKey): Map<TKey, TValue> {
    const dictionary = new Map<TKey, TValue>();
    if (!array || array.length === 0) return dictionary;
    array.forEach(i => dictionary.set(getKey(i), i));
    return dictionary;
  }
}
