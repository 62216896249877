export enum StatusType {
  Success = 'Success',
  Completed = 'Completed',
  Failed = 'Failed',
  Pending = 'Pending',
  Running = 'Running',
  Working = 'Working',
  Stopped = 'Stopped',
  Unknown = 'Unknown',
  Offline = 'Offline',
  Available = 'Available',
  NotResponding = 'NotResponding',
  Terminated = 'Terminated',
  Debugging = 'Debugging',
  Archived = 'Archived',
  Stopping = 'Stopping',
  Warning = 'Warning',
}
