<div class="graph-legend" *ngIf="data && data.legend && data.legend.length > 0">
  <div class="legend-item" *ngFor="let legend of orderedLegend, let i = index ">
    <span class="pointee-colored-circle" [style.background-color]="data.colors[i]" *ngIf="!data.invertLegendOrder"></span>
    <span class="pointee-colored-circle" [style.background-color]="data.colors[data.colors.length - i -1]" *ngIf="data.invertLegendOrder"></span>
    <span class="legend-text" [tooltip]="orderedLegend[i]" [options]="ellipsisTooltipOptions" (mouseover)="onMouseOver($event)">{{ orderedLegend[i] }}</span>
  </div>
</div>
<div class="chart-js" [style.height]="height">
  <canvas #barChart [class.grab-cursor]="isZoomingRequired"></canvas>
</div>
<div class="zoom-container" *ngIf="zoomEnabled && isZoomingRequired">
  <app-zoom
    [zoom]="currentZoom"
    [defaultZoom]="defaultZoom"
    [min]="minZoom"
    [max]="maxZoom"
    [step]="zoomStep"
    [defaultButtons]="[ZoomDefaultButton.FitToScreen, ZoomDefaultButton.ZoomOut, ZoomDefaultButton.ResetZoom, ZoomDefaultButton.ZoomIn]"
    [extraButtons]="panButtons"
    (zoomChange)="onZoomChanged($event)"
    (fitToScreenClick)="onFitToScreenClicked()"
  >
  </app-zoom>
</div>
