import { SelectItem } from '../components/select-with-search/select-item.type';
import { DateRange } from '../types/date-range.type';

export enum FilterName {
  ProcessGroup = 'business process',
  Queue = 'queue',
  Process = 'process',
  Exception = 'exception',
  Resource = 'resource',
  TimePeriod = 'time period',
  Domain = 'domain',
}

export type FilterValueType = string | DateRange;

export interface FilterItemValue {
  id: FilterValueType;
  label: string;
  count?: number;
  disabled?: boolean;
}

export enum FilterItemType {
  Simple,
  Select,
  MultiSelect,
}

export interface BaseFilterItem {
  type: FilterItemType;
  name: string;
  allItemsLabel?: string;
  fixedItems?: SelectItem[];
  showIfInactive?: boolean;
  canClear?: boolean;
  queryParamName?: string;
  source?: string;
}

export interface SimpleFilterItem extends BaseFilterItem {
  type: FilterItemType.Simple;
  value?: FilterItemValue;
}

export interface SelectFilterItem extends BaseFilterItem {
  type: FilterItemType.Select;
  value?: string;
  availableValues: FilterItemValue[];
  placeholder?: string;
}

export interface MultiSelectFilterItem extends BaseFilterItem {
  type: FilterItemType.MultiSelect;
  value?: string[];
  availableValues: FilterItemValue[];
  placeholder?: string;
}

export type FilterItem = SimpleFilterItem | SelectFilterItem | MultiSelectFilterItem;

export class FilterDefinition {
  constructor(public filters: FilterItem[]) {}

  isFilterActive(name: string): boolean {
    return this.filters.find(filter => filter.name === name)?.value != null;
  }

  isAnyFilterActive(names: string[]): boolean {
    return names.some(name => this.isFilterActive(name));
  }

  getFilterValue(name: string, ignoredSource: string[] = []): any {
    const filter = this.filters.find(f => f.name === name);
    if (filter == null || ignoredSource.includes(filter.source)) {
      return null;
    }

    if (filter.type === FilterItemType.Simple) {
      return filter.value?.id;
    }

    return filter.value;
  }

  getFilter(name: string): FilterItem {
    const filter = this.filters.find(f => f.name === name);
    if (filter == null) {
      throw Error(`Filter "${name}" not found`);
    }
    return filter;
  }
}
