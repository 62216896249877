<div class="calendar-header">
  <span>
    <button (click)="previousClicked()" *ngIf="hasBeforeMonth" class="btn btn-icon-only">
      <svg-icon key="arrow-button-left" size="xs"></svg-icon>
    </button>
  </span>
  <span (click)="viewChangedHandler()" class="calendar-header-label"
    >{{ periodLabel }}
    <i class="chevron">
      <svg-icon *ngIf="currentView === CalendarView.Year" key="chevron-up" size="md"></svg-icon>
      <svg-icon *ngIf="currentView === CalendarView.Month" key="chevron-down" size="md"></svg-icon>
    </i>
  </span>
  <span>
    <button (click)="nextClicked()" *ngIf="hasNextMonth" class="btn btn-icon-only">
      <svg-icon key="arrow-button-right" size="xs"></svg-icon>
    </button>
  </span>
</div>
