export function groupBy<T, Q>(array: T[], predicate: (value: T, index: number, array: T[]) => Q): Map<Q, T[]> {
  return array.reduce((map, value, index, array) => {
    const key = predicate(value, index, array);
    map.get(key)?.push(value) ?? map.set(key, [value]);
    return map;
  }, new Map<Q, T[]>());
}

export function isArrayEqual<T>(a: T[], b: T[]): boolean {
  if (a == null || b == null || a.length !== b.length) {
    return false;
  }

  return a.every((value, index) => value === b[index]);
}

export function findLastIndex<T>(array: T[], predicate: (value: T, index: number) => boolean): number {
  for (let i = array.length - 1; i >= 0; i--) {
    if (predicate(array[i], i)) {
      return i;
    }
  }

  return -1;
}

export function replace<T>(array: T[], oldItem: T, newItem: T): boolean {
  const index = array.indexOf(oldItem);

  if (index >= 0) {
    array[index] = newItem;
    return true;
  }

  return false;
}

export function distinct<T>(array: T[]): T[] {
  return [...new Set(array)];
}
