import { Component, Input, OnInit } from '@angular/core';
import { FormatType } from '../../enums/format-type.enum';
import { EstimationService } from '../../services/estimation.service';
import { NavigationService } from '../../services/navigation.service';
import { Data } from '../../types/data.type';

@Component({
  selector: 'shared-estimation-notification',
  templateUrl: 'estimation-notification.component.html',
  styleUrls: ['estimation-notification.component.scss'],
})
export class EstimationNotificationComponent implements OnInit {
  @Input() isOverview = true;

  readonly FormatType = FormatType;
  prefix: string;

  get estimationChanged(): boolean {
    return this.estimationService.estimationChanged;
  }

  get enteredCount(): number {
    return this.data.masterProcessUserData.enteredIntervalCasesCount;
  }

  get calculatedCount(): number {
    return this.data.masterProcessUserData.calculatedIntervalCasesCount;
  }

  get interval(): string {
    return this.estimationService.intervalText;
  }

  get isInMasterProcess(): boolean {
    return this.isOverview || this.estimationService.isInMasterProcess;
  }

  get masterProcessLabel(): string {
    return this.estimationService.masterProcessLabel;
  }

  get taskTypeLabel(): string {
    return this.estimationService.taskTypeLabel;
  }

  constructor(private data: Data, private estimationService: EstimationService, private navigationService: NavigationService) {}

  ngOnInit(): void {
    this.prefix = this.isOverview ? 'Cost and savings' : 'Savings';
  }

  async goToMasterProcess(): Promise<void> {
    await this.navigationService.navigateToOverview(this.estimationService.masterProcessId);
  }
}
