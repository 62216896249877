import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { FormatType } from '../../enums/format-type.enum';
import { FormatHelper } from '../../helpers/format.helper';

@Component({
  selector: 'shared-value-with-tooltip',
  templateUrl: './value-with-tooltip.component.html',
  styleUrls: ['./value-with-tooltip.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValueWithTooltipComponent implements OnChanges {
  tooltip: string;

  @Input() value: any;
  @Input() valueType: FormatType;
  @Input() className: string;

  ngOnChanges(_: SimpleChanges): void {
    this.tooltip = `<b>${FormatHelper.format(this.value, this.valueType, false, true)}</b>`;
  }
}
