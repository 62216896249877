import { Injectable } from '@angular/core';
import { HubConnection, HubConnectionBuilder, HubConnectionState, IRetryPolicy, RetryContext } from '@microsoft/signalr';
import { Observable, Subject } from 'rxjs';
import { MasterProcessStatus } from '../enums/generated.enums';
import { AuthService } from './auth.service';
import { LoggingService } from './logging.service';
import { MasterProcessAddedMessage, MasterProcessAddingProgressMessage } from '../types/signalr-messages.type';
import { NavigationService } from './navigation.service';

@Injectable({ providedIn: 'root' })
export class SignalRService {
  get masterProcessAddingProgress$(): Observable<MasterProcessAddingProgressMessage> {
    return this.masterProcessAddingProgressSubject.asObservable();
  }

  get masterProcessAdded$(): Observable<MasterProcessAddedMessage> {
    return this.masterProcessAddedSubject.asObservable();
  }

  get masterProcessDeleted$(): Observable<string> {
    return this.masterProcessDeletedSubject.asObservable();
  }

  get masterProcessUserDataUpdated$(): Observable<string> {
    return this.masterProcessUserDataUpdatedSubject.asObservable();
  }

  get masterProcessReportUpdated$(): Observable<string> {
    return this.masterProcessReportUpdatedSubject.asObservable();
  }

  get connectionError$(): Observable<Error> {
    return this.connectionErrorSubject.asObservable();
  }

  private hubConnection: HubConnection;
  private signalRUrl = '/hub';

  private masterProcessAddingProgressSubject = new Subject<MasterProcessAddingProgressMessage>();
  private masterProcessAddedSubject = new Subject<MasterProcessAddedMessage>();
  private masterProcessDeletedSubject = new Subject<string>();
  private masterProcessUserDataUpdatedSubject = new Subject<string>();
  private masterProcessReportUpdatedSubject = new Subject<string>();
  private connectionErrorSubject = new Subject<Error>();

  constructor(authService: AuthService, private loggingService: LoggingService, navigationService: NavigationService) {
    this.hubConnection = new HubConnectionBuilder()
      .withUrl(`${this.signalRUrl}`)
      .withAutomaticReconnect(new InfiniteRetryPolicy(loggingService, authService, navigationService))
      .build();

    this.hubConnection.onreconnecting((error?: Error) => {
      this.connectionErrorSubject.next(error);
    });

    this.hubConnection.on('MasterProcessAddingProgressChanged', (masterProcessId: string, status: MasterProcessStatus, nextOperationProgress: number) =>
      this.masterProcessAddingProgressSubject.next({ masterProcessId, status, nextOperationProgress }),
    );

    this.hubConnection.on('MasterProcessAdded', (masterProcessIds: string[], processIds: string[], someLogsWereMissing: boolean) =>
      this.masterProcessAddedSubject.next({ masterProcessIds, processIds, someLogsWereMissing }),
    );

    this.hubConnection.on('MasterProcessDeleted', (masterProcessId: string) => {
      this.masterProcessDeletedSubject.next(masterProcessId);
    });

    this.hubConnection.on('MasterProcessUserDataUpdated', (masterProcessId: string) => {
      this.masterProcessUserDataUpdatedSubject.next(masterProcessId);
    });

    this.hubConnection.on('MasterProcessReportUpdated', (masterProcessId: string) => {
      this.masterProcessReportUpdatedSubject.next(masterProcessId);
    });
  }

  async startConnection(): Promise<void> {
    try {
      if (this.hubConnection.state === HubConnectionState.Disconnected) {
        await this.hubConnection.start();
      }
    } catch (error: any) {
      this.loggingService.logException(error);
    }
  }

  async stopConnection(): Promise<void> {
    try {
      await this.hubConnection.stop();
    } catch (error: any) {
      this.loggingService.logException(error);
    }
  }

  async restartConnection(): Promise<void> {
    await this.stopConnection();
    await this.startConnection();
  }
}

class InfiniteRetryPolicy implements IRetryPolicy {
  private retryDelays = [0, 2000, 10000, 30000];

  constructor(private loggingService: LoggingService, private authService: AuthService, private navigationService: NavigationService) {}

  nextRetryDelayInMilliseconds(retryContext: RetryContext): number {
    const errorMessage = retryContext.retryReason.message;
    this.loggingService.logTrace(`SignalR connection retry: ${retryContext.previousRetryCount}; reason: ${errorMessage}`);

    if (errorMessage.includes('401')) {
      void this.authService.refreshToken().then(result => {
        if (!result) {
          void this.navigationService.navigateToLogin('SignalR connection retry failed with error 401', false);
        }
      });
    }

    const delayIndex = Math.min(retryContext.previousRetryCount, this.retryDelays.length - 1);
    return this.retryDelays[delayIndex];
  }
}
