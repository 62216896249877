import { ProcessingItemError } from './processing-item-error.enum';
import { ProcessingItemStatus } from './processing-item-state.enum';

export class UploadingItem {
  status = ProcessingItemStatus.Uploading;
  operationProgress = 0;
  totalProgress = 0;
  error: ProcessingItemError;

  private _uploadedFileId: string;

  constructor(public id: string, public fileName: string) {}

  get uploadedFileId(): string {
    return this._uploadedFileId;
  }

  // STATES logic
  get isUploading(): boolean {
    return this.status === ProcessingItemStatus.Uploading;
  }

  get isUploaded(): boolean {
    return this.status === ProcessingItemStatus.Uploaded;
  }

  get isSuccessful(): boolean {
    return this.status === ProcessingItemStatus.Successful;
  }

  get isError(): boolean {
    return this.status === ProcessingItemStatus.Error;
  }

  get isCancelled(): boolean {
    return this.status === ProcessingItemStatus.Cancelled;
  }

  get isDeleted(): boolean {
    return this.status === ProcessingItemStatus.Deleted;
  }

  get isDeletedSuccessfully(): boolean {
    return this.status === ProcessingItemStatus.DeletedSuccessfully;
  }

  get canCancel(): boolean {
    return this.isUploading;
  }

  onProgress(operationProgress: number): void {
    this.operationProgress = operationProgress;
    this.calculateTotalProgress();
  }

  onUploadFinished(uploadedFileId: string): void {
    this.status = ProcessingItemStatus.Uploaded;
    this._uploadedFileId = uploadedFileId;
    this.onProgress(1);
  }

  onError(error: ProcessingItemError): void {
    this.error = error;
    this.status = ProcessingItemStatus.Error;
  }

  private calculateTotalProgress(): void {
    this.totalProgress = this.getTotalProgress();
  }

  protected getTotalProgress(): number {
    return this.operationProgress;
  }
}
