import { Directive, Input } from '@angular/core';
import { PerformancePieComponent } from '../components/performance-pie/performance-pie.component';
import { MonitoringMetrics } from '../types/items-metrics.type';
import { ManagementMetricsEntry } from '../types/management-metrics-result.type';

@Directive({
  selector: 'shared-performance-pie[sourceMetrics]',
})
export class PieSourceMetricsDirective {
  @Input() set sourceMetrics(value: MonitoringMetrics | ManagementMetricsEntry) {
    const monitoringMetrics = value as MonitoringMetrics;
    if (monitoringMetrics.completedPercentage != null && monitoringMetrics.businessExceptionsPercentage != null && monitoringMetrics.systemExceptionsPercentage != null) {
      this.host.arcs = this.getPieDataFromMonitoringMetrics(monitoringMetrics);
      return;
    }

    const managementMetrics = value as ManagementMetricsEntry;
    if (managementMetrics.tasksCompleted != null && managementMetrics.tasksSystemException != null && managementMetrics.tasksBusinessException != null && managementMetrics.tasksTotal != null) {
      this.host.arcs = this.getPieDataFromManagementMetrics(managementMetrics);
      return;
    }
  }

  constructor(private host: PerformancePieComponent) {}

  getPieDataFromMonitoringMetrics(metrics: MonitoringMetrics): number[] {
    if (metrics == null) {
      return [0, 0, 0, 0];
    }

    const x = metrics.completedPercentage;
    const y = metrics.businessExceptionsPercentage;
    const z = metrics.systemExceptionsPercentage;
    const w = 0;

    const total = x + y + z + w;
    if (total > 1) {
      return [x / total, y / total, z / total, w / total];
    }
    return [x, y, z, 1 - x - y - z];
  }

  getPieDataFromManagementMetrics(metrics: ManagementMetricsEntry): number[] {
    if (metrics == null || metrics.tasksTotal === null || metrics.tasksTotal === 0) {
      return [0, 0, 0, 0];
    }

    const x = metrics.tasksCompleted / metrics.tasksTotal;
    const y = metrics.tasksBusinessException / metrics.tasksTotal;
    const z = metrics.tasksSystemException / metrics.tasksTotal;
    const w = 0;

    const total = x + y + z + w;
    if (total > 1) {
      return [x / total, y / total, z / total, w / total];
    }
    return [x, y, z, 1 - x - y - z];
  }
}
