import { FlameGraphNode } from 'src/app/shared/types/flame-graph-data.type';
import { ActionableInsightStatus, Platform } from '../enums/generated.enums';
import { ActionableInsight } from './actionable-insight.type';
import { ActivityType } from './activity-type.type';
import { AttributesCounts } from './attributes-counts.type';
import { AttributesValues } from './attributes-values.type';
import { ProcessStatistics } from './statistics/process-statistics.type';

export class MasterProcess {
  platform: Platform;
  pointeeVersion: string;
  processingDateTime: Date;
  itemsProcessStatistics: ProcessStatistics;
  sessionsProcessStatistics: ProcessStatistics;
  actionableInsights: ActionableInsight[];
  sessionsAttributesValues: AttributesValues;
  sessionsAttributesCounts: AttributesCounts;
  itemsAttributesValues: AttributesValues;
  itemsAttributesCounts: AttributesCounts;
  actionableInsightsByState: Map<ActionableInsightStatus | string, Set<ActionableInsight>>;
  flameGraphRoot: FlameGraphNode;
  activityTypes: ActivityType[];
  itemDurations: number[];
  itemEnds: Date[];

  get hasItems(): boolean {
    return this.itemsProcessStatistics && this.itemsProcessStatistics.casesCount > 0;
  }
}
