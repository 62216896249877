import { Injectable } from '@angular/core';
import { SchedulerProcessConfiguration } from '../../types/scheduler/scheduler-process-configuration.type';
import { SchedulerProcess } from '../../types/scheduler/scheduler-process.type';
import { SchedulerQueue } from '../../types/scheduler/scheduler-queue.type';
import { SchedulerResource } from '../../types/scheduler/scheduler-resource.type';
import { SchedulerStatus } from '../../types/scheduler/scheduler-status.type';
import { BaseApiService } from './base-api.service';
import { ApiConversionHelper } from '../../helpers/api-conversion.helper';

@Injectable({ providedIn: 'root' })
export class SchedulerApiService extends BaseApiService {
  protected get baseUrl(): string {
    return `${super.baseUrl}/scheduler`;
  }

  async getSchedulerProcesses(): Promise<SchedulerProcess[]> {
    return await this.get<SchedulerProcess[]>('processes');
  }

  async getSchedulerQueues(): Promise<SchedulerQueue[]> {
    return await this.get<SchedulerQueue[]>('queues');
  }

  async getSchedulerResources(): Promise<SchedulerResource[]> {
    return await this.get<SchedulerResource[]>('resources');
  }

  async getSchedulerProcessConfiguration(processId: string): Promise<SchedulerProcessConfiguration> {
    const result = await this.get<SchedulerProcessConfiguration>(`processes/${processId}`);
    ApiConversionHelper.convertTimeSpans(result, ['sla']);
    return result;
  }

  async updateSchedulerProcessConfiguration(processId: string, configuration: SchedulerProcessConfiguration): Promise<void> {
    const body = { ...configuration };
    ApiConversionHelper.convertTimeSpansForApi(body, ['sla']);
    return await this.patch(`processes/${processId}`, body);
  }

  async sendProcessStopRequest(processId: string): Promise<void> {
    return await this.post(`processes/${processId}/request-stop`, null);
  }

  async sendSessionStopRequest(sessionId: string): Promise<void> {
    return await this.post(`sessions/${sessionId}/request-stop`, null);
  }

  async setSchedulerProcessesQueueIds(overwrite: boolean): Promise<void> {
    return await this.patch(`processes/set-queue-ids/${overwrite}`, null);
  }

  async getSchedulerStatus(): Promise<SchedulerStatus> {
    return await this.get<SchedulerStatus>('status');
  }
}
