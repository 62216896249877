import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormBuilder, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validators } from '@angular/forms';
import { Subscription, debounceTime } from 'rxjs';
import { DateHelper } from '../../helpers/date.helper';
import { NumberHelper } from '../../helpers/number.helper';
import { FormHelper } from '../../helpers/form.helper';

@Component({
  selector: 'shared-duration-picker',
  templateUrl: './duration-picker.component.html',
  styleUrls: ['./duration-picker.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, multi: true, useExisting: DurationPickerComponent },
    { provide: NG_VALIDATORS, multi: true, useExisting: DurationPickerComponent },
  ],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class DurationPickerComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @Input() set value(value: number) {
    if (value == null || value == this.calculateDuration()) {
      return;
    }
    this.writeValue(value);
  }

  @Input() showDays: boolean;
  @Input() disabled: boolean;
  @Input() debounceTime: number;

  @Output() valueChange = new EventEmitter<number>();

  readonly DateHelper = DateHelper;

  form = this.formBuilder.group({
    count: [null as number, [Validators.required, Validators.min(1), Validators.max(9999)]],
    multiplier: [null as number, [Validators.required]],
  });

  onChange = (value: number) => this.valueChange.emit(value);
  onTouched = () => {};

  private subscription: Subscription;

  constructor(private changeDetectorRef: ChangeDetectorRef, private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    if (this.disabled) {
      this.form.disable();
      return;
    }

    let valueChanges = this.form.controls.count.valueChanges;
    if (this.debounceTime > 0) {
      valueChanges = valueChanges.pipe(debounceTime(this.debounceTime));
    }

    this.subscription = valueChanges.subscribe(v => {
      if (NumberHelper.isValidNumber(v, 1, 9999)) {
        this.onValueChange();
      }
    });

    this.writeValue(this.calculateDuration());
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  onValueChange(): void {
    this.markAsTouched();
    this.onChange(this.calculateDuration());
  }

  private calculateDuration(): number {
    this.form.updateValueAndValidity();
    return this.form.controls.count.value * this.form.controls.multiplier.value;
  }

  setDisabledState(disabled: boolean): void {
    FormHelper.setEnabled(this.form, !disabled);
    this.changeDetectorRef.markForCheck();
  }

  // ControlValueAccessor implementation
  writeValue(duration: number): void {
    const durationInUnits = DateHelper.getDurationInTimeUnits(duration);
    if (this.showDays && Number.isInteger(durationInUnits.days)) {
      this.form.controls.multiplier.setValue(DateHelper.msPerDay, { emitEvent: false });
      this.form.controls.count.setValue(durationInUnits.days, { emitEvent: false });
    } else if (Number.isInteger(durationInUnits.hours)) {
      this.form.controls.multiplier.setValue(DateHelper.msPerHour, { emitEvent: false });
      this.form.controls.count.setValue(durationInUnits.hours, { emitEvent: false });
    } else {
      this.form.controls.multiplier.setValue(DateHelper.msPerMinute, { emitEvent: false });
      this.form.controls.count.setValue(durationInUnits.minutes, { emitEvent: false });
    }
  }

  registerOnChange(onChange: (values: number) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  markAsTouched(): void {
    if (this.onTouched != null) {
      this.onTouched();
    }
  }

  // Validator implementation
  validate(control: AbstractControl): ValidationErrors | null {
    const count = this.form.controls.count.value;
    if (!count || count < 1 || count > 9999) {
      return {
        invalidValue: {
          valid: false,
        },
      };
    }
    return null;
  }
}
