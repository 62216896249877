import { Pipe, PipeTransform } from '@angular/core';
import { EstimationService } from '../services/estimation.service';

@Pipe({ name: 'estimation', pure: false })
export class EstimationPipe implements PipeTransform {
  constructor(private estimationService: EstimationService) {}

  transform(estimation: number): number {
    return this.estimationService.multiplyEstimation(estimation);
  }
}
