import { LoginType } from '../../enums/generated.enums';
import { DashboardConfiguration } from '../dashboard-configuration.type';

export class LocalStorageData {
  dashboardConfiguration?: DashboardConfiguration = undefined;
  isMenuExpanded?: boolean = true;
}

export class LocalUserStorageData {
  loginType?: LoginType = undefined;
  microsoftAccountId?: string = undefined;
}
