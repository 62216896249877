import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'shared-form-field-container',
  templateUrl: './form-field-container.component.html',
  styleUrls: ['./form-field-container.component.scss'],
})
export class FormFieldContainerComponent {
  @Input() label: string;
  @Input() control: AbstractControl;
  @Input() containerClass: string;
  @Input() labelTooltip?: string;
}
