<div class="inline-content" [formGroup]="form">
  <shared-input-number formControlName="count" [min]="1" [max]="9999" [maxLength]="4" [fixValueOnBlur]="true"></shared-input-number>
  <div class="select-content-width">
    <mat-select formControlName="multiplier" class="pointee-mat-select" (selectionChange)="onValueChange()">
      <mat-option [value]="DateHelper.msPerMinute">minutes</mat-option>
      <mat-option [value]="DateHelper.msPerHour">hours</mat-option>
      <mat-option *ngIf="showDays" [value]="DateHelper.msPerDay">days</mat-option>
    </mat-select>
  </div>
</div>
