import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Chart, TooltipItem } from 'chart.js';
import { AnnotationOptions } from 'chartjs-plugin-annotation';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { FormatHelper } from '../../../helpers/format.helper';
import { TextConstants } from '../../../text-constants';
import { ChartColor } from '../chart-color.enum';
import { tooltipTitleMultilinePlugin } from '../plugins/tooltip-title-multiline.plugin';
import { ChartConfiguration, ChartItem, LineChartData } from './line-chart.type';

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LineChartComponent implements AfterViewInit, OnChanges, OnDestroy {
  @Input() data: LineChartData;
  @Input() configuration: ChartConfiguration;
  @Input() selectedItemId: string;
  @Input() unnamedItemLabel = TextConstants.notAvailable;
  @Input() height = 'auto';
  @Input() annotations: AnnotationOptions[];

  @Output() readonly itemClick = new EventEmitter<ChartItem>();

  get fixedLabelWidth(): boolean {
    return this.configuration?.fixedLabelWidth ?? false;
  }

  get minYAxisWidth(): number {
    return this.configuration?.minYAxisWidth ?? 0;
  }

  private _lineChart: Chart;

  @ViewChild('lineChart', { static: true }) canvas: ElementRef<HTMLCanvasElement>;

  ngAfterViewInit(): void {
    this.initializeChart();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.configuration?.isFirstChange() === false || changes.data?.isFirstChange() === false) {
      this.updateChart();
    }
  }

  ngOnDestroy(): void {
    this._lineChart?.destroy();
  }

  initializeChart(): void {
    const ctx = this.canvas.nativeElement.getContext('2d');
    this._lineChart = new Chart(ctx, {
      type: 'line',
      data: {
        datasets: [],
      },
      plugins: [ChartDataLabels, tooltipTitleMultilinePlugin],
      options: {
        maintainAspectRatio: false,
        responsive: true,
        animation: {
          duration: 0,
        },
        interaction: {
          intersect: false,
          mode: 'nearest',
          axis: 'x',
        },
        scales: {
          y: {
            beginAtZero: this.configuration.minY === 0,
            suggestedMin: this.configuration.minY,
            suggestedMax: this.configuration.maxY,
            grid: {
              drawTicks: this.fixedLabelWidth,
            },
            border: {
              display: true,
              dash: [8, 4],
              dashOffset: 10,
            },
            ticks: {
              autoSkip: false,
              padding: 8,
              callback: value => {
                return FormatHelper.format(value, this.configuration.formatType, false, false, this.configuration.unit);
              },
            },
            afterFit: scale => {
              scale.width = Math.max(this.minYAxisWidth ?? 0, scale.width);
            },
          },
          x: {
            grid: {
              display: false,
            },
            border: {
              display: true,
              color: ChartColor.Border,
            },
          },
        },
        plugins: {
          legend: {
            display: this.configuration.showLegend ?? false,
            reverse: true,
            position: 'bottom',
            labels: {
              boxHeight: 12,
              boxWidth: 24,
              font: {
                size: 14,
              },
              useBorderRadius: true,
              borderRadius: 4,
            },
          },
          title: {
            display: this.configuration.title != null,
            align: 'start',
            color: ChartColor.FontTitle,
            padding: {
              bottom: 16,
            },
            font: {
              size: 16,
              lineHeight: '24px',
              weight: 500,
            },
          },
          tooltip: {
            enabled: true,
            intersect: false,
            mode: 'nearest',
            caretPadding: 8,
            boxPadding: 4,
            itemSort: (a, b) => {
              return a.datasetIndex - b.datasetIndex;
            },
            footerMarginTop: 10,
            footerFont: {
              style: 'italic',
              weight: 'normal',
              size: 14,
            },
            footerAlign: 'center',
            callbacks: {
              label: (item: TooltipItem<'line'>) => {
                const label = item.dataset.label ? `${item.dataset.label}: ` : '';
                const value = FormatHelper.format(this.data.datasets[item.datasetIndex].data[item.dataIndex], this.configuration.formatType, false, true, this.configuration.unit);
                return `${label}${value}`;
              },
              footer: this.configuration.tooltipFooter ? () => this.configuration.tooltipFooter : undefined,
            },
          },
          datalabels: {
            display: false,
          },
          annotation: {
            annotations: [],
          },
        },
      },
    });

    this.updateChart();
  }

  private updateChart(): void {
    if (this._lineChart == null || this.configuration == null || this.data == null) {
      return;
    }

    this._lineChart.options.plugins.title.text = this.configuration.title;
    this._lineChart.data.labels = this.data.labels;
    this._lineChart.data.datasets = this.data.datasets.map((dataset, index) => ({
      label: dataset.label,
      data: dataset.data,
      pointBackgroundColor: dataset.color ? dataset.color : ChartColor.Blue80,
      pointStyle: dataset.pointStyle ? (dataset.pointStyle === 'false' ? false : dataset.pointStyle) : 'circle',
      borderColor: dataset.color ? dataset.color : ChartColor.Blue80,
      backgroundColor: ChartColor.Blue16,
      fill: index === 0,
      order: this._lineChart.data.datasets.length - index - 1,
    }));

    this.updateSelectedItem();
    this.refreshAnnotations();

    this._lineChart.update();
  }

  private updateSelectedItem(): void {
    if (this.selectedItemId == null) {
      return;
    }
    this.selectedItemId = null;
  }

  private refreshAnnotations(): void {
    if (this._lineChart?.options?.plugins?.annotation == null) {
      return;
    }

    const annotations = this.annotations ?? [];
    this._lineChart.options.plugins.annotation.annotations = annotations;
  }
}
