import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class BaseApiService {
  protected get baseUrl(): string {
    return '/api';
  }

  constructor(protected httpClient: HttpClient) {}

  getUrl(relativeUrl: string): string {
    return `${this.baseUrl}/${relativeUrl}`;
  }

  protected async get<T>(relativeUrl: string, params: any = null): Promise<T> {
    return await firstValueFrom(this.httpClient.get<T>(this.getUrl(relativeUrl), { params: this.createHttpParamsFromObject(params) }));
  }

  protected async getBlob(relativeUrl: string): Promise<Blob> {
    return await firstValueFrom(this.httpClient.get(this.getUrl(relativeUrl), { responseType: 'blob' }));
  }

  protected async post<TBody>(relativeUrl: string, body: TBody): Promise<void> {
    return await firstValueFrom(this.httpClient.post<void>(this.getUrl(relativeUrl), body));
  }

  protected async postWithResult<TBody, TResult>(relativeUrl: string, body: TBody, params: HttpParams = null): Promise<TResult> {
    return await firstValueFrom(this.httpClient.post<TResult>(this.getUrl(relativeUrl), body, { params }));
  }

  protected async put<T>(relativeUrl: string, body: T): Promise<void> {
    return await firstValueFrom(this.httpClient.put<void>(this.getUrl(relativeUrl), body));
  }

  protected async delete(relativeUrl: string): Promise<void> {
    return await firstValueFrom(this.httpClient.delete<void>(this.getUrl(relativeUrl)));
  }

  protected async patch<TBody, TResult>(relativeUrl: string, body: TBody): Promise<TResult> {
    return await firstValueFrom(this.httpClient.patch<TResult>(this.getUrl(relativeUrl), body));
  }

  protected download(relativeUrl: string): void {
    const link = document.createElement('a');
    link.href = this.getUrl(relativeUrl);
    link.click();
  }

  private createHttpParamsFromObject<T>(obj: T): HttpParams {
    if (obj == null) {
      return null;
    }
    let params = new HttpParams();
    Object.entries(obj)
      .filter(([, value]) => value != null)
      .forEach(([key, value]) => {
        if (Array.isArray(value)) {
          value.forEach(v => (params = params.append(key, v)));
        } else {
          params = params.append(key, value);
        }
      });
    return params;
  }
}
