import { Injectable } from '@angular/core';
import { ResourceGroup } from '../types/monitoring/resource-group.type';
import { SchedulerProcess } from '../types/scheduler/scheduler-process.type';
import { SchedulerQueue } from '../types/scheduler/scheduler-queue.type';
import { ApiService } from './api/api.service';

@Injectable({ providedIn: 'root' })
export class SchedulerCacheService {
  public processes: SchedulerProcess[];
  public queues: SchedulerQueue[];
  public resourceGroups: ResourceGroup[];
  private isInitialized = false;
  private processesById: Map<string, SchedulerProcess>;
  private queuesById: Map<string, SchedulerQueue>;
  private resourceGroupsById: Map<string, ResourceGroup>;

  constructor(private apiService: ApiService) {}

  public getProcessName(processId: string): string {
    const name = this.processesById?.get(processId)?.name;
    return name ?? processId;
  }

  public getQueueName(processId: string): string {
    return this.queuesById?.get(processId)?.name;
  }

  public getResourceName(processId: string): string {
    return this.resourceGroupsById?.get(processId)?.name;
  }

  public getResourcesLength(): number {
    return this.resourceGroups?.length ?? 0;
  }

  public async initialize() {
    if (this.isInitialized) {
      return;
    }

    this.processes = await this.apiService.scheduler.getSchedulerProcesses();
    this.processesById = new Map(this.processes.map(p => [p.id, p]));
    this.queues = await this.apiService.scheduler.getSchedulerQueues();
    this.queuesById = new Map(this.queues.map(q => [q.id, q]));
    await this.initializeResourceGroups();
    this.isInitialized = true;
  }

  public async initializeResourceGroups() {
    this.resourceGroups = await this.apiService.resourceGroups.getResourceGroups();
    this.resourceGroupsById = new Map(this.resourceGroups.map(r => [r.id, r]));
  }

  public clear() {
    this.isInitialized = false;
    this.processes = null;
    this.processesById = null;
    this.queues = null;
    this.queuesById = null;
    this.resourceGroups = null;
    this.resourceGroupsById = null;
  }
}
