import { Injectable } from '@angular/core';
import { FormatType } from '../enums/format-type.enum';
import { IntervalType } from '../enums/generated.enums';
import { FormatHelper } from '../helpers/format.helper';
import { Data } from '../types/data.type';
import { PlatformTextsService } from './platform-texts.service';
import { SelectionFacadeService } from './selection-facade.service';

@Injectable({ providedIn: 'root' })
export class EstimationService {
  count: number;
  intervalText: string;
  estimationChanged = false;
  taskTypeLabel: string;

  private estimationCoefficient: number;
  private masterProcessTooltip: string;
  private subprocessTooltip: string;

  get isInMasterProcess(): boolean {
    return !this.selectionFacadeService.selectedProcess || this.selectionFacadeService.selectedProcess.masterProcessId != null;
  }

  get tooltip(): string {
    return this.isInMasterProcess ? this.masterProcessTooltip : this.subprocessTooltip;
  }

  get masterProcessId(): string {
    return this.selectionFacadeService.selectedMasterProcess.id;
  }

  get masterProcessLabel(): string {
    return this.selectionFacadeService.selectedMasterProcess.label;
  }

  constructor(private data: Data, private selectionFacadeService: SelectionFacadeService, private platformTexts: PlatformTextsService) {
    data.masterProcessUserDataChanged.subscribe(() => this.initialize());
    this.initialize();
  }

  initialize(): void {
    const { masterProcess, masterProcessUserData } = this.data;
    this.taskTypeLabel = this.platformTexts.get(masterProcess.hasItems ? 'items' : 'sessions');

    if (masterProcessUserData == null || masterProcessUserData.calculatedIntervalCasesCount == null || masterProcessUserData.enteredIntervalCasesCount == null) {
      const casesCount = masterProcess.hasItems ? masterProcess.itemsProcessStatistics.yearCasesCount : masterProcess.sessionsProcessStatistics.yearCasesCount;
      const count = FormatHelper.format(casesCount, FormatType.Number, null, true);
      this.masterProcessTooltip = `This calculation is based on an estimate of ${count} ${this.taskTypeLabel} in the process per year.`;
      this.estimationChanged = false;
      this.estimationCoefficient = null;
      this.subprocessTooltip = null;
      return;
    }

    this.count = masterProcessUserData.enteredIntervalCasesCount;
    this.estimationChanged = true;
    this.intervalText = masterProcessUserData.intervalTypeForCasesCounts === IntervalType.Month ? 'month' : 'year';
    this.estimationCoefficient =
      masterProcessUserData.calculatedIntervalCasesCount && masterProcessUserData.calculatedIntervalCasesCount !== 0
        ? masterProcessUserData.enteredIntervalCasesCount / masterProcessUserData.calculatedIntervalCasesCount
        : null;

    const count = FormatHelper.format(masterProcessUserData.enteredIntervalCasesCount, FormatType.Number, null, true);
    this.masterProcessTooltip = `This calculation is based on your plan to process ${count} ${this.taskTypeLabel} a ${this.intervalText}, not the original data.`;
    this.subprocessTooltip = `This calculation is based on your plan to process ${count} ${this.taskTypeLabel} a ${this.intervalText} in ${this.masterProcessLabel}, not the original data.`;
  }

  multiplyEstimation(estimation: number): number {
    return this.estimationCoefficient ? Math.round(estimation * this.estimationCoefficient) : estimation;
  }
}
