import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FeatureFlags, Permission } from '../../enums/generated.enums';
import { SubPageType } from '../../enums/page-type.enum';
import { AuthService } from '../../services/auth.service';
import { MenuService } from '../../services/menu.service';
import { NavigationService } from '../../services/navigation.service';
import { MenuItem } from '../../types/menu-section.type';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { MatButtonToggleChange } from '@angular/material/button-toggle';

export interface RoutingTab {
  type: SubPageType;
  label: string;
  navigate: () => Promise<void>;
}

@Component({
  selector: 'shared-routing-tabs',
  templateUrl: './routing-tabs.component.html',
  styleUrls: ['./routing-tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoutingTabsComponent implements OnInit {
  @Input() useTabs = true;
  menuItem: MenuItem;
  selectedTab = 0;

  constructor(
    private menuService: MenuService,
    private navigationService: NavigationService,
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.menuItem = this.menuService.menuSections.map(s => s.items).reduce((a, b) => a.concat(b.filter(i => i.pageType == this.navigationService.currentPage)), [])[0];
    this.menuItem.tabs = this.menuItem.tabs.filter(tab => !tab.isHidden && this.hasPermissions(tab.permissions) && this.hasFeatureFlag(tab.featureFlags));
    this.selectedTab = this.menuItem.tabs.findIndex(t => t.subPageType == this.navigationService.currentSubPage);
  }

  async switchSelectedTab(event: MatTabChangeEvent): Promise<void> {
    this.cdr.detectChanges();
    this.selectedTab = event.index;
    await this.navigateToSelectedTab();
  }

  async switchToggleButton(event: MatButtonToggleChange) {
    this.cdr.detectChanges();
    this.selectedTab = event.value;
    await this.navigateToSelectedTab();
  }

  private async navigateToSelectedTab(subPageType?: string): Promise<void> {
    const tab = subPageType ? this.menuItem.tabs?.find(t => t.subPageType === subPageType) : this.menuItem.tabs[this.selectedTab];
    this.menuItem.route = tab.route;
    await this.navigationService.navigateByRoute(tab.route);
    if (this.menuItem.onSubPageChange) {
      this.menuItem.onSubPageChange(tab.subPageType);
    }
  }

  hasPermissions(permissions: Permission[]): boolean {
    if (!permissions || permissions.length === 0) return true;
    return permissions.some(p => this.authService.hasPermission(p));
  }

  hasFeatureFlag(featureFlags: FeatureFlags[]): boolean {
    if (!featureFlags || featureFlags.length === 0) return true;
    return featureFlags.some(ff => this.authService.hasFeatureFlag(ff));
  }
}
