import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'shared-pointee-button',
  templateUrl: './pointee-button.component.html',
  styleUrls: ['./pointee-button.component.scss'],
})
export class PointeeButtonComponent {
  @Input() btnIcon: string;
  @Input() btnSelected: boolean;
  @Input() btnTooltip: string;
  @Input() disabled = false;
  @Input() btnColor: 'primary' | 'danger' = 'primary';
  @Input() size: 'small' | 'medium' | 'large' = 'large';
  @Output() btnOnClickAction = new EventEmitter();

  clickAction(): void {
    if (!this.disabled) {
      this.btnOnClickAction.emit();
    }
  }
}
