import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Injector, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, FormControl, FormControlDirective, FormControlName, FormGroupDirective, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { BehaviorSubject } from 'rxjs';
import { groupBy } from '../../helpers/array.helper';
import { SelectGroup, SelectItem, Selection } from './select-item.type';

@Component({
  selector: 'shared-select-with-search',
  templateUrl: './select-with-search.component.html',
  styleUrls: ['./select-with-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: NG_VALUE_ACCESSOR, multi: true, useExisting: SelectWithSearchComponent }],
})
export class SelectWithSearchComponent implements ControlValueAccessor, OnInit, OnChanges {
  @Input() selection: Selection;
  @Input() items: SelectItem[];
  @Input() fixedItems: SelectItem[] = [];
  @Input() multiple = false; // if multiple === true then SimpleChanges has data in changes.selection else changes.items
  @Input() selectAllText = 'Select all';
  @Input() placeholder: string;
  @Input() searchPlaceholder = 'Search...';
  @Input() panelClass: string | string[] | Set<string> | { [key: string]: any };
  @Input() selectClass: string | string[] | Set<string> | { [key: string]: any };
  @Input() disabled = false;
  @Input() debounceTime = 300; // Debounce time in milliseconds
  @Output() selectionChange = new EventEmitter<any>();
  @Output() closed = new EventEmitter<any>();

  get selectionModel(): SelectItem | SelectItem[] {
    return this._selectionModel;
  }

  set selectionModel(value: SelectItem | SelectItem[]) {
    this._selectionModel = value;
    this.selection = Array.isArray(value) && value.length === this.allItemsIncludingFixed.length ? 'all' : value;
    this.filterItems();
    this.updateAllSelected();
    this.markAsTouched();
    this.selectionChange.emit(this.selection);
    this.onChange(this.selection);
  }

  private filterTimeout: any;
  get filter(): string {
    return this._filter;
  }

  set filter(value: string) {
    clearTimeout(this.filterTimeout);
    this.filterTimeout = setTimeout(() => {
      this._filter = value;
      this.filterItems();
    }, this.debounceTime);
  }

  get isInvalid(): boolean {
    return this.formControl?.invalid && this.formControl?.touched;
  }

  get allItemsIncludingFixed(): SelectItem[] {
    return (this.items ?? []).concat(this.fixedItems ?? []);
  }

  groups$ = new BehaviorSubject<SelectGroup[]>([]);
  allSelected = false;

  private _selectionModel: SelectItem | SelectItem[];
  private _filter = '';
  private formControl: FormControl;
  private onChange = (value: Selection) => {};
  private onTouched = () => {};
  private touched = false;

  constructor(
    private injector: Injector,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selection?.firstChange || changes.items?.firstChange) {
      this.filterItems();
      this.updateSelection(this.selection);
      return;
    }

    if (changes.items) {
      if (this.selection === 'all') {
        this._selectionModel = this.allItemsIncludingFixed;
      }
      this.filterItems();
    }

    // logic for filter panel
    if (changes.selection && changes.items) {
      this.updateSelection(this.selection);
    }
    // logic for multiple select
    else if (this.multiple && changes.selection && changes.selection.previousValue?.id !== changes.selection.currentValue?.id) {
      this.updateSelection(changes.selection.currentValue as Selection);
    }
    // logic for single select
    else if (!this.multiple && changes.items && changes.items.previousValue?.id !== changes.items.currentValue?.id) {
      this.updateSelection(this.selection);
    }

    this.updateAllSelected();
    this.cdr.markForCheck();
  }

  async ngOnInit(): Promise<void> {
    try {
      const ngControl = this.injector.get(NgControl);
      if (ngControl instanceof FormControlName) {
        this.formControl = this.injector.get(FormGroupDirective).getControl(ngControl);
      } else {
        this.formControl = (ngControl as FormControlDirective).form;
      }
    } catch {
      this.formControl = this.injector.get(FormControlName, null)?.control as FormControl;
    }
  }

  onClosed(select: MatSelect): void {
    // Workaround for MatSelect not emitting blur event - it is needed to make it work in <form> if "updateOn: 'blur'" is used
    const element = select._elementRef.nativeElement as HTMLElement;
    element.focus();
    element.blur();
    this.closed.emit(this.selection);
  }

  onBlur(): void {
    this.onTouched();
  }

  onSelectAllChanged(selected: boolean): void {
    this.selectionModel = selected ? this.allItemsIncludingFixed : [];
  }

  private filterItems() {
    const items = this.items ?? [];
    const filterValueLowercase = this.filter.toLowerCase();
    const filteredItems = this.filter === '' ? items : items.filter(i => i.label.toLowerCase().includes(filterValueLowercase));
    const groupedItems = groupBy(filteredItems, i => i.group);
    const groups: SelectGroup[] = Array.from(groupedItems.entries())
      .map(([label, items]) => ({ label, items }))
      .sort((a, b) => a.label?.localeCompare(b.label));
    this.groups$.next(groups);
  }

  private updateSelection(value: Selection): void {
    this.selection = value;
    this._selectionModel = value === 'all' ? this.allItemsIncludingFixed.slice() : value;
    this.updateAllSelected();
    this.cdr.markForCheck();
  }

  private updateAllSelected(): void {
    this.allSelected = Array.isArray(this._selectionModel) && this._selectionModel.length === this.allItemsIncludingFixed.length;
  }

  writeValue(value: Selection): void {
    this.updateSelection(value);
    this.cdr.markForCheck();
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cdr.markForCheck();
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }
}
