export enum ProcessMapMetric {
  // Quantity
  LogsCount = 'LogsCount',
  CasesCount = 'CasesCount',
  CasesCountPercentage = 'CasesCountPercentage',
  // Average duration
  MeanDuration = 'MeanDuration',
  MeanDurationPercentage = 'MeanDurationPercentage',
  // Average cost
  AveragePrice = 'AveragePrice',
  AveragePricePercentage = 'AveragePricePercentage',
  TotalYearCosts = 'TotalYearCosts',
  undefined = 'undefined',
  // others metric, that should emit event for change
  UserActionableInsight = 'UserActionableInsight',
}
