import { Component, ContentChild, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'shared-expansion-panel',
  templateUrl: './expansion-panel.component.html',
  styleUrls: ['./expansion-panel.component.scss'],
})
export class ExpansionPanelComponent {
  @Input() title: string;
  @Input() description: string;
  @Input() expanded = false;

  @ContentChild('content') content: TemplateRef<any>;
}
