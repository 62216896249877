import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Uid } from '../../helpers/uid.type';

@Component({
  selector: 'shared-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SwitchComponent),
      multi: true,
    },
  ],
})
export class SwitchComponent implements ControlValueAccessor {
  @Input() isDisabled = false;
  @Input() isAnimated = false;
  @Input() isOnOff = true;
  @Input() set value(value: boolean) {
    this._value = value;
    this.cdr.markForCheck();
  }

  get value(): boolean {
    return this._value;
  }

  @Output() valueChange = new EventEmitter<boolean>();

  readonly id = `switch-${Uid.generate()}`;

  private _value = false;

  constructor(private cdr: ChangeDetectorRef) {}

  onChange: any = () => {
    this.valueChange.emit(this._value);
  };

  onTouched: any = () => {};

  writeValue(value: any): void {
    this._value = value;
    this.cdr.markForCheck();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  toggleSwitch(): void {
    this._value = !this._value;
    this.onChange(this._value);
    this.onTouched();
  }
}
