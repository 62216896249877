export enum ProcessingItemStatus {
  Created = 'Created',
  Uploading = 'Uploading',
  Uploaded = 'Uploaded',
  Pending = 'Pending',
  Processing = 'Processing',
  Successful = 'Successful',
  Error = 'Error',
  Cancelled = 'Cancelled',
  Deleted = 'Deleted',
  DeletedSuccessfully = 'DeletedSuccessfully',
}
