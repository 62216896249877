import { Component, EventEmitter, Input, Output } from '@angular/core';

export interface SwitchSelectorOption {
  label: string;
  value: any;
}

@Component({
  selector: 'shared-switch-selector',
  templateUrl: './switch-selector.component.html',
  styleUrls: ['./switch-selector.component.scss'],
})
export class SwitchSelectorComponent {
  @Input() leftOption: SwitchSelectorOption;
  @Input() rightOption: SwitchSelectorOption;
  @Input() selectedValue: any;
  @Input() isDisabled = false;
  @Output() selectedValueChange = new EventEmitter<any>();

  onValueChanged(checked: any) {
    this.selectedValue = checked ? this.rightOption?.value : this.leftOption?.value;
    this.selectedValueChange.emit(this.selectedValue);
  }
}
