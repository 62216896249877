<div class="form-field" [ngClass]="containerClass">
  <label>{{ label }}<shared-info-icon *ngIf="labelTooltip" [tooltip]="labelTooltip" class="p-l-8"></shared-info-icon></label>

  <div class="field-content">
    <ng-content></ng-content>

    <ng-container *ngIf="control && control.invalid && (control.dirty || control.touched)">
      <mat-error *ngIf="control.errors?.required">{{ label }} is required</mat-error>
      <mat-error *ngIf="control.errors?.min">Minimum value is {{ control.errors?.min.min }}</mat-error>
      <mat-error *ngIf="control.errors?.max">Maximum value is {{ control.errors?.max.max }}</mat-error>
    </ng-container>
  </div>
</div>
