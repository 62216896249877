import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormatType } from '../../enums/format-type.enum';
import { HotKeysService } from '../../services/hotkeys.service';
import { ZoomChangeSource } from './zoom-change-source.enum';
import { ZoomChangedEvent } from './zoom-changed-event.type';
import { ZoomComponentButtonPosition } from './zoom-component-button-position.enum';
import { ZoomComponentButton } from './zoom-component-button.type';
import { ZoomDefaultButton } from './zoom-default-button.enum';

@Component({
  selector: 'app-zoom',
  templateUrl: './zoom.component.html',
  styleUrls: ['./zoom.component.scss'],
})
export class ZoomComponent implements OnInit, OnDestroy {
  @Input() public min = 0.25;
  @Input() public max = 2;
  @Input() public step = 0.25;
  @Input() public defaultZoom = 1;
  @Input() public zoom = this.defaultZoom;
  @Input() public isVertical = false;
  @Input() public showShortcutInTooltips = false;
  @Input() public zoomKeyModifier: string;
  @Input() public enableShortcuts = false;
  @Input() public extraButtons: ZoomComponentButton[] = [];
  @Input() public defaultButtons = [ZoomDefaultButton.FitToScreen, ZoomDefaultButton.ZoomIn, ZoomDefaultButton.ZoomOut, ZoomDefaultButton.ResetZoom];
  @Output() zoomChange = new EventEmitter<ZoomChangedEvent>();
  @Output() fitToScreenClick = new EventEmitter();

  defaultButtonsDefinition: ZoomComponentButton[] = [];
  readonly FormatType = FormatType;
  readonly ZoomDefaultButton = ZoomDefaultButton;
  readonly ZoomComponentButtonPosition = ZoomComponentButtonPosition;
  private _subscription = new Subscription();

  constructor(private _hotkeys: HotKeysService) {}

  ngOnInit(): void {
    this.initDefaultButtons();
    if (this.enableShortcuts) {
      this.addShortcut('+', this.zoomIn.bind(this));
      this.addShortcut('shift.+', this.zoomIn.bind(this));
      this.addShortcut('-', this.zoomOut.bind(this));
      this.addShortcut('shift.-', this.zoomOut.bind(this));
      this.addShortcut('control.0', this.resetZoom.bind(this));
      this.addShortcut('control.1', this.fitToScreen.bind(this));
    }
  }

  private addShortcut(keys: string, action: () => void): void {
    this._subscription.add(this._hotkeys.addShortcut({ keys }).subscribe(() => action()));
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  zoomIn(): void {
    const zoomValue = Math.min(this.zoom + this.step, this.max);
    this.setZoom(ZoomChangeSource.Step, zoomValue);
  }

  zoomOut(): void {
    const zoomValue = Math.max(this.zoom - this.step, this.min);
    this.setZoom(ZoomChangeSource.Step, zoomValue);
  }

  resetZoom(): void {
    this.setZoom(ZoomChangeSource.Reset, this.defaultZoom);
  }

  fitToScreen(): void {
    this.fitToScreenClick.emit();
  }

  filterExtraButtons(position: ZoomComponentButtonPosition): ZoomComponentButton[] {
    const buttons = this.extraButtons.filter(x => x.position === position);
    return buttons.length > 0 ? buttons : null;
  }

  isButtonDisabled(button: ZoomComponentButton): boolean {
    return button.disabled != null ? button.disabled() : false;
  }

  private setZoom(source: ZoomChangeSource, value: number): void {
    const oldValue = this.zoom;
    this.zoom = value;
    this.zoomChange.emit({ source, oldValue, value });
  }

  private initDefaultButtons(): void {
    const zoomModifier = this.zoomKeyModifier ? ` ${this.zoomKeyModifier} + ` : ' ';
    this.defaultButtonsDefinition = [
      {
        id: 'fit-to-screen',
        icon: 'fit-to-screen',
        tooltip: 'Fit to screen',
        position: ZoomComponentButtonPosition.Default,
        data: ZoomDefaultButton.FitToScreen,
        action: () => this.fitToScreen(),
      },
      {
        id: 'zoom-in',
        icon: 'zoom-in',
        tooltip: 'Zoom&nbsp;in' + (this.showShortcutInTooltips ? `<br/><i>(you can also use${zoomModifier}mouse wheel to zoom)</i>` : ''),
        position: ZoomComponentButtonPosition.Default,
        data: ZoomDefaultButton.ZoomIn,
        disabled: () => this.zoom >= this.max,
        action: () => this.zoomIn(),
      },
      {
        id: 'zoom-out',
        icon: 'zoom-out',
        tooltip: 'Zoom&nbsp;out' + (this.showShortcutInTooltips ? `<br/><i>(you can also use${zoomModifier}mouse wheel to zoom)</i>` : ''),
        position: ZoomComponentButtonPosition.Default,
        data: ZoomDefaultButton.ZoomOut,
        disabled: () => this.zoom <= this.min,
        action: () => this.zoomOut(),
      },
      {
        id: 'zoom-reset',
        icon: 'zoom-reset',
        tooltip: 'Zoom to 100%',
        position: ZoomComponentButtonPosition.Default,
        data: ZoomDefaultButton.ResetZoom,
        action: () => this.resetZoom(),
      },
    ];
  }
}
