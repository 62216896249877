export class SuccessRate {
  completedCount: number;
  totalCount: number;
  successCount: number;
  successRate: number;
  totalExceptionsCount: number;

  constructor(isBusinessExceptionSuccess: boolean, public businessExceptionsCount: number, public systemExceptionsCount: number, completedCount?: number, totalCount?: number) {
    this.completedCount = completedCount ?? totalCount - businessExceptionsCount - systemExceptionsCount;
    this.totalCount = totalCount ?? this.completedCount + businessExceptionsCount + systemExceptionsCount;
    if (this.totalCount === 0) {
      this.successCount = 0;
      this.successRate = 0;
      this.totalExceptionsCount = 0;
    } else {
      this.successCount = this.completedCount + (isBusinessExceptionSuccess ? businessExceptionsCount : 0);
      this.totalExceptionsCount = systemExceptionsCount + businessExceptionsCount;
      this.successRate = this.successCount / this.totalCount;
    }
  }
}
