import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Chart, TooltipItem } from 'chart.js';
import { FormatType } from 'src/app/shared/enums/format-type.enum';
import { FormatHelper } from 'src/app/shared/helpers/format.helper';
import { PlatformTextsService } from 'src/app/shared/services/platform-texts.service';
import { ActionableInsightHistogram } from 'src/app/shared/types/actionable-insight-histogram.type';
import { ChartColor } from '../chart-color.enum';

@Component({
  selector: 'shared-histogram-chart',
  templateUrl: './histogram-chart.component.html',
  styleUrls: ['./histogram-chart.component.scss'],
})
export class HistogramChartComponent implements OnInit, OnDestroy {
  @Input() histogram: ActionableInsightHistogram;
  @ViewChild('histogramChart', { static: true }) canvas: ElementRef<HTMLCanvasElement>;
  private histogramChart: Chart;

  constructor(private platformTexts: PlatformTextsService) {}

  ngOnInit(): void {
    const step = this.histogram.durations[1] - this.histogram.durations[0];
    const tickSize = this.histogram.durations.length > 9 ? Math.floor(this.histogram.durations.length / 9) : 1;
    const h = this.histogram;
    const ctx = this.canvas.nativeElement.getContext('2d');

    const data = {
      labels: this.histogram.durations,
      datasets: [
        {
          data: this.histogram.casesCounts[0].map((value, index) => ({ x: index + 0.5, y: value > 0 ? value : null })),
          backgroundColor: ChartColor.Blue,
          hoverBackgroundColor: ChartColor.Blue,
          borderWidth: 0,
          borderRadius: 4,
          barPercentage: 1,
          categoryPercentage: 1,
        },
        {
          data: this.histogram.casesCounts[1].map((value, index) => ({ x: index + 0.5, y: value > 0 ? value : null })),
          backgroundColor: ChartColor.Red,
          borderWidth: 0,
          borderRadius: 4,
          barPercentage: 1,
          categoryPercentage: 1,
        },
      ],
    };

    this.histogramChart = new Chart(ctx, {
      type: 'bar',
      data,
      options: {
        maintainAspectRatio: true,
        responsive: true,
        animation: {
          duration: 0,
        },
        scales: {
          y: {
            beginAtZero: true,
            grid: {
              drawTicks: false,
            },
            border: {
              display: false,
              dash: [8, 4],
              dashOffset: 10,
            },
            title: {
              display: true,
            },
            stacked: true,
            ticks: {
              precision: 0,
            },
          },
          x: {
            type: 'linear',
            offset: true,
            stacked: true,
            grid: {
              offset: false,
              drawOnChartArea: false,
            },
            border: {
              display: true,
              color: ChartColor.Border,
            },
            title: {
              display: true,
            },
            ticks: {
              stepSize: tickSize,
              // maxTicksLimit: 10,
              autoSkip: true,
              callback: (value, index) =>
                index * tickSize < h.durations.length
                  ? FormatHelper.format(h.durations[index * tickSize], FormatType.Time)
                  : FormatHelper.format(h.durations[h.durations.length - 1] + step, FormatType.Time),
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            mode: 'index',
            displayColors: true,
            usePointStyle: true,
            callbacks: {
              title: (items: TooltipItem<'bar'>[]) => {
                if (!items.length) {
                  return '';
                }
                const item = items[0];
                const from = FormatHelper.format(this.histogram.durations[item.dataIndex], FormatType.Time, false, true);
                const to = FormatHelper.format(this.histogram.durations[item.dataIndex + 1], FormatType.Time, false, true);
                return `${this.platformTexts.get('Item')} duration: ${from} – ${to}`;
              },
              label: (item: TooltipItem<'bar'>) => (item.parsed.y > 0 ? ` ${item.parsed.y} ${this.platformTexts.get('items')}` : ''),
              labelPointStyle: () => ({ pointStyle: 'circle', rotation: 0 }),
            },
          },
        },
      },
    });
  }

  ngOnDestroy(): void {
    this.histogramChart?.destroy();
  }
}
