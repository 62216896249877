import { Marking } from '../enums/generated.enums';
import { Edge } from './edge.type';
import { Node } from './node.type';
import { VariantStatistics } from './statistics/variants-statistics.type';

export class Variant {
  id: string;
  label: string;
  nodeIds: number[];
  edgeIds: number[];
  cycleEdgeIds?: number[] = [];
  marking?: Marking;
  statistics?: VariantStatistics;
  groupLabel?: string;
  nodes?: Node[] = [];
  edges?: Edge[] = [];
  isSelected?: boolean;
  sortOrder?: number;

  static getEmptyVariant(label: string): Variant {
    return {
      id: label.replace(' ', '-'),
      label: label ?? '',
      nodeIds: [],
      edgeIds: [],
      isSelected: true,
      marking: null,
      statistics: VariantStatistics.getEmptyStatistics(),
    };
  }
}
