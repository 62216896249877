import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { BaseModalDialogDirective } from 'src/app/shared/directives/base-modal-dialog.directive';
import { ApiService } from 'src/app/shared/services/api/api.service';
import { SelectionFacadeService } from 'src/app/shared/services/selection-facade.service';
import { ModalSize } from '../../../enums/modal-size.enum';
import { OrganizationService } from '../../../services/organization.service';
import { Data } from '../../../types/data.type';

@Component({
  selector: 'actionable-insights-manual-processing',
  templateUrl: './manual-processing-cost-modal.component.html',
  styleUrls: ['./manual-processing-cost-modal.component.scss'],
})
export class ManualProcessingCostModalComponent extends BaseModalDialogDirective {
  submitted = false;
  casesCount: number;
  monthCasesCount: number;
  yearCasesCount: number;
  formattedProcessInterval: string;
  errorMessage: string;

  form = this.formBuilder.group({
    manualProcessingCost: [this.data.masterProcessUserData?.manualProcessingCosts, [Validators.required, Validators.min(0.1), Validators.max(2000000000)]],
  });

  get size(): ModalSize {
    return ModalSize.Small;
  }

  get f() {
    return this.form.controls;
  }

  get organizationCurrency(): string {
    return this.organizationService.organizationConfiguration.currencySymbol;
  }

  get displayReset(): boolean {
    return this.data.masterProcessUserData?.manualProcessingCosts != null;
  }

  constructor(
    private formBuilder: FormBuilder,
    private data: Data,
    private selectionFacadeService: SelectionFacadeService,
    private apiService: ApiService,
    private organizationService: OrganizationService,
  ) {
    super();
  }

  async setManualProcessingCost(): Promise<void> {
    if (this.submitted || this.form.invalid) {
      return;
    }
    this.submitted = true;
    this.errorMessage = '';
    this.data.masterProcessUserData.manualProcessingCosts = this.f.manualProcessingCost.value;
    try {
      await this.apiService.updateManualProcessingCosts(this.selectionFacadeService.selectedMasterProcess.masterProcessId, this.data.masterProcessUserData.manualProcessingCosts);
      this.close();
    } catch (error) {
      this.errorMessage = 'The value exceeds the system limit. Please enter a smaller number.';
    } finally {
      this.submitted = false;
    }
  }

  async resetManualProcessingCost(): Promise<void> {
    this.data.masterProcessUserData.manualProcessingCosts = null;
    await this.apiService.updateManualProcessingCosts(this.selectionFacadeService.selectedMasterProcess.masterProcessId, this.data.masterProcessUserData.manualProcessingCosts);
    this.close();
  }
}
