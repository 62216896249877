import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { Chart, TooltipItem } from 'chart.js';
import { AnnotationOptions } from 'chartjs-plugin-annotation';
import ChartZoomPlugin from 'chartjs-plugin-zoom';
import { FormatType } from '../../../enums/format-type.enum';
import { FormatHelper } from '../../../helpers/format.helper';
import { MathHelper } from '../../../helpers/math.helper';
import { ZoomChangeSource } from '../../zoom/zoom-change-source.enum';
import { ZoomChangedEvent } from '../../zoom/zoom-changed-event.type';
import { ZoomComponentButtonPosition } from '../../zoom/zoom-component-button-position.enum';
import { ZoomComponentButton } from '../../zoom/zoom-component-button.type';
import { ZoomDefaultButton } from '../../zoom/zoom-default-button.enum';
import { ChartColor } from '../chart-color.enum';
import { tooltipTitleMultilinePlugin } from '../plugins/tooltip-title-multiline.plugin';
import { StackedBarChartData } from './stacked-bar-chart-data.type';

@Component({
  selector: 'app-stacked-bar-chart',
  templateUrl: './stacked-bar-chart.component.html',
  styleUrls: ['./stacked-bar-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StackedBarChartComponent implements AfterViewInit, OnDestroy {
  orderedLegend: string[];
  @Input() set data(data: StackedBarChartData) {
    const dataRangeChanged = this.isDataRangeDifferent(data, this._data);
    this._data = data;
    if (data) {
      data.legend ??= [];
      this.orderedLegend = data.legend && data.invertLegendOrder ? [...data.legend].reverse() : data.legend;
    }
    this.updateChart(dataRangeChanged);
  }

  get data(): StackedBarChartData {
    return this._data;
  }

  @Input() zoomEnabled = true;
  @Input() height = '330px';
  @Input() set annotations(value: AnnotationOptions[]) {
    this._annotations = value;
    this.refreshAnnotations();
  }

  ellipsisTooltipOptions = {
    display: false,
  };

  currentZoom = 1;
  maxZoom = 1;
  minZoom = 1;
  isZoomingRequired = false;

  panButtons: ZoomComponentButton[] = [
    {
      id: 'pan-left',
      icon: 'arrow-left-light',
      position: ZoomComponentButtonPosition.Start,
      disabled: () => this._barChart.scales.x.min <= 0,
      action: () => this.onPanClicked(1),
    },
    {
      id: 'pan-right',
      icon: 'arrow-right-light',
      position: ZoomComponentButtonPosition.End,
      disabled: () => this._barChart.scales.x.max >= this.data?.labels.length - 1,
      action: () => this.onPanClicked(-1),
    },
  ];

  readonly ZoomDefaultButton = ZoomDefaultButton;
  readonly zoomStep = 0.15;
  readonly defaultZoom = 1;
  private _panStep = 50;
  private _originalVisibleDataLength: number;
  private _visibleDataLengthLimits = {
    min: 7,
    max: 14,
  };

  private _barChart: Chart;
  private _data: StackedBarChartData;
  private _annotations: AnnotationOptions[];

  @ViewChild('barChart', { static: true }) canvas: ElementRef<HTMLCanvasElement>;

  constructor(private cdr: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.initializeChart();
  }

  ngOnDestroy(): void {
    this._barChart?.destroy();
  }

  onMouseOver(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
    const target = <HTMLSpanElement>event.target;
    this.ellipsisTooltipOptions = {
      display: target.clientWidth > 0 && target.scrollWidth > target.clientWidth,
    };
  }

  initializeChart(): void {
    const ctx = this.canvas.nativeElement.getContext('2d');
    this._barChart = new Chart(ctx, {
      type: 'bar',
      data: {
        datasets: [],
      },
      plugins: [ChartZoomPlugin, tooltipTitleMultilinePlugin],
      options: {
        onResize: (chart: Chart, size: { width: number; height: number }) => {
          this._visibleDataLengthLimits = {
            ...this._visibleDataLengthLimits,
            max: Math.round(size.width / 50),
          };
          this.updateChart();
        },
        maintainAspectRatio: false,
        responsive: true,
        animation: {
          duration: 0,
        },
        interaction: {
          intersect: false,
        },
        scales: {
          y: {
            stacked: true,
            grid: {
              drawTicks: false,
            },
            border: {
              display: true,
              dash: [8, 4],
            },
            ticks: {
              autoSkip: false,
              padding: 8,
              callback: value => {
                const data = this.data;
                return FormatHelper.format(value, data.dataType ?? FormatType.Number, false, false);
              },
              precision: this.data.precision ?? 0,
            },
            title: {
              display: this.data.axesLabels?.length > 0,
              text: this.data.axesLabels?.length > 0 ? this.data.axesLabels[1] : 'yyy',
            },
            afterFit: scale => {
              scale.width = Math.max(this.data.minYAxisWidth ?? 0, scale.width);
            },
          },
          x: {
            stacked: true,
            min: 0,
            grid: {
              display: false,
            },
            border: {
              display: true,
              color: ChartColor.Border,
            },
            title: {
              display: this.data.axesLabels?.length > 0,
              text: this.data.axesLabels?.length > 0 ? this.data.axesLabels[0] : 'xxx',
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            mode: 'index',
            displayColors: true,
            callbacks: {
              title: (items: TooltipItem<'bar'>[]) => this.getTooltipTitle(items),
              label: (item: TooltipItem<'bar'>) => this.getTooltipLabel(item),
              labelColor: (item: TooltipItem<'bar'>) => ({ backgroundColor: this.data.colors?.[item.datasetIndex], borderColor: '' }),
              labelPointStyle: () => ({ pointStyle: 'circle', rotation: 0 }),
            },
          },
          zoom: this.zoomEnabled && {
            limits: {
              x: {
                minRange: this._visibleDataLengthLimits.min - 1,
              },
            },
            zoom: {
              mode: 'x',
              wheel: {
                enabled: false,
              },
              onZoomComplete: this.updateZoomComponent.bind(this),
            },
            pan: {
              enabled: true,
              mode: 'x',
              onPanComplete: () => this.cdr.detectChanges(),
            },
          },
          annotation: {
            annotations: [],
          },
        },
      },
    });

    this.updateChart();
  }

  onZoomChanged(event: ZoomChangedEvent): void {
    switch (event.source) {
      case ZoomChangeSource.Step:
        const zoom = event.value - event.oldValue > 0 ? 1 + this.zoomStep : 1 - this.zoomStep;
        this._barChart.zoom(zoom);
        break;
      case ZoomChangeSource.Reset:
        if (this.currentZoom !== this.defaultZoom) {
          this._barChart.resetZoom();
        }
        break;
    }

    this.updateZoomComponent();
  }

  onPanClicked(direction: number): void {
    const value = direction * this._panStep;
    this._barChart.pan({ x: value, y: 0 });
    this.updateZoomComponent();
  }

  onFitToScreenClicked() {
    this._barChart.zoom(-100);
    this.updateZoomComponent();
  }

  private updateZoomComponent(): void {
    if (!this.zoomEnabled || this._barChart?.data?.datasets[0] == null) {
      return;
    }

    const x = this._barChart.scales.x;
    this._panStep = this._barChart.width / (x.max - x.min);

    const zoomLevel = this._originalVisibleDataLength / (x.max - x.min + 1);
    this.currentZoom = MathHelper.round(zoomLevel, 2);
    this.cdr.detectChanges();
  }

  private getTooltipTitle(items: TooltipItem<'bar'>[]): string {
    const item = items[0];
    return `${this.data.tooltipTitles ? this.data.tooltipTitles[item.dataIndex] : item.label}`;
  }

  private getTooltipLabel(item: TooltipItem<'bar'>): string {
    if (!this.data?.legend[item.datasetIndex]) {
      return `${FormatHelper.format(this.data.casesCounts[item.datasetIndex][item.dataIndex], this.data.dataType, false, true)}`;
    }

    const legend = this.tooltipEclipse(this.data?.legend[item.datasetIndex]);
    if (this.data.allVariants && item.datasetIndex === 0 && this.data.totalCasesCounts != null) {
      return `${legend}: ${FormatHelper.format(this.data.totalCasesCounts[item.dataIndex], this.data.dataType, false, true)}`;
    }

    const casesCount = FormatHelper.format(this.data.casesCounts[item.datasetIndex][item.dataIndex], this.data.dataType, false, true);
    if (this.data.dataType === FormatType.Number || this.data.totalCasesCounts == null) {
      return `${legend}: ${casesCount}`;
    } else {
      const casesCountPercentage = this.data.dataType !== FormatType.Percentage ? this.getTooltipPercentage(item) : this.getTooltipFromPercentage(item);
      return `${legend}: ${casesCount} (${casesCountPercentage})`;
    }
  }

  private getTooltipFromPercentage(item: TooltipItem<'bar'>) {
    const totalCount = this.data.totalCasesCounts[item.dataIndex];
    return totalCount > 0 ? Math.round(this.data.casesCounts[item.datasetIndex][item.dataIndex] * totalCount) : 0;
  }

  private getTooltipPercentage(item: TooltipItem<'bar'>) {
    const totalCount = this.data.totalCasesCounts[item.dataIndex];
    return totalCount > 0 ? FormatHelper.format(this.data.casesCounts[item.datasetIndex][item.dataIndex] / totalCount, FormatType.Percentage) : '0%';
  }

  private tooltipEclipse(str: string): string {
    if (!str || str.length <= 50) {
      return str;
    }
    return `${str.substring(0, 47)}...`;
  }

  private updateChart(dataRangeChanged = true): void {
    const dataLength = this.data?.labels.length;
    if (this._barChart == null || dataLength == null) {
      return;
    }

    if (dataRangeChanged && this.zoomEnabled) {
      this._originalVisibleDataLength = Math.min(this._visibleDataLengthLimits.max, dataLength);
      this.minZoom = Math.min(1, this._originalVisibleDataLength / dataLength);
      this.maxZoom = Math.max(1, MathHelper.round(this._originalVisibleDataLength / this._visibleDataLengthLimits.min, 2));
      this.isZoomingRequired = this.minZoom < 1;

      this._barChart.resetZoom();
      this._barChart.options.plugins.zoom.pan.enabled = this.isZoomingRequired;
      this._barChart.options.scales.x.max = this._originalVisibleDataLength - 1;
    }

    this._barChart.data.labels = this.data.labels;
    this._barChart.data.datasets = this.data.casesCounts
      .map((c, i) => ({
        label: this.data.legend[i],
        data: c.map((value, index) => ({ x: index, y: value })),
        backgroundColor: this.data.colors[i],
        hoverBackgroundColor: this.data.colors[i],
        maxBarThickness: this.data.maxBarThickness ?? 80,
        barPercentage: 0.75,
        borderWidth: 0,
        borderRadius: 4,
      }))
      .reverse();

    this.refreshAnnotations();
    this.updateZoomComponent();
  }

  private refreshAnnotations(): void {
    if (this._barChart?.options?.plugins?.annotation == null) {
      return;
    }
    const annotations = this._annotations ?? [];
    if (this.data.axesYLine) {
      const limitHorizontalLineAnnotation = {
        id: 'limitHorizontalLine',
        type: 'line',
        yMin: this.data.axesYLine,
        yMax: this.data.axesYLine,
        borderColor: ChartColor.Orange80,
        borderWidth: 1.5,
        drawTime: 'afterDatasetsDraw',
        label: {
          display: true,
          content: [FormatHelper.format(this.data.axesYLine, this.data.dataType)],
          position: 'end',
          padding: {
            bottom: 16,
          },
          backgroundColor: 'transparent',
          color: 'black',
          font: {
            size: 12,
            weight: 'normal',
          },
        },
      } as AnnotationOptions;
      annotations.push(limitHorizontalLineAnnotation);
    }
    this._barChart.options.plugins.annotation.annotations = annotations;
    this._barChart.update();
    this.cdr.detectChanges();
  }

  private isDataRangeDifferent(a: StackedBarChartData, b: StackedBarChartData): boolean {
    if (a == null && b == null) {
      return false;
    }

    if (a == null || b == null) {
      return true;
    }

    return a.labels.length !== b.labels.length || a.labels[0] !== b.labels[0] || a.labels.at(-1) !== b.labels.at(-1);
  }
}
