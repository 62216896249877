<mat-select
  #regionSelection
  placeholder="{{multiple ? 'Select countries' : 'Select a country'}}"
  [multiple]="multiple"
  [disabled]="disabled"
  [(ngModel)]="selectionModel"
  (opened)="markAsTouched()"
  (closed)="closed.emit()"
  (blur)="onBlur()"
  [class.mat-mdc-select-invalid]="isInvalid"
>
  <mat-select-trigger>
    <div class="selected-content" [tooltip]="selectedRegionNamesTooltip" tooltipIfEllipsis>
      <ng-container *ngIf="multiple">
        <span *ngFor="let item of selectionModel; let indexOfelement=index;" class="selected-item"
          ><i class="fi fi-{{item.toLowerCase()}}"></i>{{getRegionName(item)}}<span *ngIf="indexOfelement < selectionModel.length - 1">,</span>
        </span>
      </ng-container>
      <ng-container *ngIf="!multiple">
        <i *ngIf="selectionModel" class="fi fi-{{selectionModel.toLocaleString().toLocaleLowerCase()}}"></i>{{getRegionName(selectionModel.toLocaleString())}}
      </ng-container>
    </div>
  </mat-select-trigger>
  <mat-option *ngIf="searchEnabled">
    <shared-select-search [(ngModel)]="filter"></shared-select-search>
  </mat-option>
  <mat-option *ngFor="let region of filteredRegions" [value]="region.isoRegionName"><i class="fi fi-{{region.isoRegionName.toLowerCase()}}"></i>{{region.englishName}}</mat-option>
</mat-select>
