/* MODULES */
import { A11yModule } from '@angular/cdk/a11y';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PortalModule } from '@angular/cdk/portal';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
import { CommonModule } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule, MAT_SELECT_CONFIG } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { MAT_LUXON_DATE_ADAPTER_OPTIONS, MatLuxonDateModule, MAT_LUXON_DATE_FORMATS } from '@angular/material-luxon-adapter';
import { RouterModule } from '@angular/router';
import { sharedIcons } from '@app/svg/shared';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SvgIconsModule } from '@ngneat/svg-icon';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { TableVirtualScrollModule } from 'ng-table-virtual-scroll';
import { NgxFileDropModule } from 'ngx-file-drop';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { MatSelectSearchOptions, MAT_SELECTSEARCH_DEFAULT_OPTIONS, NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatTabsModule } from '@angular/material/tabs';

/* COMPONENTS */
import { BreadcrumbsComponent } from '../layout/breadcrumbs/breadcrumbs.component';
import { UploadFilesModalComponent } from '../layout/upload-files/upload-files-modal.component';
import { UploadProcessingComponent } from '../layout/upload-processing/upload-processing.component';
import { UploadProcessingResultModalComponent } from '../layout/upload-processing-result/upload-processing-result-modal.component';
import { BarMeterComponent } from '../modules/reporting/benchmarking/benchmark-metric/bar-meter/bar-meter.component';
import { BarChartComponent } from './components/_charts/bar-chart/bar-chart.component';
import { HistogramChartComponent } from './components/_charts/chart-histogram/histogram-chart.component';
import { ChartsModule } from './components/_charts/charts.module';
import { LineChartComponent } from './components/_charts/line-chart/line-chart.component';
import { MultiBarChartComponent } from './components/_charts/multi-bar-chart/multi-bar-chart.component';
import { ScatterChartComponent } from './components/_charts/scatter-chart/scatter-chart.component';
import { StackedBarChartComponent } from './components/_charts/stacked-bar-chart/stacked-bar-chart.component';
import { TimeSeriesChartComponent } from './components/_charts/time-series-chart/time-series-chart.component';
import { ChangePasswordModalComponent } from './components/_modals/change-password-modal/change-password-modal.component';
import { ContactUsModalComponent } from './components/_modals/contact-us-modal/contact-us-modal.component';
import { EstimationModalComponent } from './components/_modals/estimation-modal/estimation-modal.component';
import { HotkeysHelpModalComponent } from './components/_modals/hotkeys-help-modal/hotkeys-help-modal.component';
import { ManualProcessingCostModalComponent } from './components/_modals/manual-processing-cost-modal/manual-processing-cost-modal.component';
import { UserActionableInsightModalComponent } from './components/_modals/user-actionable-insight-modal/user-actionable-insight-modal.component';
import { UserActivationLinkComponent } from './components/_modals/user-activation-link/user-activation-link.component';
import { CalendarBodyComponent } from './components/calendar/calendar-body/calendar-body.component';
import { CalendarDropdownComponent } from './components/calendar/calendar-dropdown/calendar-dropdown.component';
import { CalendarHeaderComponent } from './components/calendar/calendar-header/calendar-header.component';
import { CalendarIntervalDropdownComponent } from './components/calendar/calendar-interval-dropdown/calendar-interval-dropdown.component';
import { ChipsInputComponent } from './components/chips-input/chips-input.component';
import { ClickableInputComponent } from './components/clickable-input/clickable-input.component';
import { ContextDialogComponent } from './components/context-dialog/context-dialog.component';
import { CountrySelectComponent } from './components/country-select/country-select.component';
import { DateTimePickerComponent } from './components/date-time-picker/date-time-picker.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { DurationPickerComponent } from './components/duration-picker/duration-picker.component';
import { EmptyDataComponent } from './components/empty-data/empty-data.component';
import { EmptyDataFilterComponent } from './components/empty-data-filter/empty-data-filter.component';
import { EstimationNotificationComponent } from './components/estimation-notification/estimation-notification.component';
import { ExpansionPanelComponent } from './components/expansion-panel/expansion-panel.component';
import { FileDropBoxComponent } from './components/file-drop-box/file-drop-box.component';
import { FilterInputDropdownComponent } from './components/filter-input-dropdown/filter-input-dropdown.component';
import { FilterPanelComponent } from './components/filter-panel/filter-panel.component';
import { FormFieldContainerComponent } from './components/form-field-container/form-field-container.component';
import { InfoIconComponent } from './components/info-icon/info-icon.component';
import { InfoMessageBarComponent } from './components/info-message-bar/info-message-bar.component';
import { InputWithIconComponent } from './components/input-icon/input-icon.component';
import { InputNumberComponent } from './components/input-number/input-number.component';
import { InputNumberSimpleComponent } from './components/input-number-simple/input-number-simple.component';
import { DotLoaderComponent } from './components/loading-indicator/dot-loader/dot-loader.component';
import { LoadingIndicatorComponent } from './components/loading-indicator/loading-indicator.component';
import { ModalComponent } from './components/modal/modal.component';
import { PSearchInputComponent } from './components/p-search-input/p-search-input.component';
import { PageBodyContainerComponent } from './components/page-body-container/page-body-container.component';
import { PageContainerComponent } from './components/page-container/page-container.component';
import { PageHeaderContainerComponent } from './components/page-header-container/page-header-container.component';
import { ParametersEditorComponent } from './components/parameters-editor/parameters-editor.component';
import { PerformancePieComponent } from './components/performance-pie/performance-pie.component';
import { PillStateButtonComponent } from './components/pill-state-button/pill-state-button.component';
import { PointeeButtonComponent } from './components/pointee-button/pointee-button.component';
import { PointeeMetricComponent } from './components/pointee-metric/pointee-metric.component';
import { ProcessIntervalComponent } from './components/process-interval/process-interval.component';
import { UploadProcessingItemComponent } from './components/processing-progress-circle/processing-progress-circle.component';
import { RoutingTabsComponent } from './components/routing-tabs/routing-tabs.component';
import { SearchBarInputComponent } from './components/search-bar-input/search-bar-input.component';
import { SelectCheckAllComponent } from './components/select-check-all/select-check-all.component';
import { SelectSearchComponent } from './components/select-search/select-search.component';
import { SelectAllOptionComponent } from './components/select-with-search/select-all-option/select-all-option.component';
import { SelectWithSearchComponent } from './components/select-with-search/select-with-search.component';
import { StatusInfoComponent } from './components/status-info/status-info.component';
import { SwitchComponent } from './components/switch/switch.component';
import { SwitchSelectorComponent } from './components/switch-selector/switch-selector.component';
import { TaskExceptionsTableComponent } from './components/task-exceptions-table/task-exceptions-table.component';
import { TimePickerComponent } from './components/time-picker/time-picker.component';
import { TrendComponent } from './components/trend/trend.component';
import { ValueWithTooltipComponent } from './components/value-with-tooltip/value-with-tooltip.component';
import { VariantMarkingComponent } from './components/variant-marking/variant-marking.component';
import { ZoomComponent } from './components/zoom/zoom.component';

/* DIRECTIVES */
import { AutoFocusDirective } from './directives/autofocus.directive';
import { AutoFocusSelectDirective } from './directives/autofucusSelect.directive';
import { ClickedOutsideDirective } from './directives/clicked-outside.directive';
import { DisableControlDirective } from './directives/disable-control.directive';
import { DropdownTriggerForDirective } from './directives/dropdown-trigger-for.directive';
import { InViewDirective } from './directives/in-view.directive';
import { NumberOnlyDirective } from './directives/number-only.directive';
import { OptionalMatSelectDirective } from './directives/optional-mat-select.directive';
import { PieSourceMetricsDirective } from './directives/pie-metrics-source.directive';
import { PreventFocusDirective } from './directives/prevent-focus.directive';
import { SidepanelContentDirective } from './directives/sidepanel-content.directive';
import { SidepanelHeaderDirective } from './directives/sidepanel-header.directive';
import { SvgIconDirective } from './directives/svg-icon.directive';
import { TextEllipsisDirective } from './directives/text-ellipsis.directive';
import { TitleIfEllipseDirective } from './directives/title-if-ellipse.directive';
import { TooltipDisplayTargetDirective } from './directives/tooltip-display-target.directive';
import { TooltipHideOnClickDirective } from './directives/tooltip-hide-on-click.directive';
import { TooltipIfEllipsisDirective } from './directives/tooltip-if-ellipsis.directive';

/* PIPES */
import { DateTimePipe } from './pipes/date-time.pipe';
import { EnumToTextPipe } from './pipes/enum-to-text.pipe';
import { EstimationPipe } from './pipes/estimation.pipe';
import { FileSizePipe } from './pipes/file-size.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { FormatPipe } from './pipes/format.pipe';
import { PercentagePipe } from './pipes/percentage.pipe';
import { PlatformTextsPipe } from './pipes/platform-texts.pipe';
import { TextEllipsisPipe } from './pipes/text-ellipsis.pipe';
import { UiPathPipe } from './pipes/ui-path.pipe';

import { CustomDateAdapter } from './types/custom-date-adapter.type';
import { ScrollNearEndDirective } from './directives/scroll-near-end.directive';
import { CustomTooltipComponent } from './components/custom-tooltip/custom-tooltip.component';
import { ToolTipRendererDirective } from './directives/tooltip-renderer.directive';
import { TooltipModule, TooltipOptions } from '@cloudfactorydk/ng2-tooltip-directive';
import { OrganizationService } from './services/organization.service';

const materialModules = [
  CdkStepperModule,
  CdkTableModule,
  CdkTreeModule,
  ScrollingModule,
  MatButtonModule,
  MatCheckboxModule,
  MatDialogModule,
  MatExpansionModule,
  MatIconModule,
  MatInputModule,
  MatMenuModule,
  MatPaginatorModule,
  MatSelectModule,
  MatSnackBarModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatButtonToggleModule,
  MatProgressSpinnerModule,
  MatSidenavModule,
  MatTreeModule,
  // PortalModule,
  MatRadioModule,
  MatDatepickerModule,
  MatTooltipModule,
  MatLuxonDateModule,
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ...materialModules,
    NgbModule,
    RouterModule,
    NgxMatSelectSearchModule,
    TooltipModule.forRoot({
      showDelay: 150,
      hideDelay: 150,
      zIndex: 2000, // to be on top of modal dialogs
      maxWidth: '340px',
      placement: 'bottom',
      theme: 'dark',
      tooltipClass: 'tooltip-theme-dark',
      displayTouchscreen: false,
    } as TooltipOptions),
    NgMultiSelectDropDownModule.forRoot(),
    NgxFileDropModule,
    NgxMaskDirective,
    SvgIconsModule.forChild(sharedIcons),
    ChartsModule,
    TableVirtualScrollModule,
  ],
  exports: [
    ...materialModules,
    NgxMatSelectSearchModule,
    TooltipModule,
    NgMultiSelectDropDownModule,
    NgxFileDropModule,
    TableVirtualScrollModule,
    BreadcrumbsComponent,
    ClickableInputComponent,
    VariantMarkingComponent,
    ContextDialogComponent,
    AutoFocusDirective,
    AutoFocusSelectDirective,
    TitleIfEllipseDirective,
    TooltipIfEllipsisDirective,
    TextEllipsisDirective,
    InViewDirective,
    DropdownTriggerForDirective,
    ClickedOutsideDirective,
    SidepanelContentDirective,
    SidepanelHeaderDirective,
    DisableControlDirective,
    OptionalMatSelectDirective,
    NumberOnlyDirective,
    ScrollNearEndDirective,
    PercentagePipe,
    FormatPipe,
    DateTimePipe,
    FilterPipe,
    SwitchComponent,
    FileSizePipe,
    EstimationPipe,
    TextEllipsisPipe,
    PlatformTextsPipe,
    UiPathPipe,
    EnumToTextPipe,
    PointeeButtonComponent,
    TrendComponent,
    ValueWithTooltipComponent,
    ModalComponent,
    ContactUsModalComponent,
    UploadFilesModalComponent,
    UploadProcessingComponent,
    UploadProcessingResultModalComponent,
    ProcessIntervalComponent,
    EstimationNotificationComponent,
    ChangePasswordModalComponent,
    // Material Modules
    A11yModule,
    DragDropModule,
    PortalModule,
    EstimationModalComponent,
    ManualProcessingCostModalComponent,
    PSearchInputComponent,
    StackedBarChartComponent,
    MultiBarChartComponent,
    BarChartComponent,
    LineChartComponent,
    TimeSeriesChartComponent,
    HistogramChartComponent,
    ScatterChartComponent,
    PointeeMetricComponent,
    HotkeysHelpModalComponent,
    FormFieldContainerComponent,
    PageBodyContainerComponent,
    PageContainerComponent,
    PageHeaderContainerComponent,
    DropdownComponent,
    FilterInputDropdownComponent,
    CalendarDropdownComponent,
    CalendarIntervalDropdownComponent,
    CalendarHeaderComponent,
    CalendarBodyComponent,
    SearchBarInputComponent,
    InputWithIconComponent,
    PerformancePieComponent,
    PieSourceMetricsDirective,
    ZoomComponent,
    UserActionableInsightModalComponent,
    ExpansionPanelComponent,
    EmptyDataComponent,
    EmptyDataFilterComponent,
    DotLoaderComponent,
    LoadingIndicatorComponent,
    SelectWithSearchComponent,
    PillStateButtonComponent,
    SelectCheckAllComponent,
    TimePickerComponent,
    DateTimePickerComponent,
    ChipsInputComponent,
    SelectSearchComponent,
    CountrySelectComponent,
    TooltipHideOnClickDirective,
    DurationPickerComponent,
    FilterPanelComponent,
    InputNumberComponent,
    TaskExceptionsTableComponent,
    RoutingTabsComponent,
    SvgIconDirective,
    PreventFocusDirective,
    StatusInfoComponent,
    SwitchSelectorComponent,
    InfoIconComponent,
    UserActivationLinkComponent,
    BarMeterComponent,
    InfoMessageBarComponent,
    ParametersEditorComponent,
    InputNumberSimpleComponent,
    CustomTooltipComponent,
    ToolTipRendererDirective,
    NgxMaskDirective,
  ],
  declarations: [
    ClickableInputComponent,
    BreadcrumbsComponent,
    VariantMarkingComponent,
    ContextDialogComponent,
    AutoFocusDirective,
    AutoFocusSelectDirective,
    TitleIfEllipseDirective,
    TooltipIfEllipsisDirective,
    TextEllipsisDirective,
    InViewDirective,
    DropdownTriggerForDirective,
    SidepanelContentDirective,
    SidepanelHeaderDirective,
    ClickedOutsideDirective,
    DisableControlDirective,
    OptionalMatSelectDirective,
    NumberOnlyDirective,
    ScrollNearEndDirective,
    PercentagePipe,
    FormatPipe,
    DateTimePipe,
    FilterPipe,
    SwitchComponent,
    FileSizePipe,
    EstimationPipe,
    TextEllipsisPipe,
    PlatformTextsPipe,
    UiPathPipe,
    EnumToTextPipe,
    PointeeButtonComponent,
    TrendComponent,
    ValueWithTooltipComponent,
    ModalComponent,
    ContactUsModalComponent,
    UploadFilesModalComponent,
    UploadProcessingComponent,
    UploadProcessingResultModalComponent,
    ProcessIntervalComponent,
    EstimationNotificationComponent,
    ChangePasswordModalComponent,
    EstimationModalComponent,
    ManualProcessingCostModalComponent,
    PSearchInputComponent,
    StackedBarChartComponent,
    MultiBarChartComponent,
    BarChartComponent,
    LineChartComponent,
    TimeSeriesChartComponent,
    HistogramChartComponent,
    ScatterChartComponent,
    PointeeMetricComponent,
    HotkeysHelpModalComponent,
    FormFieldContainerComponent,
    PageBodyContainerComponent,
    PageContainerComponent,
    PageHeaderContainerComponent,
    DropdownComponent,
    FilterInputDropdownComponent,
    CalendarDropdownComponent,
    CalendarIntervalDropdownComponent,
    CalendarHeaderComponent,
    CalendarBodyComponent,
    SearchBarInputComponent,
    InputWithIconComponent,
    PerformancePieComponent,
    PieSourceMetricsDirective,
    UserActionableInsightModalComponent,
    FileDropBoxComponent,
    UploadProcessingItemComponent,
    ZoomComponent,
    ExpansionPanelComponent,
    TooltipDisplayTargetDirective,
    EmptyDataComponent,
    EmptyDataFilterComponent,
    DotLoaderComponent,
    LoadingIndicatorComponent,
    SelectWithSearchComponent,
    PillStateButtonComponent,
    SwitchComponent,
    SelectCheckAllComponent,
    TimePickerComponent,
    DateTimePickerComponent,
    ChipsInputComponent,
    SelectSearchComponent,
    CountrySelectComponent,
    TooltipHideOnClickDirective,
    DurationPickerComponent,
    FilterPanelComponent,
    SelectAllOptionComponent,
    InputNumberComponent,
    TaskExceptionsTableComponent,
    RoutingTabsComponent,
    SvgIconDirective,
    PreventFocusDirective,
    StatusInfoComponent,
    SwitchSelectorComponent,
    InfoIconComponent,
    UserActivationLinkComponent,
    BarMeterComponent,
    InfoMessageBarComponent,
    ParametersEditorComponent,
    InputNumberSimpleComponent,
    CustomTooltipComponent,
    ToolTipRendererDirective,
  ],
  providers: [
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } },
    {
      provide: MAT_SELECT_CONFIG,
      useValue: {
        overlayPanelClass: 'pointee-mat-select-overlay',
        hideSingleSelectionIndicator: true,
      },
    },
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_LUXON_DATE_ADAPTER_OPTIONS, OrganizationService],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_LUXON_DATE_FORMATS },
    {
      provide: MAT_SELECTSEARCH_DEFAULT_OPTIONS,
      useValue: <MatSelectSearchOptions>{
        noEntriesFoundLabel: 'No options found',
        placeholderLabel: 'Search...',
      },
    },
    provideNgxMask(),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
