<div #modalHeader class="app-modal-header">
  <button type="button" class="btn btn-icon-only modal-close-btn" (click)="close()">
    <svg-icon key="close"></svg-icon>
  </button>
  <div class="modal-header-container">
    <h3 *ngIf="modalTitle">{{ modalTitle }}</h3>
    <ng-content select="[modalHeader]"></ng-content>
  </div>
</div>
<div class="app-modal-body scrollable-content">
  <ng-content select="[modalBody]"></ng-content>
</div>
<div *ngIf="showFooter" class="app-modal-footer">
  <ng-content select="[modalFooter]"></ng-content>
</div>
