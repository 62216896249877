<mat-select
  #selectWithSearch
  [(ngModel)]="selectionModel"
  [multiple]="multiple"
  [placeholder]="placeholder"
  [panelClass]="panelClass"
  [class]="selectClass"
  [class.mat-mdc-select-invalid]="isInvalid"
  [disabled]="disabled"
  (blur)="onBlur()"
  (opened)="markAsTouched()"
  (closed)="onClosed(selectWithSearch)">
  <mat-select-trigger *ngIf="multiple && allSelected">{{ selectAllText }}</mat-select-trigger>

  <shared-select-all-option
    *ngIf="multiple && allItemsIncludingFixed.length > 0"
    [text]="selectAllText"
    [selected]="allSelected"
    (selectedChange)="onSelectAllChanged($event)"></shared-select-all-option>
  <mat-option *ngFor="let item of fixedItems; let isLast = last" [class.bottom-divider]="isLast" [disabled]="item.disabled ?? false" [value]="item">{{ item.label }}</mat-option>
  <mat-option *ngIf="items?.length > 5">
    <shared-select-search [(ngModel)]="filter" [searchPlaceholder]="searchPlaceholder"></shared-select-search>
  </mat-option>

  <ng-container *ngFor="let group of groups$ | async">
    <mat-optgroup *ngIf="group.label">
      <b>{{ group.label | uppercase }}</b>
      <mat-option *ngFor="let item of group.items" [disabled]="item.disabled ?? false" [value]="item" [tooltip]="item.tooltip" [showDelay]="500" shared-title-if-ellipse> {{ item.label }} </mat-option>
    </mat-optgroup>
    <ng-container *ngIf="!group.label">
      <mat-option *ngFor="let item of group.items" [disabled]="item.disabled ?? false" [value]="item" [tooltip]="item.tooltip" [showDelay]="500" shared-title-if-ellipse> {{ item.label }} </mat-option>
    </ng-container>
  </ng-container>
</mat-select>
