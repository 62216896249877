export class NumberHelper {
  static safeValue(val: string | number): number {
    const safeValue = parseFloat(this.stringify(val)?.replace(',', '.'));
    return isNaN(safeValue) ? undefined : safeValue;
  }

  static stringify(val: string | number): string {
    return val === undefined || val === null ? '' : `${val}`;
  }

  static isValidNumber(val?: number, min: number = null, max: number = null): boolean {
    const isNumber = val != null && typeof val === 'number' && !isNaN(val);
    return isNumber && (min == null || val >= min) && (max == null || val <= max);
  }
}
