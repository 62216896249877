import { Directive, HostListener, Renderer2, ElementRef, Input } from '@angular/core';
import { MatSelect, MatSelectChange } from '@angular/material/select';

@Directive({
  selector: 'mat-select',
})
export class OptionalMatSelectDirective {
  @Input() hasEmptyOption = false;

  constructor(private matSelect: MatSelect, private elementRef: ElementRef, private renderer: Renderer2) {}

  @HostListener('openedChange', ['$event'])
  onOpenedChange(isOpened: boolean) {
    if (this.hasEmptyOption === false) {
      return;
    }
    if (!isOpened || this.matSelect.required || this.matSelect.multiple) {
      return; //Closing panel, or select is required or multi select
    }
    //Manually create a mat option DOM element to clear the selection
    const matOption = this.renderer.createElement('mat-option');
    this.renderer.setAttribute(matOption, 'class', 'mat-mdc-option');
    const span = this.renderer.createElement('span');
    this.renderer.setAttribute(span, 'class', 'mat-option-text');
    this.renderer.appendChild(span, this.renderer.createText('--'));
    this.renderer.appendChild(matOption, span);

    //Bind events to the new mat option
    this.renderer.listen(matOption, 'click', () => {
      this.matSelect.value = '';
      this.matSelect.ngControl.viewToModelUpdate(undefined);
      this.matSelect.selectionChange.emit(new MatSelectChange(this.matSelect, undefined));
      this.matSelect.close();
    });

    //Try to add the new mat option in first position of the list
    const panel = document.querySelector('.mat-mdc-select-panel');
    if (!panel) {
      throw new Error('Cannot find mat select panel');
    }
    this.renderer.insertBefore(panel, matOption, panel.firstChild as Node);
  }
}
