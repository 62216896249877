import { UserActionableInsight } from './actionable-insight.type';
import { Edge } from './edge.type';
import { NodeStatistics } from './statistics/node-statistics.type';
import { Variant } from './variant.type';

export class Node {
  id: number;
  activityName: string;
  activityLabel: string;
  activityType: string;
  normDuration?: number;
  subProcessId?: string;
  parentNodeId?: number;
  objectName?: string;
  actionName?: string;
  statistics: NodeStatistics;
  description: string;

  incomingEdges?: Map<number, Edge> = new Map<number, Edge>();
  outgoingEdges?: Map<number, Edge> = new Map<number, Edge>();
  children: Node[] = [];
  variants: Variant[] = [];
  isSelected: boolean;
  isAugmented: boolean;
  isBackgrounded: boolean;
  isExpanded = false;
  isVisible: boolean;
  logsCountHighlight: number;
  casesCountHighlight: number;
  durationHighlight: number;
  replayLogsCount = 0;
  userActionableInsight?: UserActionableInsight;

  get itemId(): string {
    return `N${this.id}`;
  }

  get isStartEnd(): boolean {
    return this.activityName === 'Start' || this.activityName === 'End';
  }

  get isVirtual(): boolean {
    return this.activityName.startsWith('@');
  }

  get variantIds(): string[] {
    return this.variants.map(v => v.id);
  }
}
