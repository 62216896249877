export class MathHelper {
  static round(value: number, decimals: number): number {
    const multiplier = Math.pow(10, decimals);
    return Math.round(value * multiplier) / multiplier;
  }

  static clamp(value: number, min: number, max: number): number {
    return Math.min(Math.max(value, min), max);
  }

  static sum<T>(items: T[], propertySelector: (item: T) => number): number {
    return items.reduce((a, b) => a + propertySelector(b), 0);
  }

  static avg<T>(items: T[], propertySelector: (item: T) => number): number {
    const totalLength = items.length;
    return items.reduce((a, b) => a + propertySelector(b), 0) / totalLength;
  }

  static median(numbers: number[]): number {
    if (!numbers || numbers.length === 0) {
      return 0;
    }
    const sortedNumbers = numbers.sort((a, b) => a - b);
    const middleIndex = Math.floor(sortedNumbers.length / 2);

    if (sortedNumbers.length % 2 === 0) {
      const medianSum = sortedNumbers[middleIndex - 1] + sortedNumbers[middleIndex];
      return medianSum / 2;
    } else {
      return sortedNumbers[middleIndex];
    }
  }

  static percentile(value: number, numbers: number[]): number {
    const sortedArr = numbers.sort((a, b) => a - b);
    const tenthIndex = Math.floor(sortedArr.length * value);
    return sortedArr[tenthIndex];
  }

  static oversampleArray(numbers: number[], samplesInArray: number): number[] {
    const minSamples = samplesInArray;
    let numberArray = numbers.slice();
    while (numberArray.length < minSamples) {
      const oversampledArr = [];
      for (let i = 0; i < numberArray.length; i++) {
        oversampledArr.push(numberArray[i]);
        if (numberArray[i + 1] != null) {
          oversampledArr.push((numberArray[i + 1] + numberArray[i]) / 2);
        }
      }

      numberArray = oversampledArr;
    }

    return numberArray;
  }
}
