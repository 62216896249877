import { Component, Input } from '@angular/core';
import { BaseModalDialogDirective } from 'src/app/shared/directives/base-modal-dialog.directive';
import { ModalSize } from 'src/app/shared/enums/modal-size.enum';
import { ModalService } from 'src/app/shared/services/modal.service';
import { NavigationService } from 'src/app/shared/services/navigation.service';
import { UploadFilesModalComponent } from '../upload-files/upload-files-modal.component';
import { ProcessingItemError } from '../upload-processing/processing-item-error.enum';
import { ProcessingItem } from '../upload-processing/processing-item.type';
import { ApiService } from 'src/app/shared/services/api/api.service';

@Component({
  selector: 'app-upload-processing-result-modal',
  templateUrl: './upload-processing-result-modal.component.html',
  styleUrls: ['./upload-processing-result-modal.component.scss'],
})
export class UploadProcessingResultModalComponent extends BaseModalDialogDirective {
  @Input() processingItem: ProcessingItem;

  readonly ProcessingItemError = ProcessingItemError;

  get size(): ModalSize {
    return ModalSize.Small;
  }

  constructor(private modalService: ModalService, private apiService: ApiService, private navigationService: NavigationService) {
    super();
  }

  tryAgain(): void {
    this.close();
    this.modalService.open(UploadFilesModalComponent);
  }

  async navigateToAddedAnalysis() {
    if (this.processingItem.processIds.length >= 0) {
      const processId = `P${this.processingItem.processIds[0]}`;
      await this.navigationService.navigateToOverview(processId);
    } else {
      await this.navigationService.navigateToAnalyses();
    }

    this.close();
  }

  async refreshAnalyses(): Promise<void> {
    await this.navigationService.navigateToAnalyses(true);
    this.close();
  }

  downloadMissingRows(): void {
    this.apiService.downloadMissingLines(this.processingItem.mainMasterProcessId);
  }
}
