import { Injectable } from '@angular/core';
import { BaseApiService } from './base-api.service';
import { SchedulerConditionState } from '../../types/scheduler/scheduler-condition-state.type';

@Injectable({ providedIn: 'root' })
export class SchedulerConditionStatesApiService extends BaseApiService {
  protected get baseUrl(): string {
    return `${super.baseUrl}/scheduler-condition-states`;
  }

  public async getConditionStates(): Promise<SchedulerConditionState[]> {
    return await this.get<SchedulerConditionState[]>('');
  }

  public setStateValue(id: string, value: boolean): void {
    this.patch(`${id}/value`, value);
  }
}
