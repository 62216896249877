<div class="pointee-metric">
  <div class="pointee-metric-content">
    <div *ngIf="metricType === metricTypeEnum.Pointer" class="metric-pointer">
      <span class="pointee-colored-circle" [ngStyle]="{'background-color': pointerColor}"></span>
    </div>

    <div class="metric-main-content">
      <div class="pointee-metric-label">
        <span [tooltip]="label" [options]="ellipsisTooltipOptions" (mouseover)="onMouseOver($event)"> {{label}} </span>
        <shared-info-icon *ngIf="labelInfoTooltip && displayInfoTooltip" [infoIconKey]="labelInfoIcon" [color]="labelInfoIconColor" [tooltip]="labelInfoTooltip"></shared-info-icon>
      </div>
      <div class="pointee-metric-value">
        <div *ngIf="marking" class="metric-icon" shared-variant-marking [marking]="marking"></div>

        <div class="value">
          <ng-container *ngIf="metricType === metricTypeEnum.Percentage && !isValueInvalid">
            <shared-performance-pie *ngIf="!hasException" [arcs]="pieData ?? getDefaultPieData()" [width]="24" class="metric-percentage-graphic"></shared-performance-pie>
            <shared-performance-pie
              *ngIf="hasException"
              [arcs]="pieData ?? getDefaultPieData()"
              [width]="24"
              [colors]="getPieChartColorsForException()"
              class="metric-percentage-graphic"
            ></shared-performance-pie>
          </ng-container>
          <ng-container *ngIf="!isValueInvalid">
            <svg-icon
              *ngIf="showWarning"
              key="warning"
              class="m-r-8 color-warning-icon"
              [class.cursor-pointer]="isWarningClickable"
              [tooltip]="warningTooltip"
              (click)="isWarningClickable ? warningClick.emit() : null"
            ></svg-icon>
            <span [tooltip]="tooltip" [display]="displayTooltip">{{value | format:formatType:false:false:unit}}</span>
            <span *ngIf="params?.length === 1" class="value-params">({{params[0] | format:paramsType}})</span>
            <span *ngIf="params?.length === 2" class="value-params">({{params[0] | format:paramsType}} of {{params[1] | format:paramsType}})</span>
          </ng-container>
          <span *ngIf="isValueInvalid" [tooltip]="invalidValueTooltip ?? TextConstants.notAvailable" [display]="displayTooltip">{{TextConstants.notAvailable}}</span>
        </div>
        <shared-visual-trend
          *ngIf="trendValue && !isValueInvalid"
          class="metric-trend"
          [trend]="trendValue"
          [trendType]="trendType"
          [isPositive]="trendIsPositive"
          [tooltip]="trendTooltip"
        ></shared-visual-trend>
      </div>
    </div>
  </div>
</div>
