<div class="graph-legend" *ngIf="data.labels?.length > 1">
  <div class="legend-item" *ngFor="let label of data.labels, let i = index ">
    <span class="pointee-colored-circle" [style.background-color]="datasetColors[i]"></span>
    <span class="legend-text">{{ label }} </span>
  </div>
</div>

<div class="chart-js" [style.height]="height">
  <canvas #barChart></canvas>
</div>
