<div class="form-input-number" [class.disabled]="disabled" (keydown)="onKeyDownEvent($event)" (keyup)="onButtonKeyUpEvent($event)">
  <button type="button" [id]="'btnDec_' + formControlName" class="btn left" (click)="decreaseValue()" [disabled]="decreaseDisabled" [class.disabled]="decreaseDisabled" tabindex="-1">
    <svg-icon key="arrow-button-left" fontSize="11px" />
  </button>
  <input
    type="text"
    #valueInput
    [id]="formControlName"
    appNumberOnly
    decimals="{{decimals}}"
    [(ngModel)]="stringValue"
    [disabled]="disabled"
    [class.disabled]="disabled"
    aria-placeholder="0"
    aria-labelledby="valueInput"
    [attr.aria-valuenow]="stringValue"
    [attr.aria-valuemin]="min"
    [attr.aria-valuemax]="max"
    autocomplete="off"
    [maxLength]="maxLength"
    (blur)="onBlurEvent($event)"
  />
  <button type="button" [id]="'btnInc_' + formControlName" class="btn right" (click)="increaseValue()" [disabled]="increaseDisabled" [class.disabled]="increaseDisabled" tabindex="-1">
    <svg-icon key="arrow-button-right" fontSize="11px" />
  </button>
</div>
<ng-container *ngIf="currency">
  <span class="inline-content">{{currencySymbol}}</span>
</ng-container>
