<div class="calendar-dropdown-container" *ngIf="selectedRange?.start">
  <div *ngIf="showShortcuts" class="day-button-group">
    <button *ngFor="let item of filteredShortcuts" class="btn btn-set-interval" [ngClass]="{'selected': item.selected, 'disabled': item.disabled}" [disabled]="item.disabled" (click)="setRangeManually(item)">
      {{item.label}}
    </button>
  </div>

  <div class="inline-calendar-card">
    <mat-calendar
      #calendar
      [selected]="selectedDateRange"
      [minDate]="minDateTime"
      [dateFilter]="dateFilter"
      [dateClass]="setClass()"
      [startAt]="startAt"
      [headerComponent]="customCalendarHeader"
      (selectedChange)="selectedChanged($event)"
      class="calendar"
    ></mat-calendar>
  </div>
</div>
