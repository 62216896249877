@if (useTabs) {
  <mat-tab-group [selectedIndex]="selectedTab" [contentTabIndex]="selectedTab" (selectedTabChange)="switchSelectedTab($event)" preserveContent="true" [animationDuration]="0" mat-align-tabs="end">
    @for (tab of menuItem.tabs; track $index) {
      <mat-tab [label]="tab.label" [aria-labelledby]="tab.subPageType"></mat-tab>
    }
  </mat-tab-group>
} @else {
  <mat-button-toggle-group [value]="selectedTab" (change)="switchToggleButton($event)" hideSingleSelectionIndicator="true" aria-label="Routing tabs">
    @for (tab of menuItem.tabs; track $index; let i = $index) {
      <mat-button-toggle [value]="i" [aria-labelledby]="tab.subPageType">{{ tab.label }}</mat-button-toggle>
    }
  </mat-button-toggle-group>
}
