import { Component, Input } from '@angular/core';
import { Marking } from '../../enums/generated.enums';

@Component({
  selector: '[shared-variant-marking]',
  templateUrl: './variant-marking.component.html',
  styleUrls: ['./variant-marking.component.scss'],
})
export class VariantMarkingComponent {
  markingIcon: string;
  markingClass: string;

  @Input() set marking(marking: Marking) {
    this.markingIcon = marking == null ? 'questionCircle' : 'circle';
    this.markingClass = marking == null ? 'marking-undefined' : `marking-${marking.toString().toLowerCase()}`;
  }
}
