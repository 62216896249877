/* eslint-disable max-classes-per-file */
import { ActionableInsightCategory, ActionableInsightType } from '../enums/generated.enums';
import { ActionableInsightHistogram } from './actionable-insight-histogram.type';
import { AttributesCounts } from './attributes-counts.type';
import { AttributesValues } from './attributes-values.type';

export class ActionableInsight {
  id: string;
  type: ActionableInsightType;
  categories: ActionableInsightCategory[] = [];
  count: number;
  potential: number;
  variantIds: string[];
  location: Location;
  nodeIds: number[];
  edgeIds: number[];
  expanded: boolean;
  savedCosts: number;
  savedRobotHours: number;
  savedItemMeanDuration: number;
  states: Set<string> = new Set();
}

export class ExceptionActionableInsight extends ActionableInsight {
  isBusinessException: boolean;
  isItemException: boolean;
  percentage: number;
  attributesCounts: AttributesCounts;
  casesWithHandledExceptionsCount: number;
  exceptionTexts: ExceptionCause[];
  handledExceptions: ExceptionCause[];
  itemExceptions: ExceptionCause;
  ids: string[];
}

export class ExceptionCause {
  count: number;
  exceptionText: string;
  attributesCounts: AttributesCounts;
  ids: string[];
  location: Location;
  variantIds: string[];
}

export class ExceptionBluePrismActionableInsight extends ActionableInsight {
  isBusinessException: boolean;
  exceptionText: string;
  percentage: number;
  processName: string;
  pageName: string;
  stageName: string;
  stageId: string;
  attributesCounts: AttributesCounts;
  totalAttributesCounts: AttributesCounts;
  attributesValues: AttributesValues;
}

export class ForcedStopActionableInsight extends ActionableInsight {
  percentage: number;
  averageInitializationAndFinalizationCosts: number;
  attributesCounts: AttributesCounts;
  sessionIds: string[];
}

export class WaitOptimizationActionableInsight extends ActionableInsight {
  meanDuration: number;
  isDelay: boolean;
  isSleep: boolean;
}

export class UnnecessaryInitializationsActionableInsight extends ActionableInsight {
  percentage: number;
  totalInitializationCount: number;
  maxInitializationRepetionInJob: number;
  averageCostOfInitialization: number;
  attributesCounts: AttributesCounts;
  sessionIds: string[];
}

export class OutliersActionableInsight extends ActionableInsight {
  percentage: number;
  maxDuration: number;
  minDuration: number;
  medianDuration: number;
  histogramAll: ActionableInsightHistogram;
  itemIds: string[];
  itemDurations: number[];
}

export class IdleRunsActionableInsight extends ActionableInsight {
  percentage: number;
  attributesCounts: AttributesCounts;
  sessionIds: string[];
}

export class ItemDurationTrendActionableInsight extends ActionableInsight {
  days: ItemDurationTrendDay[];
}

export class ItemDurationTrendDay {
  date: Date;
  averageDurations: number[];
  averageEnds: Date[];
  slowdownPercentage: number;
}

export class InterruptedJobsActionableInsight extends ActionableInsight {
  attributesCounts: AttributesCounts;
  sessionIds: string[];
  interruptionInLastSession: boolean;
}

export class FakeActionableInsight extends ActionableInsight {
  whatHappenedFirstLine: string;
  whatHappenedRest: string;
  youShouldAlsoKnow: string;
  recommendedSteps: string;
}

export class UserActionableInsight extends ActionableInsight {
  title: string;
  note: string;
  userName: string;
  location: Location;
}

export class Location {
  processName: string;
  subProcessName: string;
  activityName: string;
  processId: string;
  itemId: string;
  processLabel: string;
  subProcessLabel: string;
  activityLabels: string[];
}
