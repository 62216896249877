import { Injectable } from '@angular/core';
import { Region } from '../types/region.type';
import { ApiService } from './api/api.service';

@Injectable({ providedIn: 'root' })
export class RegionCacheService {
  get regions(): Region[] {
    return this._regions;
  }

  private _regions: Region[];
  private initializationPromise: Promise<Region[]>;

  constructor(private apiService: ApiService) {}

  public async initialize() {
    if (this._regions != null) {
      return;
    }

    if (this.initializationPromise != null) {
      await this.initializationPromise;
      return;
    }

    this.initializationPromise = this.apiService.organizationSettings.getOrganizationSupportedRegions();
    this._regions = await this.initializationPromise;
  }

  getRegion(isoRegionName: string): Region {
    return this._regions?.find(r => r.isoRegionName === isoRegionName);
  }

  getRegionName(isoRegionName: string): string {
    return this.getRegion(isoRegionName)?.englishName;
  }

  getRegionNames(isoRegionNames: string[]): string[] {
    return isoRegionNames.map(isoRegionName => this.getRegionName(isoRegionName));
  }

  getRegionNamesString(isoRegionNames: string[]): string {
    return this.getRegionNames(isoRegionNames).join(', ');
  }

  getRegionsByIsoRegionNames(isoRegionNames: string[], all = false): Region[] {
    if ((isoRegionNames == null || isoRegionNames?.length === 0) && all) {
      return this._regions;
    } else {
      return this._regions?.filter(r => isoRegionNames.includes(r.isoRegionName));
    }
  }

  public clear(): void {
    this._regions = null;
    this.initializationPromise = null;
  }
}
