import { Marking } from '../enums/generated.enums';
import { UserActionableInsight } from './actionable-insight.type';
import { Node } from './node.type';
import { EdgeStatistics } from './statistics/edge-statistics.type';
import { Variant } from './variant.type';

export class Edge {
  description: string;
  id: number;
  from: number;
  to: number;
  marking: Marking;
  statistics: EdgeStatistics;

  variants: Variant[] = [];
  startNode?: Node;
  endNode?: Node;
  width?: number;
  isStartEnd?: boolean;
  isSelected: boolean;
  isAugmented: boolean;
  isBackgrounded: boolean;
  isVisible: boolean;

  quantityHighlight: number;
  transfersCountHighlight: number;
  durationHighlight: number;

  replayTransfersCount = 0;
  replaySpeedInSeconds: number;
  replayPeriodInSeconds: number;
  userActionableInsight?: UserActionableInsight;

  get itemId(): string {
    return `E${this.id}`;
  }

  get variantIds(): string[] {
    return this.variants.map(v => v.id);
  }

  public refreshMarking(): void {
    let marking: Marking = null;
    this.variants.forEach(v => (marking = this.getMarkingValue(marking) > this.getMarkingValue(v.marking) ? v.marking : marking));
    this.marking = marking;
  }

  private getMarkingValue(marking?: Marking): number {
    switch (marking) {
      case Marking.Success:
        return 1;
      case Marking.Exception:
        return 2;
      default:
        return 3;
    }
  }
}
