import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { isArrayEqual } from '../../helpers/array.helper';
import { ChartColor } from '../_charts/chart-color.enum';

@Component({
  selector: 'shared-performance-pie',
  templateUrl: './performance-pie.component.html',
  styleUrls: ['./performance-pie.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PerformancePieComponent implements AfterViewInit {
  private _arcs: number[] = [0, 0, 0, 0];
  readonly pieColors: ChartColor;
  @Input() width = 32;
  @Input() colors: string[] = [ChartColor.Green, ChartColor.Orange, ChartColor.Red, ChartColor.Gray];
  @Input() set arcs(value: number[]) {
    if (value == null || isArrayEqual(this._arcs, value)) {
      return;
    }

    this._arcs = value;
    this.renderContentOnCanvas();
  }

  @ViewChild('processPerformancePie', { static: true }) private _canvasPieRef: ElementRef<HTMLCanvasElement>;

  ngAfterViewInit(): void {
    this.renderContentOnCanvas();
  }

  private renderContentOnCanvas(): void {
    const radius = this.width / 2;
    let currentAngle = 4.712388975;
    const ctx = this._canvasPieRef.nativeElement.getContext('2d');
    this._canvasPieRef.nativeElement.width = this.width;
    this._canvasPieRef.nativeElement.height = this.width;
    const amount = this._arcs.reduce((total, CurrVal) => total + CurrVal, 0);
    if (amount === 0) {
      ctx.beginPath();
      ctx.arc(radius, radius, radius, 0, 2 * Math.PI);
      ctx.fillStyle = this.colors[3];
      ctx.fill();
    } else {
      this._arcs.forEach((arc, index) => {
        //calculating the angle the slice (portion) will take in the chart
        const portionAngle = arc * 2 * Math.PI;
        //drawing an arc and a line to the center to differentiate the slice from the rest
        ctx.beginPath();
        ctx.arc(radius, radius, radius, currentAngle, currentAngle + portionAngle);
        currentAngle += portionAngle;
        ctx.lineTo(radius, radius);
        ctx.fillStyle = this.colors[index];
        ctx.fill();
      });
    }
  }
}
