import { Directive, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';

@Directive({
  selector: '[appScrollNearEnd]',
})
export class ScrollNearEndDirective implements OnInit {
  @Output() nearEnd: EventEmitter<void> = new EventEmitter<void>();
  @HostListener('window:scroll', ['$event.target'])
  windowScrollEvent() {
    // height of whole window page
    const heightOfWholePage = this.window.document.documentElement.scrollHeight;
    // how big in pixels the element is
    const heightOfElement = this.el.nativeElement.scrollHeight;
    // currently scrolled Y position
    const currentScrolledY = this.window.scrollY;
    // height of opened window - shrinks if console is opened
    const innerHeight = this.window.innerHeight;
    const spaceOfElementAndPage = heightOfWholePage - heightOfElement;
    const scrollToBottom = heightOfElement - innerHeight - currentScrolledY + spaceOfElementAndPage;
    // if we are near the end of the page emit event ( 1/6 works great for whole page)
    if (scrollToBottom < heightOfElement / 6) {
      this.nearEnd.emit();
    }
  }

  private window!: Window;

  constructor(private el: ElementRef) {}

  ngOnInit(): void {
    // save window object for type safety
    this.window = window;
  }
}
