<div class="app-dialog-box" [ngClass]="dialogBoxClass" [class.in]="visibleAnimate">
  <div class="dialog-box" [ngClass]="dialogClass">
    <div class="btn-caret" *ngIf="caretPosition" [ngStyle]="{ left: caretPosition + 'px' }"></div>
    <div *ngIf="closeBtn" class="close-icon">
      <button type="button" class="btn btn-icon-only" (click)="close()">
        <svg-icon key="close" fontSize="14px"></svg-icon>
      </button>
    </div>

    <div class="dialog-header">
      <ng-content select=".app-dialog-header"></ng-content>
    </div>

    <div class="dialog-body">
      <ng-content select=".app-dialog-body"></ng-content>
    </div>

    <div class="dialog-footer">
      <ng-content select=".app-dialog-footer"></ng-content>
    </div>
  </div>
</div>
