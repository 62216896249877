import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { Chart, FontSpec } from 'chart.js';
import { AnnotationOptions, LabelOptions, LabelPosition } from 'chartjs-plugin-annotation';
import { ChartColor } from '../../../../../shared/components/_charts/chart-color.enum';
import { FormatType } from '../../../../../shared/enums/format-type.enum';
import { FormatHelper } from '../../../../../shared/helpers/format.helper';
import { MathHelper } from '../../../../../shared/helpers/math.helper';
import { TextHelper } from '../../../../../shared/helpers/text.helper';
import { BenchmarkMetricValues } from '../../../../../shared/types/benchmark-metrics-entry.type';

@Component({
  selector: 'app-bar-meter',
  templateUrl: './bar-meter.component.html',
  styleUrls: ['./bar-meter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BarMeterComponent implements AfterViewInit, OnDestroy {
  @Input() public set values(value: BenchmarkMetricValues) {
    this._values = value;
    this.updateChart();
  }

  public get values() {
    return this._values;
  }

  @Input() higherIsBetter: boolean;
  @Input() formatType = FormatType.Number;
  @Input() unit: string;

  @ViewChild('barChart', { static: true }) canvas: ElementRef<HTMLCanvasElement>;

  private _values: BenchmarkMetricValues;
  private barChart: Chart;
  private barOffset = 10;
  private barWidth = 100;

  ngAfterViewInit(): void {
    this.initializeChart();
  }

  ngOnDestroy(): void {
    this.barChart?.destroy();
  }

  private initializeChart(): void {
    const ctx = this.canvas.nativeElement.getContext('2d');
    this.barChart = new Chart(ctx, {
      type: 'bar',
      data: {
        datasets: [],
      },
      options: {
        indexAxis: 'y',
        maintainAspectRatio: false,
        responsive: true,
        animation: {
          duration: 0,
        },
        layout: {
          padding: {
            right: 32,
            left: 32,
          },
        },
        scales: {
          y: {
            grid: {
              display: false,
            },
            border: {
              display: false,
            },
            ticks: {
              display: false,
            },
          },
          x: {
            min: 0,
            max: 2 * this.barOffset + this.barWidth,
            grid: {
              display: false,
            },
            border: {
              display: false,
            },
            ticks: {
              display: false,
            },
          },
        },
        plugins: {
          title: {
            display: false,
          },
          tooltip: {
            enabled: false,
          },
          legend: {
            display: false,
          },
          annotation: {
            clip: false,
          },
        },
      },
    });

    const chartArea = this.barChart.chartArea;
    const gradient = this.barChart.ctx.createLinearGradient(chartArea.left, 0, chartArea.right, 0);
    gradient.addColorStop(this.higherIsBetter ? 0 : 1, this.values.current != null ? ChartColor.Blue16 : ChartColor.Gray16);
    gradient.addColorStop(this.higherIsBetter ? 1 : 0, this.values.current != null ? ChartColor.Blue : ChartColor.Gray);

    this.barChart.data.labels = [''];
    this.barChart.data.datasets = [
      {
        data: [this.barWidth + 2 * this.barOffset],
        maxBarThickness: 32,
        barPercentage: 0.75,
        borderWidth: 0,
        borderRadius: 4,
        borderSkipped: false,
        backgroundColor: gradient,
      },
    ];

    this.updateChart();
  }

  private createAnnotations(): AnnotationOptions[] {
    function createScaler(max: number, higherIsBetter: boolean, width: number, offset: number): (value: number) => number {
      return (value: number) => {
        if (value == null) {
          return null;
        }
        let result = (width * value) / max;
        result = higherIsBetter ? result : 100 - result;
        return MathHelper.clamp(result, 0, 100) + offset;
      };
    }

    const annotations = [];

    // bar full width is 110 = 5 (left padding) + 100 (value) + 5 (right padding)
    const max = Math.max(this.values.bestInClass ?? 0, this.values.current ?? 0, this.values.average ?? 0);
    const scaler = createScaler(max, this.higherIsBetter, this.barWidth, this.barOffset);

    const averageValuePosition = scaler(this.values.average);
    const bestInClassValuePosition = scaler(this.values.bestInClass);

    const valuesDistance = Math.abs(averageValuePosition - bestInClassValuePosition);
    const valuesDistanceInPx = (this.barChart.chartArea.width / this.barWidth) * valuesDistance;
    const minLabelsDistanceInPx = TextHelper.getTextWidth('Avg.:Best in class:', "500 14px 'Roboto'", 0) / 2 + 16;
    const showAvgBelowBar = valuesDistanceInPx < minLabelsDistanceInPx;

    if (averageValuePosition != null) {
      annotations.push(
        this.createReferenceValueAnnotation(
          ['Avg.', this.formatValue(this.values.average)],
          averageValuePosition,
          showAvgBelowBar,
          this.values.current != null ? ChartColor.FontTitle : ChartColor.DarkGray,
        ),
      );
    }

    if (bestInClassValuePosition) {
      if (this.values.current == null) {
        annotations.push(this.createReferenceValueAnnotation(['Best in class', this.formatValue(this.values.bestInClass)], bestInClassValuePosition, false, ChartColor.DarkGray));
      } else if ((this.higherIsBetter && this.values.bestInClass > this.values.current) || (!this.higherIsBetter && this.values.bestInClass < this.values.current)) {
        annotations.push(this.createReferenceValueAnnotation(['Best in class', this.formatValue(this.values.bestInClass)], bestInClassValuePosition));
      } else {
        annotations.push(this.createReferenceValueAnnotation(['Best in class = YOU', ''], bestInClassValuePosition));
      }
    }

    if (this.values.current != null) {
      annotations.push(...this.createCurrentValueAnnotation(['You', this.formatValue(this.values.current)], scaler(this.values.current)));
    } else {
      annotations.push(this.createNoCurrentValueAnnotation());
    }

    return annotations;
  }

  private formatValue(value: number): string {
    return FormatHelper.format(value, this.formatType, false, false, this.unit);
  }

  private updateChart(): void {
    if (this.barChart == null) {
      return;
    }

    const chartArea = this.barChart.chartArea;
    const gradient = this.barChart.ctx.createLinearGradient(chartArea.left, 0, chartArea.right, 0);
    gradient.addColorStop(0, this.values.current != null ? ChartColor.Blue16 : ChartColor.HavelkaAnothorGray);
    gradient.addColorStop(1, this.values.current != null ? ChartColor.Blue : ChartColor.HavelkaAnothorGray);
    this.barChart.data.datasets[0].backgroundColor = gradient;

    this.barChart.options.plugins.annotation.annotations = this.createAnnotations();
    this.barChart.update();
  }

  private createReferenceValueAnnotation(label: string[], position: number, showValueBelowBar = false, fontColor?: string): AnnotationOptions {
    return {
      type: 'line',
      yMin: -0.2,
      yMax: 0.2,
      xMin: position,
      xMax: position,
      borderDash: [2, 3],
      borderWidth: 2,
      borderColor: ChartColor.FontTitle,
      label: this.createAnnotationLabel(label, showValueBelowBar ? 'end' : 'start', showValueBelowBar ? 28 : -24, fontColor),
    };
  }

  private createCurrentValueAnnotation(label: string[], position: number): AnnotationOptions[] {
    return [
      {
        type: 'line',
        yMin: -0.2,
        yMax: 0.2,
        xMin: position,
        xMax: position,
        borderWidth: 3,
        borderColor: ChartColor.Green,
        label: this.createAnnotationLabel(label, 'end', 28),
      },
      {
        type: 'point',
        backgroundColor: ChartColor.Green,
        borderWidth: 0,
        radius: 4,
        xValue: position,
        yValue: 0.2,
      },
      {
        type: 'point',
        backgroundColor: ChartColor.Green,
        borderWidth: 0,
        radius: 4,
        xValue: position,
        yValue: -0.2,
      },
    ];
  }

  private createNoCurrentValueAnnotation(): AnnotationOptions {
    return {
      type: 'label',
      content: 'You = N/A',
      color: ChartColor.DarkGray,
      padding: 4,
      position: 'start',
      xValue: 0,
      yValue: 0,
      yAdjust: 40,
      font: {
        size: 14,
        weight: 500,
        family: 'Roboto',
      } as FontSpec,
    };
  }

  private createAnnotationLabel(label: string[], position: LabelPosition, yAdjust: number, fontColor?: string): LabelOptions {
    return {
      display: true,
      content: label,
      position,
      padding: 4,
      backgroundColor: ChartColor.Transparent,
      color: fontColor ?? ChartColor.FontTitle,
      borderRadius: 0,
      yAdjust,
      font: {
        size: 14,
        weight: 500,
        family: 'Roboto',
      } as FontSpec,
    };
  }
}
