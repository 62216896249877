import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, FormControlDirective, FormControlName, FormGroupDirective, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { RegionCacheService } from '../../services/region.service';
import { Region } from '../../types/region.type';

type Selection = string | string[];

@Component({
  selector: 'shared-country-select',
  templateUrl: './country-select.component.html',
  styleUrls: ['./country-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CountrySelectComponent,
    },
  ],
})
export class CountrySelectComponent implements ControlValueAccessor, OnInit {
  @Input() multiple = false;
  @Input() searchEnabled = false;
  @Input() regions: Region[];
  @Input() set value(value: Selection) {
    this._selectionModel = value;
    this.cdr.markForCheck();
  }

  @Output() valueChange = new EventEmitter<Selection>();
  @Output() closed = new EventEmitter();

  filteredRegions: Region[] = [];
  formControl: FormControl;
  disabled = false;
  selectedRegionNamesTooltip: string;

  private onChange = (value: Selection) => {};
  private onTouched = () => {};
  private touched = false;
  private _selectionModel: Selection;
  private _filter = '';

  get selectionModel(): Selection {
    return this._selectionModel;
  }

  set selectionModel(value: Selection) {
    this._selectionModel = value;
    this.valueChange.emit(value);
    if (this.multiple) {
      this.selectedRegionNamesTooltip = this.getSelectedRegionNames()?.join(', ');
    } else {
      this.selectedRegionNamesTooltip = this.getRegionName(value as string);
    }
    this.markAsTouched();
    this.onChange(value);
  }

  get filter(): string {
    return this._filter;
  }

  set filter(value: string) {
    this._filter = value;
    this.filterItems();
  }

  get isInvalid(): boolean {
    return this.formControl?.invalid && this.formControl?.touched;
  }

  @ViewChild('regionSelection') regionSelection: MatSelect;

  constructor(private injector: Injector, private cdr: ChangeDetectorRef, private regionService: RegionCacheService) {}

  async ngOnInit(): Promise<void> {
    await this.regionService.initialize();
    this.regions = this.regions ?? this.regionService.regions;
    this.filterItems();
    try {
      const ngControl = this.injector.get(NgControl);
      if (ngControl instanceof FormControlName) {
        this.formControl = this.injector.get(FormGroupDirective).getControl(ngControl);
      } else {
        this.formControl = (ngControl as FormControlDirective).form;
      }
    } catch {
      // component is not used as form control
    }

    this.selectedRegionNamesTooltip = this.getSelectedRegionNames()?.join(', ');
    this.cdr.markForCheck();
  }

  onBlur() {
    this.onTouched();
  }

  getRegionName(isoRegionName: string): string {
    return this.regionService.getRegionName(isoRegionName);
  }

  writeValue(value: Selection): void {
    this.value = value;
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cdr.markForCheck();
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
      this.cdr.markForCheck();
    }
  }

  joinRegionNames(regions: Region[]): string {
    return regions.map(r => r.englishName).join(', ');
  }

  private filterItems() {
    const filterValue = this.filter.toLowerCase();
    this.filteredRegions =
      this.filter === '' ? this.regions.slice() : this.regions.filter(i => i.englishName.toLowerCase().includes(filterValue) || i.isoRegionName.toLowerCase().includes(filterValue));
  }

  private getSelectedRegionNames(): string[] {
    return this.multiple ? (this.selectionModel as string[])?.map(r => this.getRegionName(r)) : [this.getRegionName(this.selectionModel as string)];
  }
}
