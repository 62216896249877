<div class="zoom-container" [class.vertical]="isVertical">
  <ng-container *ngIf="filterExtraButtons(ZoomComponentButtonPosition.Start) as buttons">
    <button
      *ngFor="let btn of buttons; let i = index"
      class="btn btn-icon"
      [class.disabled]="isButtonDisabled(btn)"
      [attr.id]="btn.id"
      [disabled]="isButtonDisabled(btn)"
      [style.order]="-1000 + i"
      [tooltip]="btn.tooltip"
      [placement]="isVertical ? 'left' : 'top'"
      (click)="btn.action()"
    >
      <svg-icon [key]="btn.icon"></svg-icon>
    </button>
    <hr [style.order]="-1" />
  </ng-container>

  <ng-container *ngFor="let btn of defaultButtonsDefinition">
    <button
      *ngIf="defaultButtons.includes(btn.data)"
      class="btn btn-icon"
      [class.disabled]="isButtonDisabled(btn)"
      [attr.id]="btn.id"
      [disabled]="isButtonDisabled(btn)"
      [style.order]="defaultButtons.indexOf(btn.data)"
      [tooltip]="btn.tooltip"
      [placement]="isVertical ? 'left' : 'top'"
      (click)="btn.action()"
    >
      <ng-container *ngIf="btn.data === ZoomDefaultButton.ResetZoom"> {{ zoom | format:FormatType.Percentage }} </ng-container>
      <svg-icon *ngIf="btn.data !== ZoomDefaultButton.ResetZoom" [key]="btn.icon"></svg-icon>
    </button>
  </ng-container>

  <ng-container *ngIf="filterExtraButtons(ZoomComponentButtonPosition.End) as buttons">
    <hr [style.order]="1000" />
    <button
      *ngFor="let btn of buttons; let i = index"
      class="btn btn-icon"
      [class.disabled]="isButtonDisabled(btn)"
      [attr.id]="btn.id"
      [disabled]="isButtonDisabled(btn)"
      [style.order]="1000 + i"
      [tooltip]="btn.tooltip"
      [placement]="isVertical ? 'left' : 'top'"
      (click)="btn.action()"
    >
      <svg-icon [key]="btn.icon"></svg-icon>
    </button>
  </ng-container>
</div>
