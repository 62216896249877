import { Injectable } from '@angular/core';
import { DateHelper } from '../../helpers/date.helper';
import { BenchmarkMetricsEntry } from '../../types/benchmark-metrics-entry.type';
import { BaseApiService } from './base-api.service';

@Injectable({ providedIn: 'root' })
export class BenchmarkMetricsApiService extends BaseApiService {
  protected get baseUrl(): string {
    return `${super.baseUrl}/benchmark-metrics`;
  }

  async getMetrics(fromDate: Date, toDate: Date): Promise<BenchmarkMetricsEntry[]> {
    const params = {
      fromDate: `${DateHelper.formatApiDateOnly(fromDate)}`,
      toDate: `${DateHelper.formatApiDateOnly(toDate)}`,
    };
    return await this.get<BenchmarkMetricsEntry[]>('', params);
  }
}
