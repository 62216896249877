import { Component, ViewEncapsulation, Input, ViewChild, ElementRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'shared-modal',
  templateUrl: 'modal.component.html',
  styleUrls: ['modal.component.scss'],
  host: { class: 'modal-body' },
  encapsulation: ViewEncapsulation.None,
})
export class ModalComponent {
  @Input() modalTitle: string;
  @Input() showFooter = false;

  @ViewChild('modalHeader', { static: true }) modalHeader: ElementRef<HTMLElement>;

  constructor(private _activeModal: NgbActiveModal) {}

  close(result?: any): void {
    this._activeModal.close(result);
  }
}
