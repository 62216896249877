/* eslint-disable @typescript-eslint/indent */
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, OnChanges, OnDestroy, SimpleChanges, ViewChild } from '@angular/core';
import { Chart, TooltipItem } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { FormatHelper } from '../../../helpers/format.helper';
import { TextHelper } from '../../../helpers/text.helper';
import { TextConstants } from '../../../text-constants';
import { MultiMetricBarChartItem } from '../../../types/metric-barchart-data.type';
import { ChartColor } from '../chart-color.enum';
import { tooltipTitleMultilinePlugin } from '../plugins/tooltip-title-multiline.plugin';
import { MultiBarChartConfiguration } from './multi-bar-chart-configuration.type';

@Component({
  selector: 'app-multi-bar-chart',
  templateUrl: './multi-bar-chart.component.html',
  styleUrls: ['./multi-bar-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiBarChartComponent implements AfterViewInit, OnChanges, OnDestroy {
  @Input() data: MultiMetricBarChartItem[];
  @Input() configuration: MultiBarChartConfiguration;
  @Input() unnamedItemLabel = TextConstants.notAvailable;

  get maxBarThickness(): number {
    return this.configuration?.maxBarThickness ?? 48;
  }

  get fixedLabelWidth(): boolean {
    return this.configuration?.fixedLabelWidth ?? false;
  }

  get dynamicHeight(): boolean {
    return this.configuration?.dynamicHeight ?? false;
  }

  readonly fixedLabelWidthFactor = 0.5;

  private _barChart: Chart;

  @ViewChild('barChart', { static: true }) canvas: ElementRef<HTMLCanvasElement>;

  ngAfterViewInit(): void {
    this.initializeChart();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.configuration?.isFirstChange() === false || changes.data?.isFirstChange() === false) {
      this.updateChart();
    }
  }

  ngOnDestroy(): void {
    this._barChart?.destroy();
  }

  initializeChart(): void {
    const ctx = this.canvas.nativeElement.getContext('2d');
    this._barChart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: [],
        datasets: [],
      },
      plugins: [ChartDataLabels, tooltipTitleMultilinePlugin],
      options: {
        indexAxis: 'y',
        maintainAspectRatio: false,
        responsive: true,
        animation: {
          duration: 0,
        },
        scales: {
          y: {
            afterFit: this.fixedLabelWidth
              ? scale => {
                  scale.width = scale.chart.width * this.fixedLabelWidthFactor;
                }
              : undefined,
            grid: {
              drawTicks: this.fixedLabelWidth,
              tickLength: this.fixedLabelWidth ? 1000 : undefined,
            },
            border: {
              display: true,
              dash: [8, 4],
            },
            ticks: {
              crossAlign: 'far',
              callback: (value: string | number, index: number) => {
                const label = this.data[index].label || this.unnamedItemLabel;
                return TextHelper.textEllipsis(label, 100);
              },
            },
          },
          x: {
            grid: {
              display: false,
            },
            border: {
              display: false,
            },
            ticks: {
              display: false,
            },
          },
        },
        plugins: {
          legend: {
            display: this.configuration.showLegend ?? false,
            position: 'bottom',
            labels: {
              boxHeight: 12,
              boxWidth: 24,
              font: {
                size: 14,
              },
              useBorderRadius: true,
              borderRadius: 4,
            },
          },
          title: {
            display: this.configuration.title != null,
            align: 'start',
            color: ChartColor.FontTitle,
            padding: {
              bottom: 16,
            },
            font: {
              size: 16,
              lineHeight: '24px',
              weight: 500,
            },
          },
          tooltip: {
            enabled: true,
            intersect: true,
            mode: 'y',
            caretPadding: 8,
            boxPadding: 4,
            callbacks: {
              label: (item: TooltipItem<'bar'>) => {
                return FormatHelper.format(this.data[item.dataIndex].values[item.datasetIndex], this.configuration.formatType, false, true, this.configuration.unit);
              },
            },
          },
          datalabels: {
            display: true,
            anchor: 'end',
            align: 'end',
            font: {
              size: 14,
              weight: 'bold',
            },
            formatter: (value: any) => {
              return FormatHelper.format(value, this.configuration.formatType);
            },
          },
        },
      },
    });

    this.updateChart();
  }

  private updateChart(): void {
    if (this._barChart == null || this.configuration == null || this.data == null) {
      return;
    }

    const maxValue = Math.max(...this.data.flatMap(i => i.values).map(v => v ?? 0));
    this._barChart.options.scales.x.max = maxValue * 1.2;

    this._barChart.options.plugins.title.text = this.configuration.title;
    this._barChart.data.labels = this.data.map(i => i.label || this.unnamedItemLabel);

    this._barChart.data.datasets = this.data[0].values.map((v, datasetIndex) => ({
      data: this.data.map(i => i.values[datasetIndex]),
      label: this.configuration.dataLabels?.[datasetIndex],
      backgroundColor: this.configuration.colors[datasetIndex],
      minBarLength: 5,
      maxBarThickness: this.maxBarThickness,
      borderColor: ChartColor.Transparent,
      borderWidth: 0,
      borderRadius: 4,
    }));

    if (this.dynamicHeight) {
      let height = 1.25 * this.maxBarThickness * this.data.length + 20;
      if (this.configuration.title != null) {
        height += 40;
      }
      if (this.configuration.showLegend) {
        height += 36;
      }
      this._barChart.resize(this._barChart.width, height);
    }

    this._barChart.update();
  }
}
