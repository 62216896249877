import { AnnotationOptions } from 'chartjs-plugin-annotation';
import { DateHelper, DateTimeUnit } from '../../../helpers/date.helper';
import { DateRange } from '../../../types/date-range.type';
import { ChartColor } from '../chart-color.enum';

const highlightWeekendsAnnotationId = 'highlightWeekends';

export function getWeekendsAnnotations(range: DateRange): AnnotationOptions[] {
  let start = DateHelper.addDays(DateHelper.startOf(new Date(range.start), DateTimeUnit.Week), 5);
  const end = new Date(range.end);
  if (start > end) {
    return [];
  }

  const annotations: AnnotationOptions[] = [];
  for (; start < end; start = DateHelper.addTime(start, 1, DateTimeUnit.Week)) {
    annotations.push({
      id: highlightWeekendsAnnotationId,
      type: 'box',
      drawTime: 'beforeDraw',
      backgroundColor: ChartColor.Gray40,
      borderWidth: 0,
      xMin: start.getTime(),
      xMax: DateHelper.endOfDay(DateHelper.addDays(start, 1)).getTime(),
    });
  }

  return annotations;
}
