import { Component, ViewChild, ElementRef, Input, Output, EventEmitter, AfterViewInit, Injector, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, NgControl, FormControl, FormControlName, FormGroupDirective, FormControlDirective } from '@angular/forms';
import { TextHelper } from '../../helpers/text.helper';
import { NumberHelper } from '../../helpers/number.helper';

@Component({
  selector: 'app-input-number-simple',
  templateUrl: './input-number-simple.component.html',
  styleUrls: ['./input-number-simple.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: InputNumberSimpleComponent,
      multi: true,
    },
  ],
})
export class InputNumberSimpleComponent implements ControlValueAccessor, OnInit, AfterViewInit {
  @ViewChild('input', { static: true }) inputElm: ElementRef;
  @ViewChild('suffix', { static: true }) suffixElm: ElementRef;

  @Input() set value(value: number) {
    const safeValue = NumberHelper.safeValue(value);
    if (safeValue !== this._value) {
      this._value = safeValue;
      this.updateSuffix(this._value);
      this._onChange.forEach(fn => fn(safeValue));
    }
  }
  get value(): number {
    return this._value;
  }
  @Input() decimals: number;
  @Input() step: number; // TODO: implement step
  @Input() set unit(value: string) {
    this._unit = value;
    if (this._unit === undefined || this._unit === value) return;
    this.updateSuffix();
  }
  get unit(): string {
    return this._unit;
  }
  @Input() min: number;
  @Input() max: number;
  @Input() disabled: boolean;
  @Input() placeholder = '';

  @Output() valueChange = new EventEmitter<number>();

  private _value: number | null = undefined;
  private _unit: string;
  private _onChange = [(_: number) => {}];
  private _onTouch = [() => {}];
  formControl: FormControl;

  get stringifiedValue(): string {
    const val = ((this.inputElm.nativeElement as HTMLInputElement).value || '').replace('.', ',');
    if (val === '-' || val === ',') return val;
    const safeValue = NumberHelper.safeValue(this.value);
    return NumberHelper.stringify(safeValue) || '';
  }

  get isInvalid(): boolean {
    return this.formControl?.invalid && this.formControl?.touched;
  }

  constructor(private injector: Injector) {}

  async ngOnInit(): Promise<void> {
    try {
      const ngControl = this.injector.get(NgControl);
      if (ngControl instanceof FormControlName) {
        this.formControl = this.injector.get(FormGroupDirective).getControl(ngControl);
      } else {
        this.formControl = (ngControl as FormControlDirective).form;
      }
    } catch {
      // component is not used as form control
    }
  }

  ngAfterViewInit(): void {
    this.setDisabledState(this.disabled);
    this.updateSuffix();
  }

  onInput(target: EventTarget): void {
    this.updateValue(target);
  }

  onChange(target: EventTarget): void {
    this.updateValue(target);
    this.valueChange.emit(this.value);
  }

  private updateValue(target: EventTarget): void {
    const el = target as HTMLInputElement;
    if (parseFloat(el.value) < +this.min) el.value = NumberHelper.stringify(this.min);
    if (parseFloat(el.value) > +this.max) el.value = NumberHelper.stringify(this.max);
    this.value = NumberHelper.safeValue(el.value);
  }

  onTouch(): void {
    this._onTouch.forEach(fn => fn());
  }

  private updateSuffix(value?: number) {
    let width = 0;

    if (value == null) {
      width = TextHelper.getTextWidth(this.inputElm.nativeElement.value, TextHelper.getCanvasFont(this.inputElm.nativeElement));
    } else {
      width = TextHelper.getTextWidth(NumberHelper.stringify(value), TextHelper.getCanvasFont(this.inputElm.nativeElement));
    }
    this.suffixElm.nativeElement.style.left = width + 'px';
  }

  // ControlValueAccessor implementation
  registerOnChange(fn: any): void {
    this._onChange.push(fn);
  }

  registerOnTouched(fn: any): void {
    this._onTouch.push(fn);
  }

  writeValue(inputValue: number): void {
    this.value = NumberHelper.safeValue(inputValue);
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
