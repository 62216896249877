import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormatType } from '../../enums/format-type.enum';
import { Marking } from '../../enums/generated.enums';
import { FormatHelper } from '../../helpers/format.helper';
import { ChartColor } from '../_charts/chart-color.enum';
import { MetricType } from './metric-type.type';
import { TextConstants } from '../../text-constants';

@Component({
  selector: 'app-pointee-metric',
  templateUrl: './pointee-metric.component.html',
  styleUrls: ['./pointee-metric.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PointeeMetricComponent implements OnChanges {
  @Input() label: string;

  @Input() value: string | number | Date;
  @Input() unit: string;
  @Input() isValueInvalid = false;
  @Input() formatType: FormatType = FormatType.Number;

  @Input() metricType? = MetricType.Basic;
  @Input() pieData?: number[];
  @Input() marking?: Marking;
  @Input() pointerColor? = '#000000';
  @Input() params?: number[] | string[];
  @Input() paramsType: FormatType = FormatType.Number;
  @Input() hasException = false;
  // tooltip
  @Input() displayTooltip? = true;
  @Input() customTooltip?: string;
  @Input() customTooltipMode: 'append' | 'replace' = 'append';
  // info icon & tooltip
  @Input() displayInfoTooltip = true;
  @Input() labelInfoTooltip: string;
  @Input() labelInfoIcon: string;
  @Input() labelInfoIconColor?: string;
  // invalid value tooltip
  @Input() invalidValueTooltip?: string;
  // trend & trend tooltip
  @Input() trendValue?: number;
  @Input() trendType: FormatType;
  @Input() trendIsPositive: boolean;
  @Input() trendTooltip: string;
  // warning tooltip
  @Input() showWarning = false;
  @Input() warningTooltip: string;
  @Input() isWarningClickable = false;

  @Output() warningClick = new EventEmitter<void>();

  @ViewChild('percentageChart', { static: false }) canvas: ElementRef<HTMLCanvasElement>;

  readonly TextConstants = TextConstants;
  tooltip: string;
  type = FormatType;
  metricTypeEnum = MetricType;
  ellipsisTooltipOptions = {
    display: false,
  };

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.displayTooltip) {
      return;
    }

    const formattedValue = `<b>${FormatHelper.format(this.value, this.formatType, false, true, this.unit)}</b>`;
    if (this.customTooltip == null) {
      this.tooltip = formattedValue;
      return;
    }

    this.tooltip = this.customTooltipMode == 'replace' ? this.customTooltip : `${formattedValue} (${this.customTooltip})`;
  }

  onMouseOver(event: MouseEvent): void {
    const target = <HTMLSpanElement>event.target;
    this.ellipsisTooltipOptions = {
      display: target.clientWidth > 0 && target.scrollWidth > target.clientWidth,
    };
  }

  getDefaultPieData(): number[] {
    let value = this.value;
    if (typeof value === 'number') {
      while (value > 1) {
        value = value / 100;
      }
      return [value, 0, 0, 1 - value];
    }

    return [];
  }

  getPieChartColorsForException(): ChartColor[] {
    return [ChartColor.Green, ChartColor.Red, ChartColor.Red, ChartColor.Red];
  }
}
