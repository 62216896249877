import { Pipe, PipeTransform } from '@angular/core';
import { Data } from '../types/data.type';

@Pipe({ name: 'uiPath' })
export class UiPathPipe implements PipeTransform {
  constructor(private data: Data) {}

  transform(text: string, uiPathText: string): string {
    return this.data.isUiPath ? uiPathText : text;
  }
}
