<!-- SEARCH INPUT -->
<div class="pointee-search-input" [ngClass]="{'ghost': asGhostButton}">
  <input
    #searchInput
    type="text"
    name="search-input"
    aria-describedby="searched-text"
    [(ngModel)]="searchValue"
    placeholder="{{placeholder || 'Search...'}}"
    maxlength="100"
    class="search-input"
    autocomplete="off"
  />
  <span class="search-input-icon">
    <svg-icon key="search"></svg-icon>
  </span>
  <span class="clear-icon" (click)="clearSearch($event)" *ngIf="searchValue?.length > 0 || closeAlwaysVisible">
    <svg-icon key="close" fontSize="12px"></svg-icon>
  </span>
</div>
