import { Pipe, PipeTransform } from '@angular/core';
import { PlatformTextsService } from '../services/platform-texts.service';

@Pipe({ name: 'platformTexts' })
export class PlatformTextsPipe implements PipeTransform {
  constructor(private platformTexts: PlatformTextsService) {}

  transform(value: string, unified = false): string {
    return this.platformTexts.get(value, unified);
  }
}
