import { Chart } from 'chart.js';

export const legendMarginPlugin = {
  id: 'legendMargin',
  beforeInit(chart: Chart) {
    const untypedLegend = chart.legend as any as { height: number; fit: () => void };
    const originalFit = untypedLegend.fit;

    // Override the fit function
    untypedLegend.fit = function fit() {
      originalFit.bind(chart.legend)();
      this.height += 16;
    };
  },
};
