import { Component, Input } from '@angular/core';
import { ProcessingItemStatus } from '../../../layout/upload-processing/processing-item-state.enum';

@Component({
  selector: 'app-processing-progress-circle',
  templateUrl: './processing-progress-circle.component.html',
  styleUrls: ['./processing-progress-circle.component.scss'],
})
export class UploadProcessingItemComponent {
  @Input() public status: ProcessingItemStatus;
  @Input() public totalProgress: number;
  @Input() public isFatalError: boolean;

  readonly ProcessingItemStatus = ProcessingItemStatus;

  public radius = 16;
  public circumference = 2 * Math.PI * this.radius;

  public get isInProgress(): boolean {
    return this.status === ProcessingItemStatus.Uploading || this.status === ProcessingItemStatus.Processing || this.status === ProcessingItemStatus.Pending;
  }
}
