import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { Sort } from '@angular/material/sort';
import { FormatType } from '../../enums/format-type.enum';
import { ExceptionSortBy, ExceptionType, TaskExceptionSourceType } from '../../enums/generated.enums';
import { DateRangeFormat } from '../../helpers/date.helper';
import { TextHelper } from '../../helpers/text.helper';
import { ApiService } from '../../services/api/api.service';
import { PlatformTextsService } from '../../services/platform-texts.service';
import { DateRange } from '../../types/date-range.type';
import { ExceptionSort } from '../../types/exception-sort.type';
import { TaskException } from '../../types/task-exception';
import { TextConstants } from '../../text-constants';

@Component({
  selector: 'shared-task-exceptions-table',
  templateUrl: './task-exceptions-table.component.html',
  styleUrls: ['./task-exceptions-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskExceptionsTableComponent implements OnInit, OnChanges {
  @Input() timePeriod: DateRange;
  @Input() filter: { domainId?: string; processGroupId?: string } = {};
  @Input() initialExceptionCount = 3;
  @Input() viewPageSize = 10;
  @Input() bucketSize = 100;
  @Output() dataLoaded = new EventEmitter<number>();

  get timePeriodLabel(): string {
    return this.timePeriod?.format(DateRangeFormat.DateOnly);
  }

  get displayViewMoreButton(): boolean {
    return this.exceptions.length > this.initialExceptionCount && this.exceptions.length > this.exceptionsCountToShow && !this.isLoading;
  }

  readonly AllExceptions = 'all';
  readonly ExceptionType = ExceptionType;
  readonly FormatType = FormatType;

  tableColumns = ['Text', 'Type', 'Count', 'Cost'];
  exceptions: TaskException[] = [];
  exceptionsToDisplay: TaskException[] = [];
  exceptionsCountToShow = this.initialExceptionCount;
  selectedExceptionType: string = this.AllExceptions;
  exceptionsSort: ExceptionSort = {
    by: ExceptionSortBy.Cost,
    ascending: false,
  };

  isLoading = false;

  private pageIndex = 0;

  constructor(private platformTexts: PlatformTextsService, private apiService: ApiService, private cdr: ChangeDetectorRef) {}

  async ngOnInit(): Promise<void> {
    await this.reloadExceptions(this.initialExceptionCount, true);
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.timePeriod?.isFirstChange() === false || changes.filter?.isFirstChange() === false) {
      await this.reloadExceptions(this.initialExceptionCount, true);
    }
  }

  onViewMoreClicked(): void {
    this.exceptionsCountToShow += this.exceptionsCountToShow < this.viewPageSize ? this.viewPageSize - this.exceptionsCountToShow : this.viewPageSize;
    this.showMoreExceptions(this.exceptionsCountToShow);
  }

  async onExceptionTypeChanged($event: MatButtonToggleChange): Promise<void> {
    this.selectedExceptionType = $event.value as ExceptionType;
    await this.reloadExceptions(this.exceptionsToDisplay.length);
  }

  async onSortChanged(sort: Sort): Promise<void> {
    if (!sort) {
      return;
    }

    const isAsc = sort.direction === 'asc';
    this.exceptionsSort = {
      by: sort.active as ExceptionSortBy,
      ascending: isAsc,
    };

    await this.reloadExceptions(this.exceptionsToDisplay.length);
  }

  getExceptionSourcesTooltip(exception: TaskException): string {
    const queueSources = exception.sources.filter(s => s.type === TaskExceptionSourceType.Queue);
    const processSources = exception.sources.filter(s => s.type === TaskExceptionSourceType.Process);

    let result = `Exception <i>${exception.text}</i><br/>`;
    result += 'Occurred in ';
    if (queueSources.length > 0) {
      result += queueSources.length === 1 ? 'queue ' : 'queues ';
      result += queueSources.map(s => `<i>${s.label}</i> (${s.count}x)`).join(' and ');
    }

    if (processSources.length > 0) {
      result += queueSources.length > 0 ? ' and ' : '';
      result += this.platformTexts.get('Blue Prism') + (processSources.length === 1 ? ' process ' : ' processes ');
      result += processSources.map(s => `<i>${s.label}</i> (${s.count}x)`).join(' and ');
    }

    return result;
  }

  getExceptionCostTooltip(exception: TaskException): string {
    if (exception.cost == null) {
      return TextConstants.manualProcessingInfoMissing;
    }
    return `The cost of manual processing of the ${exception.count} tasks that ended in this exception.`;
  }

  private async loadExceptionsPage(): Promise<void> {
    this.isLoading = true;
    try {
      const exceptions = await this.apiService.managementMetrics.getTopExceptions(
        this.timePeriod.start,
        this.timePeriod.end,
        this.bucketSize,
        this.pageIndex,
        {
          ...this.filter,
          exceptionType: this.selectedExceptionType !== this.AllExceptions ? (this.selectedExceptionType as ExceptionType) : undefined,
        },
        this.exceptionsSort,
      );

      this.exceptions = this.exceptions.concat(
        exceptions.map(e => ({
          ...e,
          text: TextHelper.removeHtmlTags(e.text),
        })),
      );

      this.pageIndex++;
    } finally {
      this.isLoading = false;
      this.cdr.markForCheck();
    }
  }

  private async reloadExceptions(exceptionsCountToShow: number, emitDataLoaded = false): Promise<void> {
    this.pageIndex = 0;
    this.exceptions = [];
    await this.loadExceptionsPage();
    if (emitDataLoaded) {
      this.dataLoaded.emit(this.exceptions.length);
    }
    this.exceptionsCountToShow = Math.max(this.initialExceptionCount, exceptionsCountToShow);
    this.showMoreExceptions(this.exceptionsCountToShow);
  }

  private showMoreExceptions(count: number): void {
    const shouldLoadFromServer = this.exceptions.length > 0 && this.exceptions.length <= count;
    this.exceptionsToDisplay = [...this.exceptions].slice(0, count > this.exceptions.length ? this.exceptions.length : count);
    if (shouldLoadFromServer) {
      void this.loadExceptionsPage();
    }
    this.cdr.markForCheck();
  }
}
