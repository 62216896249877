import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { FormatType } from 'src/app/shared/enums/format-type.enum';
import { SubPageType } from 'src/app/shared/enums/page-type.enum';
import { MathHelper } from 'src/app/shared/helpers/math.helper';
import { TextHelper } from 'src/app/shared/helpers/text.helper';
import { ModalService } from 'src/app/shared/services/modal.service';
import { NavigationService } from 'src/app/shared/services/navigation.service';
import { PopupService } from 'src/app/shared/services/popup.service';
import { SubscriptionService } from 'src/app/shared/services/subscription.service';
import { UploadProcessingService } from 'src/app/shared/services/upload-processing.service';
import { UploadProcessingResultModalComponent } from '../upload-processing-result/upload-processing-result-modal.component';
import { ProcessingItemStatus } from './processing-item-state.enum';
import { ProcessingItem } from './processing-item.type';

@Component({
  selector: 'app-upload-processing',
  templateUrl: './upload-processing.component.html',
  styleUrls: ['./upload-processing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SubscriptionService],
})
export class UploadProcessingComponent implements OnInit {
  readonly FormatType = FormatType;
  isCollapsed: boolean;
  isOpen: boolean;
  error: string;
  statuses = ProcessingItemStatus;
  closeTooltip: string;
  collapseTooltip: string;
  dialogTitle = 'Adding 1 new process';
  items: ProcessingItem[] = [];

  get isProcessMap(): boolean {
    return this.navigationService.isOnSubPage(SubPageType.ProcessMap);
  }

  constructor(
    private modalService: ModalService,
    private popupService: PopupService,
    private navigationService: NavigationService,
    private subscriptionService: SubscriptionService,
    private uploadProcessingService: UploadProcessingService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.subscriptionService.subscribeUntilDestroyed(this.uploadProcessingService.items$, items => {
      this.items = items;
      this.isOpen = items.length > 0;
      this.refresh();
    });
  }

  private refresh(): void {
    this.closeTooltip = this.items.some(i => i.canCancel) ? 'Cancel and close' : 'Close';
    this.collapseTooltip = this.isCollapsed ? 'Open' : 'Minimize';
    const successfulCount = MathHelper.sum(this.items, i => (i.isSuccessful ? i.masterProcessIds.length : 0));
    const processingCount = this.items.filter(i => i.isUploading || i.isPending || i.isProcessing).length;
    const uploadedCount = this.items.filter(i => i.isUploaded).length;

    if (processingCount >= 1) {
      this.dialogTitle = `Processing ${TextHelper.getTextWithCount(processingCount, 'log file')}`;
    } else if (successfulCount >= 1) {
      this.dialogTitle = `${TextHelper.getTextWithCount(successfulCount, 'analysis', 'analyses')} added`;
    } else if (uploadedCount >= 1) {
      this.dialogTitle = `${TextHelper.getTextWithCount(uploadedCount, 'process log')} uploaded`;
    } else if (this.items.length === 1) {
      this.dialogTitle = 'Analysis not added';
    } else {
      this.dialogTitle = 'No analysis added';
    }

    this.cdr.markForCheck();
  }

  async close(): Promise<void> {
    const processingItemsCount = this.items.filter(i => i.canCancel).length;
    if (processingItemsCount > 0 && !(await this.showCancelQuestion(processingItemsCount > 1))) {
      return;
    }

    await this.uploadProcessingService.clear();
  }

  toggleCollapse(): void {
    this.isCollapsed = !this.isCollapsed;
    this.refresh();
  }

  @HostListener('window:beforeunload', ['$event'])
  onUnload(event: BeforeUnloadEvent): void {
    if (this.items.some(i => i.canCancel)) {
      event.returnValue = true;
    }
  }

  async displayResult(processingItem: ProcessingItem): Promise<void> {
    if (processingItem.isSuccessful || processingItem.isUploaded || processingItem.isError) {
      this.modalService.open(UploadProcessingResultModalComponent, d => {
        d.processingItem = processingItem;
      });
    }
  }

  async cancel(processingItem: ProcessingItem): Promise<void> {
    const result = await this.showCancelQuestion(false);
    if (result) {
      await this.uploadProcessingService.cancelItem(processingItem, true);
    }
  }

  trackById(index: number, item: ProcessingItem): string {
    return item.id;
  }

  private async showCancelQuestion(plural: boolean): Promise<boolean> {
    return await this.popupService.showOkCancelQuestion(
      plural ? 'Cancel creating analyses?' : 'Cancel creating analysis?',
      plural ? 'The analyses are not ready yet. Are you sure you want to cancel?' : 'The analysis is not ready yet. Are you sure you want to cancel?',
      'Cancel upload',
      'Continue upload',
    );
  }
}
