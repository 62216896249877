import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'shared-pill-state-button',
  templateUrl: './pill-state-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PillStateButtonComponent {
  @Input() state: boolean;
  @Input() text: string;
  @Input() negativeText: string;
  @Input() icon: string;
  @Input() negativeIcon: string;
  @Input() disabled = false;
  @Output() stateChange = new EventEmitter<boolean>();

  toggleState(): void {
    if (this.disabled){
      return;
    }
    this.state = !this.state;
    this.stateChange.emit(this.state);
  }
}
