<div id="input-container">
  <input
    type="text"
    #input
    appNumberOnly
    decimals="{{decimals}}"
    class="form-control"
    [placeholder]="placeholder"
    [value]="stringifiedValue"
    (input)="onInput($event.target)"
    (change)="onChange($event.target)"
    (blur)="input.value = stringifiedValue;
    onTouch()"
    [ngClass]="{'ng-dirty ng-invalid': isInvalid}"
    [attr.aria-placeholder]="placeholder"
    aria-labelledby="valueInput"
    [attr.aria-valuenow]="stringifiedValue"
    [attr.aria-valuemin]="min"
    [attr.aria-valuemax]="max"
    autocomplete="off"
    [disabled]="disabled"
  />
  <span id="input-suffix" #suffix [ngClass]="{'hidden': value === null || value === undefined }">{{unit}}</span>
</div>
