import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { FormatType } from '../../enums/format-type.enum';
import { FormatHelper } from '../../helpers/format.helper';

@Component({
  selector: 'shared-visual-trend',
  templateUrl: './trend.component.html',
  styleUrls: ['./trend.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrendComponent implements OnChanges {
  @Input() trend: number;
  @Input() trendType: FormatType;
  @Input() isPositive: boolean;
  @Input() displayTooltip = false;
  @Input() displayIcon = true;
  class: string;
  iconPath: string;
  showTrend: boolean;

  ngOnChanges(): void {
    this.refresh();
  }

  private refresh(): void {
    this.showTrend = !this.isNeutral();
    this.class = !this.showTrend ? 'trend-neutral' : this.isPositive === !(this.trend < 0) ? 'trend-positive' : 'trend-negative';
    if (this.displayIcon) {
      this.iconPath = this.trend < 0
        ? 'M21.666 21.695l-7.463-.033m7.497-7.416l-.034 7.449m-11.32-11.347l11.321 11.348'
        : this.trend > 0
          ? 'M21.687 10.366l-.033 7.463m-7.417-7.496l7.45.033M10.34 21.687l11.348-11.322'
          : 'M24.022 15.97l-5.261 5.294m-.023-10.545l5.284 5.251m-16.028.103l16.03-.103';
    }
  }

  private isNeutral(): boolean {
    switch (this.trendType) {
      case FormatType.Number:
      case FormatType.Currency:
        return FormatHelper.format(this.trend, FormatType.Number) === '0';
      case FormatType.Percentage:
        return FormatHelper.format(this.trend, FormatType.Percentage) === '0%';
      case FormatType.Time:
        return FormatHelper.format(this.trend, this.trendType) === '0s';
      default:
        return true;
    }
  }
}
