import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormControlDirective,
  FormControlName,
  FormGroupDirective,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  NgControl,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { debounceTime } from 'rxjs';
import { DateHelper } from '../../helpers/date.helper';
import { OrganizationService } from '../../services/organization.service';

export interface TimePickerType {
  hour: string;
  minute: string;
  daytimePeriod: 'AM' | 'PM' | undefined;
}

@Component({
  selector: 'shared-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, multi: true, useExisting: TimePickerComponent },
    { provide: NG_VALIDATORS, useExisting: TimePickerComponent, multi: true },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimePickerComponent implements ControlValueAccessor, Validator, OnInit {
  private _isTime24H = DateHelper.is24HFormat;
  @Input() set isTime24h(value: boolean) {
    this._isTime24H = value;
    this.updateTimeFormat();
  }
  get isTime24h(): boolean {
    return this._isTime24H;
  }

  time: number;
  timeFormat: 'AM' | 'PM' | undefined;
  disabled: boolean;
  timePickerForm = this.formBuilder.nonNullable.group({
    hour: ['00', [Validators.min(0), this.isTime24h ? Validators.max(23) : Validators.max(12), Validators.required]],
    minute: ['00', [Validators.min(0), Validators.max(59), Validators.required]],
  });

  get f() {
    return this.timePickerForm.controls;
  }

  get isInvalid(): boolean {
    return (this.timePickerForm.invalid && this.timePickerForm.touched) || (this.formControl.invalid && this.formControl.touched);
  }

  readonly dayDuration = 86400000;
  readonly maxDayTime = 86334000;
  readonly timeInterval = 600000;

  private formControl: FormControl;
  private onChange = (time: number) => {};
  private onTouched = () => {};
  onValidationChange: any = () => {};

  constructor(private formBuilder: FormBuilder, private cdr: ChangeDetectorRef, private injector: Injector, private organizationService: OrganizationService) {}

  ngOnInit(): void {
    this.timePickerForm.valueChanges.pipe(debounceTime(200)).subscribe(value => this.decomposeTimeFromForm(value as TimePickerType));
    const ngControl = this.injector.get(NgControl);
    if (ngControl instanceof FormControlName) {
      this.formControl = this.injector.get(FormGroupDirective).getControl(ngControl);
    } else {
      this.formControl = (ngControl as FormControlDirective).form;
    }
  }

  private updateTimeFormat() {
    this.timePickerForm.get('hour')?.setValidators([Validators.min(0), this.isTime24h ? Validators.max(23) : Validators.max(12), Validators.required]);
    this.writeValue(this.time);
  }

  subTime(): void {
    this.addTimeInterval(-this.timeInterval);
  }

  addTime(): void {
    this.addTimeInterval(this.timeInterval);
  }

  private addTimeInterval(n: number): void {
    // always round to n minute intervals and then add / sub n minutes
    let time = Math.round(this.time / n) * n + n;
    this.updateTime(time);
  }

  private updateTime(time: number): void {
    if (time < 0) {
      time = 0;
    } else if (time > this.maxDayTime) {
      time = this.maxDayTime;
    }
    this.time = time;
    this.writeValue(time);
    this.notifyChanged();
  }

  setTimeFormat(format: 'AM' | 'PM'): void {
    if (format === this.timeFormat || this.timePickerForm.invalid) {
      return;
    }
    const time = DateHelper.parse12HTime(`${this.padDigit(this.f.hour.value)}:${this.padDigit(this.f.minute.value)}:${format}`);
    this.updateTime(time);
  }

  normalizeHours() {
    const hour = +this.f.hour.value;
    if (this.isTime24h) {
      this.timePickerForm.setValue({ hour: this.padDigit(hour), minute: this.f.minute.value });
    } else if (hour === 0 || hour > 12) {
      const hour12 = hour === 0 ? 12 : hour % 12;
      this.timePickerForm.setValue({ hour: this.padDigit(hour12), minute: this.f.minute.value });
    }
  }

  padInputValue(input: FormControl<string>): void {
    input.setValue(this.padDigit(input.value), { emitEvent: false });
  }

  private decomposeTimeFromForm(value: TimePickerType): void {
    if (this.timePickerForm.invalid) {
      this.time = -1;
      this.notifyChanged();
      return;
    }

    const hours = this.padDigit(value.hour);
    const minutes = this.padDigit(value.minute);
    this.time = this.isTime24h ? DateHelper.parseDuration(`${hours}:${minutes}`) : DateHelper.parse12HTime(`${hours}:${minutes}:${this.timeFormat}`);

    this.notifyChanged();
    this.cdr.markForCheck();
  }

  private notifyChanged(): void {
    if (this.onChange) {
      this.onChange(this.time);
    }
    if (this.onTouched) {
      this.onTouched();
    }
  }

  private padDigit(value: string | number): string {
    return value.toString().padStart(2, '0');
  }

  // ControlValueAccessor implementation
  writeValue(time: number): void {
    if (time == null) {
      return;
    }
    this.time = time;
    const splitTime = this.isTime24h ? DateHelper.format24HTime(time).split(':') : DateHelper.format12HTime(time).split(':');
    const hour = splitTime[0] ?? '00';
    const minute = splitTime[1] ?? '00';
    this.timeFormat = splitTime.length > 2 ? (splitTime[2] as 'AM' | 'PM') : undefined;
    this.timePickerForm.setValue({ hour: this.padDigit(hour), minute: this.padDigit(minute) }, { emitEvent: false });
    this.cdr.markForCheck();
  }

  registerOnChange(onChange: (time: number) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cdr.markForCheck();
  }

  // Validator implementation
  registerOnValidatorChange?(fn: () => void): void {
    this.onValidationChange = fn;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const value: number = <number | null>control.value;
    if (value == null || (value <= this.dayDuration && value >= 0)) {
      return null;
    }
    return { invalidValue: { value } };
  }
}
