import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BaseModalDialogDirective } from '../../../directives/base-modal-dialog.directive';
import { ApiService } from '../../../services/api/api.service';
import { LoggingService } from '../../../services/logging.service';
import { PopupService } from '../../../services/popup.service';
import { SelectionFacadeService } from '../../../services/selection-facade.service';
import { StorageService } from '../../../services/storage.service';
import { UserActionableInsight } from '../../../types/actionable-insight.type';
import { Edge } from '../../../types/edge.type';
import { Node } from '../../../types/node.type';
import { ModalCloseType } from '../../modal/modal-close-type.enum';

interface IUserInsightFormGroup extends FormGroup {
  value: UserActionableInsight;
  controls: {
    title: AbstractControl;
    note: AbstractControl;
  };
}

@Component({
  selector: 'shared-user-actionable-insight-modal',
  templateUrl: './user-actionable-insight-modal.component.html',
  styleUrls: ['./user-actionable-insight-modal.component.scss'],
})
export class UserActionableInsightModalComponent extends BaseModalDialogDirective implements OnInit {
  @Input() userActionableInsight: UserActionableInsight;
  @Input() item: Node | Edge;

  form: IUserInsightFormGroup;

  get title(): string {
    return this.isUpdate ? 'Edit an insight' : 'Add an insight';
  }

  get isUpdate(): boolean {
    return this.userActionableInsight?.id != null;
  }

  get closeType(): ModalCloseType {
    return ModalCloseType.Safe;
  }

  private _submitted = false;

  constructor(
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    private popupService: PopupService,
    private selectionService: SelectionFacadeService,
    private storageService: StorageService,
    private loggingService: LoggingService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      title: [this.userActionableInsight.title || '', [Validators.required]],
      note: [this.userActionableInsight.note || ''],
    }) as any as IUserInsightFormGroup;
  }

  async save() {
    if (this.form.invalid || this._submitted) {
      return;
    }

    this._submitted = true;

    this.userActionableInsight.title = this.form.value.title;
    this.userActionableInsight.note = this.form.value.note || null;

    try {
      const userActionableInsight = new UserActionableInsight();
      Object.assign(userActionableInsight, this.userActionableInsight);
      if (this.isUpdate) {
        await this.apiService.updateUserActionableInsight(this.selectionService.selectedMasterProcess.masterProcessId, userActionableInsight);
        this.popupService.showSnackBar('Insight edited');
      } else {
        await this.apiService.addUserActionableInsight(this.selectionService.selectedMasterProcess.masterProcessId, userActionableInsight);
        this.item.userActionableInsight = this.userActionableInsight;
        this.storageService.emitProcessMapMetricsChanged('Insight has been added.');
        this.popupService.showSnackBar('Insight added');
      }
      this.close();
    } catch (error: any) {
      this.loggingService.logException(error);
      this.popupService.showSnackBar(`Insight can't be ${this.isUpdate ? 'edited' : 'added'}`);
    }
  }
}
