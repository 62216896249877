import { Injectable } from '@angular/core';
import { BaseApiService } from './base-api.service';

@Injectable({ providedIn: 'root' })
export class ImportApiService extends BaseApiService {
  protected get baseUrl(): string {
    return `${super.baseUrl}/import`;
  }

  downloadInstaller(): void {
    this.download('installer');
  }
}
