import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BaseModalDialogDirective } from 'src/app/shared/directives/base-modal-dialog.directive';
import { ModalSize } from 'src/app/shared/enums/modal-size.enum';
import { PopupService } from 'src/app/shared/services/popup.service';
import { ModalCloseType } from '../../modal/modal-close-type.enum';

@Component({
  selector: 'app-user-activation-link',
  templateUrl: './user-activation-link.component.html',
  styleUrls: ['./user-activation-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class UserActivationLinkComponent extends BaseModalDialogDirective {
  @Input() link: string;
  @Input() userName: string;
  get size(): ModalSize {
    return ModalSize.Small;
  }

  get closeType(): ModalCloseType {
    return ModalCloseType.Safe;
  }

  constructor(private popupService: PopupService) {
    super();
  }

  copyLink(): void {
    this.popupService.showSnackBar('Invitation link copied');
    navigator.clipboard.writeText(this.link);
    // this.close();
  }
}
