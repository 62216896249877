import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'shared-chips-input',
  templateUrl: './chips-input.component.html',
  styleUrls: ['./chips-input.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, multi: true, useExisting: ChipsInputComponent }],
})
export class ChipsInputComponent implements ControlValueAccessor {
  @Input() placeholder = '';
  @ViewChild('chipInput', { static: true }) chipInput: ElementRef;

  values: string[] = [];
  disabled: boolean;
  private onChange = (values: string[]) => {};
  private onTouched = () => {};
  private touched = false;

  onKeyPress(event: KeyboardEvent) {
    // check if chipInput is focused

    if (document.activeElement === this.chipInput.nativeElement && (event.key === 'Enter' || event.key === ',' || event.key === ' ')) {
      event.preventDefault();
      event.stopPropagation();
    }

    let value = (event.target as HTMLInputElement).value;
    // prevent Delete and Backspace keys only when there is no value in the input

    if (event.key === 'Delete' || event.key === 'Backspace') {
      if (value == null || value.length === 0) {
        event.preventDefault();
        event.stopPropagation();
        this.removeLastValue();
      }
      return;
    }

    if (value == null || value === '') {
      return;
    }

    if (event.key === 'Enter' || event.key === ',' || event.key === ' ') {
      this.processInput();
    }
  }

  onFocusOutEvent(event: FocusEvent) {
    this.processInput();
  }

  private processInput() {
    let value = this.chipInput.nativeElement.value;
    value = value.replace(/,/g, '').trim();
    if (!value) {
      return;
    }
    this.markAsTouched();
    this.values.push(value);
    this.onChange(this.values);
    this.chipInput.nativeElement.value = null;
  }

  private removeLastValue() {
    if (this.values.length > 0) {
      this.values.splice(-1);
      this.markAsTouched();
      this.onChange(this.values);
    }
  }

  removeValue(event: MouseEvent, index: number) {
    event.preventDefault();
    this.markAsTouched();
    this.values.splice(index, 1);
    this.onChange(this.values);
  }

  writeValue(values: string[]): void {
    this.values = values;
  }

  registerOnChange(onChange: (values: string[]) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  setDisabledState?(disabled: boolean): void {
    this.disabled = disabled;
  }

  markAsTouched(): void {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }
}
