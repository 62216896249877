import { Injectable } from '@angular/core';
import { PublicClientApplication, InteractionRequiredAuthError, BrowserAuthError, AuthenticationResult } from '@azure/msal-browser';
import { AppConfigurationService } from './app-configuration.service';
import { LoggingService } from './logging.service';
import { PopupService } from './popup.service';
import { StorageService } from './storage.service';

@Injectable({ providedIn: 'root' })
export class MsalService {
  private client: PublicClientApplication;

  get hasMicrosoftAccountId(): boolean {
    return this.storageService.microsoftAccountId != null;
  }

  constructor(private storageService: StorageService, private loggingService: LoggingService, private appConfigurationService: AppConfigurationService, private popupService: PopupService) {}

  async login(): Promise<string> {
    this.loggingService.logInformation('MsalService.login');
    return await this.loginInternal(false);
  }

  async logout(): Promise<void> {
    this.loggingService.logInformation('MsalService.logout');
    try {
      await (await this.getClient()).logoutRedirect({ onRedirectNavigate: () => false });
    } finally {
      this.storageService.microsoftAccountId = null;
      this.storageService.loginType = null;
    }
  }

  async acquireToken(): Promise<string> {
    this.loggingService.logInformation('MsalService.refreshToken');
    const client = await this.getClient();
    try {
      const account = client.getAccount({ homeAccountId: this.storageService.microsoftAccountId });
      if (!account) {
        return await this.loginInternal(true);
      }

      // use forceRefresh when necessary, see: https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/4206
      const exp = account?.idTokenClaims?.exp * 1000;
      const forceRefresh = exp < Date.now() - 5 * 60 * 1000;

      const result = await client.acquireTokenSilent({ scopes: ['User.Read'], account, forceRefresh });
      return result.idToken;
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        this.loggingService.logError('Failed to acquire Microsoft token silently', error);
        return await this.loginInternal(true);
      }

      // error is caused by blocked popup
      if (error instanceof BrowserAuthError && error.errorCode === 'popup_window_error') {
        await this.popupService.showInfo('Please allow popups for this site and try again.', 'Popup blocked');
      }

      throw error;
    }
  }

  private async loginInternal(throwIfCancelled: boolean): Promise<string> {
    const client = await this.getClient();
    try {
      const result = await client.acquireTokenPopup({ scopes: ['User.Read'] });
      this.storageService.microsoftAccountId = result.account.homeAccountId;
      return result.idToken;
    } catch (error) {
      this.loggingService.logError('Failed to acquire Microsoft token via popup', error);
      if (this.isUserCancelledError(error) && !throwIfCancelled) {
        return null;
      }
      throw error;
    }
  }

  private isUserCancelledError(error: any): boolean {
    return error instanceof BrowserAuthError && error.errorCode === 'user_cancelled';
  }

  private async getClient(): Promise<PublicClientApplication> {
    this.client ??= await this.createClient();
    return this.client;
  }

  private async createClient(): Promise<PublicClientApplication> {
    const client = new PublicClientApplication({
      auth: {
        clientId: this.appConfigurationService.azureApplicationId,
        authority: `https://login.microsoftonline.com/${this.appConfigurationService.azureAuthorityTenant}/`,
        redirectUri: `${location.origin}/assets/blank.html`,
      },
    });
    await client.initialize();
    return client;
  }
}
