import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, Subscription, filter } from 'rxjs';
import { Platform } from '../enums/generated.enums';
import { UploadItemType } from '../enums/upload-item-type.enum';
import { IUploadItem } from '../types/upload-item.type';
import { ApiService } from './api/api.service';
import { Uid } from '../helpers/uid.type';

@Injectable({ providedIn: 'root' })
export class UploadService {
  private uploads = new Map<string, { item: IUploadItem; subscription: Subscription }>();
  private uploadStartedSubject = new Subject<IUploadItem>();
  private uploadProgressSubject = new Subject<{ item: IUploadItem; progress: number }>();
  private uploadFinishedSubject = new Subject<IUploadItem>();
  private uploadErrorSubject = new Subject<{ item: IUploadItem; error: string }>();

  constructor(private apiService: ApiService) {}

  onUploadStarted(allowedType: UploadItemType): Observable<IUploadItem> {
    return this.uploadStartedSubject.asObservable().pipe(filter(x => x.type === allowedType));
  }

  onUploadProgress(): Observable<{ item: IUploadItem; progress: number }> {
    return this.uploadProgressSubject.asObservable();
  }

  onUploadFinished(): Observable<IUploadItem> {
    return this.uploadFinishedSubject.asObservable();
  }

  onUploadError(): Observable<{ item: IUploadItem; error: string }> {
    return this.uploadErrorSubject.asObservable();
  }

  uploadProcess(file: File, platform: Platform, monitoringProcessGroupId?: string): void {
    const item = this.initUpload(UploadItemType.Process, file.name, { platform });
    const subscription = this.apiService.addMasterProcess(file, platform, monitoringProcessGroupId).subscribe({
      next: ev => {
        if (ev.type === HttpEventType.Response) {
          item.data = { ...item.data, masterProcessId: ev.body.masterProcessId };
        }
        this.onUploadEvent(ev, item);
      },
      error: er => this.onError(er, item),
    });

    this.uploads.set(item.id, { item, subscription });
  }

  uploadCustomerFile(file: File): void {
    const item = this.initUpload(UploadItemType.CustomerFile, file.name);
    const subscription = this.apiService.uploadCustomerFile(file).subscribe({
      next: ev => {
        if (ev.type === HttpEventType.Response) {
          item.data = { ...item.data, fileId: ev.body.fileId };
        }
        this.onUploadEvent(ev, item);
      },
      error: er => this.onError(er, item),
    });

    this.uploads.set(item.id, { item, subscription });
  }

  cancelUpload(uploadId: string): void {
    this.deleteUpload(uploadId);
  }

  private initUpload(itemType: UploadItemType, fileName: string, data?: any): IUploadItem {
    const item: IUploadItem = {
      id: Uid.generate(),
      type: itemType,
      fileName,
      data,
    };

    this.uploadStartedSubject.next(item);
    return item;
  }

  private onUploadEvent(event: HttpEvent<any>, item: IUploadItem): void {
    switch (event.type) {
      case HttpEventType.ResponseHeader:
        break;
      case HttpEventType.UploadProgress:
        const progress = event.loaded / event.total;
        this.uploadProgressSubject.next({ item, progress });
        break;
      case HttpEventType.Response:
        this.uploadFinishedSubject.next(item);
        this.deleteUpload(item.id);
        break;
      default:
      // do nothing
    }
  }

  private onError(error: Error, item: IUploadItem): void {
    // FIXME on error 401, 403 and other http errors doesn't navigateToLogin
    this.uploadErrorSubject.next({ item, error: error.message });
    this.deleteUpload(item.id);
  }

  private deleteUpload(uploadId: string): void {
    const uploadItem = this.uploads.get(uploadId);
    if (uploadItem) {
      uploadItem.subscription?.unsubscribe();
      this.uploads.delete(uploadId);
    }
  }
}
