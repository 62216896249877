<ng-container [formGroup]="form">
  <div formArrayName="parameters" class="parameters-gap">
    <div *ngFor="let parameter of f.parameters.controls; let i = index">
      <div class="flex-row flex-gap-8" [formGroupName]="i">
        <div *ngIf="f.parameters.controls[i]?.controls.name as paramName">
          <input type="text" name="name" formControlName="name" class="form-control" placeholder="Name" autocomplete="off" (blur)="onBlur(paramName)" />
          <mat-error *ngIf="(paramName.touched || paramName.dirty) && paramName.invalid">
            <span>Please enter a name</span>
          </mat-error>
        </div>

        <span class="select-content-width">
          <mat-select class="pointee-mat-select" placeholder="Select a type" name="type" formControlName="type">
            <mat-option *ngFor="let type of parameterTypes" [value]="type">{{type}}</mat-option>
          </mat-select>
        </span>

        <div *ngIf="showValueInput">
          <input type="text" name="value" formControlName="value" class="form-control" placeholder="Value" autocomplete="off" />
        </div>

        <span class="p-l-8">
          <button type="button" class="btn btn-icon-only" aria-label="Remove parameter" (click)="removeParameter(i)" [disabled]="isDisabled">
            <svg-icon key="delete-24" size="md"></svg-icon>
          </button>
        </span>
      </div>
    </div>
  </div>
  <button type="button" class="btn btn-link m-t-8" (click)="addParameter()" [disabled]="isDisabled"><svg-icon key="plus"></svg-icon>Add a startup parameter</button>
</ng-container>
