import { Component, Input, OnDestroy, OnInit, Optional, Self } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormBuilder, FormGroup, NgControl, ValidationErrors, Validator, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { SessionParameterType } from '../../enums/generated.enums';
import { SchedulerActionParameter } from '../../types/scheduler/scheduler-task.type';
import { FormHelper } from '../../helpers/form.helper';

@Component({
  selector: 'shared-parameters-editor',
  templateUrl: './parameters-editor.component.html',
  styleUrls: ['./parameters-editor.component.scss'],
})
export class ParametersEditorComponent implements ControlValueAccessor, Validator, OnInit, OnDestroy {
  @Input() showValueInput = true;

  readonly parameterTypes = Object.values(SessionParameterType);

  form = this.formBuilder.group({
    parameters: this.formBuilder.array([] as FormGroup[]),
  });

  private onChange = (value: SchedulerActionParameter[]) => {};
  private onTouched = () => {};
  private touched = false;
  private subscription: Subscription;
  isDisabled: boolean;

  get f() {
    return this.form.controls;
  }

  constructor(private formBuilder: FormBuilder, @Self() @Optional() public ngControl: NgControl) {
    this.subscription = this.f.parameters.valueChanges.subscribe(() => this.notifyChanged());
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    const control = this.ngControl?.control;
    if (control == null) {
      return;
    }

    control.setValidators(() => this.validate(this.ngControl.control));
    control.updateValueAndValidity();

    const originalMarkAsTouched = control.markAsTouched;
    control.markAsTouched = (...args) => {
      originalMarkAsTouched.call(control, ...args);
      this.f.parameters.markAllAsTouched();
    };
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  addParameter() {
    this.f.parameters.push(this.createParameter());
    this.notifyChanged();
  }

  removeParameter(i: number) {
    this.f.parameters.removeAt(i);
    this.notifyChanged();
  }

  onBlur(control: AbstractControl) {
    control.setValue(control.value?.trim());
  }

  writeValue(value: SchedulerActionParameter[]): void {
    this.f.parameters.clear();
    value.forEach(p => this.f.parameters.push(this.createParameter(p)));
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    FormHelper.setEnabled(this.form, !isDisabled);
  }

  private createParameter(parameter?: { name: string; type: SessionParameterType; value: string }): FormGroup {
    return this.formBuilder.group({
      name: this.formBuilder.control(parameter?.name ?? null, Validators.required),
      type: this.formBuilder.control(parameter?.type ?? SessionParameterType.Text),
      value: this.formBuilder.control(parameter?.value ?? ''),
    });
  }

  private notifyChanged() {
    this.markAsTouched();
    this.onChange(this.f.parameters.value);
  }

  private markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const parameters = control.value as SchedulerActionParameter[];
    if (parameters?.some(p => !p.name)) {
      return { emptyName: true };
    }
    return null;
  }
}
