import { Chart, TooltipItem } from 'chart.js';
import { TextHelper } from '../../../helpers/text.helper';

export const tooltipTitleMultilinePlugin = {
  id: 'tooltipTitleMultiline',
  start(chart: Chart): void {
    const originalTitleCallback = chart.options.plugins.tooltip.callbacks.title;
    chart.options.plugins.tooltip.callbacks = {
      ...chart.options.plugins.tooltip.callbacks,
      title(tooltipItems: TooltipItem<'bar'>[]): string | string[] {
        const title = originalTitleCallback.call(this, tooltipItems);
        if (Array.isArray(title)) {
          return title;
        }

        if (typeof title === 'string') {
          return TextHelper.splitText(title ?? tooltipItems[0].label, 64);
        }

        return undefined;
      },
    };
  },
};
