import { AlertSeverity } from '../enums/alert-severity.enum';

export enum NotificationBarMessageType {
  LicenseCountInvalid,
  SchedulerDisabled,
  SchedulerRpaDbNotConnected,
  SchedulerRpaServerNotConnected,
  SchedulerIsSlow,
}

export interface NotificationBarMessage {
  type: NotificationBarMessageType;
  icon: string;
  severity: AlertSeverity;
  htmlText: string;
  tooltip?: string;
  contactSupportEnabled?: boolean;
  link?: {
    text: string;
    route: string[];
  };
}
