<div class="chart-js" #scrollable>
  <canvas #chartCanvas></canvas>
</div>
<div class="zoom-container" *ngIf="chart && zoomEnabled">
  <app-zoom
    [zoom]="currentZoom"
    [defaultZoom]="defaultZoom"
    [min]="1"
    [max]="maxZoom"
    [step]="zoomStep"
    [showShortcutInTooltips]="true"
    [zoomKeyModifier]="'CTRL'"
    [defaultButtons]="[ZoomDefaultButton.FitToScreen, ZoomDefaultButton.ZoomOut, ZoomDefaultButton.ResetZoom, ZoomDefaultButton.ZoomIn]"
    [extraButtons]="panButtons"
    (zoomChange)="onZoomChanged($event)"
    (fitToScreenClick)="onFitToScreenClick()"
  >
  </app-zoom>
</div>
