<!-- SEARCH INPUT -->
<div class="pointee-search-bar">
  <input
    #searchInput
    type="text"
    name="search-bar-input"
    aria-describedby="searched text filter"
    [(ngModel)]="searchValue"
    placeholder="{{placeholder || 'Search...'}}"
    maxlength="100"
    class="search-input"
    autocomplete="off"
  />
  <span class="search-icon">
    <svg-icon key="search" size="sm"></svg-icon>
  </span>
  <span class="clear-icon" (click)="clearSearch($event)">
    <svg-icon key="close" size="sm"></svg-icon>
  </span>
</div>
