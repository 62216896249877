import { Component, ViewEncapsulation, Input, HostBinding } from '@angular/core';
import { ContextDialog } from '../../types/context-dialog.type';

@Component({
  selector: 'shared-context-dialog-box',
  templateUrl: 'context-dialog.component.html',
  styleUrls: ['context-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ContextDialogComponent {
  dialog: ContextDialog;
  @Input() dialogBoxClass = '';
  @Input() dialogClass: string;
  @Input() closeBtn = true;
  @Input() caretPosition: number;
  @Input() name: string;

  @HostBinding('style.display')
  get display(): string {
    return this.visible ? 'flex' : 'none';
  }

  @HostBinding('style.opacity')
  get opacity(): number {
    return 1;
  }

  visible = false;
  visibleAnimate = false;

  open(): void {
    this.visible = true;
    this.dialog.onOpen();
    setTimeout(() => (this.visibleAnimate = true), 100);
  }

  close(): void {
    if (this.visible) {
      this.dialog.onClose();
      this.visible = false;
      setTimeout(() => (this.visibleAnimate = false), 100);
    }
  }
}
