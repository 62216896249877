import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { PageType, SubPageType } from 'src/app/shared/enums/page-type.enum';
import { ModalService } from 'src/app/shared/services/modal.service';
import { NavigationService } from 'src/app/shared/services/navigation.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { ContactUsModalComponent } from '../components/_modals/contact-us-modal/contact-us-modal.component';
import { FeatureFlags, Permission } from '../enums/generated.enums';
import { MathHelper } from '../helpers/math.helper';
import { DataAvailabilityInfo } from '../types/data-availability-info.type';
import { Data } from '../types/data.type';
import { BadgeType, MenuSection, MenuSettings } from '../types/menu-section.type';
import { AuthService } from './auth.service';
import { NotificationsService } from './notifications.service';
import { PlatformTextsService } from './platform-texts.service';

@Injectable({ providedIn: 'root' })
export class MenuService {
  private menuSettingsSubject = new BehaviorSubject<MenuSettings>({ sections: [] });

  get menuSections(): MenuSection[] {
    return this.menuSettingsSubject.getValue().sections;
  }

  get menuSettings$(): Observable<MenuSettings> {
    return this.menuSettingsSubject.asObservable();
  }

  constructor(
    private navigationService: NavigationService,
    private modalService: ModalService,
    private storageService: StorageService,
    private authService: AuthService,
    private platformTexts: PlatformTextsService,
    private notificationService: NotificationsService,
    private data: Data,
  ) {}

  clearMenuItems(): void {
    this.menuSettingsSubject.next({ sections: [] });
  }

  public initializeMainMenuItems(dataAvailability: DataAvailabilityInfo): void {
    let menuSections: MenuSection[] = [
      {
        label: 'Monitoring',
        type: 'items',
        items: [
          {
            route: this.navigationService.getProcessesRoute(),
            label: 'Processes',
            icon: 'processes-24',
            permission: Permission.Monitoring,
          },
          {
            route: this.navigationService.getResourcesRoute(),
            label: this.platformTexts.get('Resources'),
            icon: 'resources-24',
            permission: Permission.Monitoring,
          },
          {
            route: this.navigationService.getSessionsRoute(),
            label: this.platformTexts.get('Sessions'),
            icon: 'sessions-24',
            permission: Permission.Monitoring,
          },
          {
            route: this.navigationService.getUtilizationRoute(this.storageService.activeUtilizationSubPage),
            label: 'Utilization',
            icon: 'utilization-24',
            permission: Permission.Monitoring,
            pageType: PageType.Utilization,
            onSubPageChange: s => (this.storageService.activeUtilizationSubPage = <SubPageType.Licenses | SubPageType.Resources>s),
            tabs: [
              {
                route: this.navigationService.getUtilizationRoute(SubPageType.Licenses),
                label: 'Licenses',
                subPageType: SubPageType.Licenses,
              },
              {
                route: this.navigationService.getUtilizationRoute(SubPageType.Resources),
                label: this.platformTexts.get('Resources'),
                subPageType: SubPageType.Resources,
              },
            ],
          },
          {
            route: this.navigationService.getPastScheduleRoute(),
            label: 'Past schedule',
            icon: 'schedule-24',
            permission: Permission.Monitoring,
          },
        ],
      },
      {
        label: 'Orchestration',
        type: 'items',
        items: [
          {
            route: this.navigationService.getAlertsRoute(),
            label: 'Alerts',
            icon: 'alert-24',
            permission: Permission.Orchestration,
            badge$: this.notificationService.unreadNotificationsCount$,
            badgeType: BadgeType.Warning,
          },
          {
            // FUTURE page - should be hidden
            label: 'Process configuration',
            icon: 'process-config-24',
            permission: Permission.Orchestration,
            disabled: true,
            hide: true,
          },
          {
            route: this.navigationService.getSimulatorRoute(SubPageType.Simulator),
            label: 'Simulator',
            icon: 'simulator-24',
            permission: Permission.Simulator,
            pageType: PageType.Simulator,
            tabs: [
              {
                route: this.navigationService.getSimulatorRoute(SubPageType.Simulator),
                label: 'Simulation',
                subPageType: SubPageType.Simulator,
              },
              {
                route: this.navigationService.getSimulatorRoute(SubPageType.SimulatorProcesses),
                label: 'Processes',
                subPageType: SubPageType.SimulatorProcesses,
              },
              {
                route: this.navigationService.getSimulatorRoute(SubPageType.SimulatorSessions),
                label: `${this.platformTexts.get('Sessions')}`,
                subPageType: SubPageType.SimulatorSessions,
              },
              {
                route: this.navigationService.getSimulatorRoute(SubPageType.SimulatorEventLog),
                label: 'Event log',
                subPageType: SubPageType.SimulatorEventLog,
              },
              {
                route: this.navigationService.getSimulatorRoute(SubPageType.LicenseUtilization),
                label: 'License utilization',
                subPageType: SubPageType.LicenseUtilization,
              },
              {
                route: this.navigationService.getSimulatorRoute(SubPageType.ResourceUtilization),
                label: `${this.platformTexts.get('Resources')} utilization`,
                subPageType: SubPageType.ResourceUtilization,
              },
              {
                route: this.navigationService.getSimulatorRoute(SubPageType.PastSchedule),
                label: 'Past schedule',
                subPageType: SubPageType.PastSchedule,
              },
            ],
          },
        ],
      },
      {
        label: 'Analytics',
        type: 'items',
        items: [
          {
            route: this.navigationService.getDetectedIncidentsRoute(),
            label: 'Detected incidents',
            icon: 'incidents-24',
            permission: Permission.Analytics,
          },
          {
            route: this.navigationService.getDiagnosticDashboardRoute(),
            label: 'Diagnostic dashboard',
            icon: 'statistics-24',
            permission: Permission.Analytics,
          },
          {
            route: this.navigationService.getRuntimeAnalysesRoute(),
            label: 'Runtime analysis',
            icon: 'runtime-24',
            permission: Permission.RuntimeAnalysis,
          },
        ],
      },
      {
        label: 'Reporting',
        type: 'items',
        items: [
          {
            route: this.navigationService.getExecutiveOverviewRoute(),
            label: 'Executive overview',
            icon: 'executive-overview-24',
            permission: Permission.ExecutiveOverview,
          },
          {
            route: this.navigationService.getProcessesReportingRoute(),
            label: 'Process reporting',
            icon: 'process-reporting-24',
            permission: Permission.ProcessReporting,
          },
          {
            route: this.navigationService.getMonthlyReportsRoute(),
            label: 'Monthly reports',
            icon: 'reports-24',
            permission: Permission.Reporting,
          },
          {
            route: this.navigationService.getBenchmarkOverviewRoute(),
            label: 'Benchmarking',
            icon: 'benchmarking-24',
            permission: Permission.Benchmarking,
          },
        ],
      },
      {
        label: '',
        type: 'actions',
        items: [
          {
            route: this.navigationService.getOrganizationSettingsRoute(SubPageType.GeneralSettings),
            label: 'Organization settings',
            icon: 'configuration-24',
            permission: Permission.OrganizationSettings,
            pageType: PageType.OrganizationSettings,
            tabs: [
              {
                route: this.navigationService.getOrganizationSettingsRoute(SubPageType.GeneralSettings),
                label: 'General',
                subPageType: SubPageType.GeneralSettings,
              },
              {
                route: this.navigationService.getOrganizationSettingsRoute(SubPageType.BusinessProcessesSettings),
                label: 'Business processes',
                subPageType: SubPageType.BusinessProcessesSettings,
              },
              {
                route: this.navigationService.getOrganizationSettingsRoute(SubPageType.SavingsSettings),
                label: 'Costs and savings',
                subPageType: SubPageType.SavingsSettings,
              },
              {
                route: this.navigationService.getOrganizationSettingsRoute(SubPageType.GroupsSettings),
                label: 'Process groups',
                subPageType: SubPageType.GroupsSettings,
              },
              {
                route: this.navigationService.getOrganizationSettingsRoute(SubPageType.BillingGroupsSettings),
                label: 'Billing groups',
                subPageType: SubPageType.BillingGroupsSettings,
                permissions: [Permission.Reporting],
              },
              {
                route: this.navigationService.getOrganizationSettingsRoute(SubPageType.PendingSessions),
                label: 'Pendings',
                subPageType: SubPageType.PendingSessions,
                permissions: [Permission.Orchestration],
              },
              {
                route: this.navigationService.getOrganizationSettingsRoute(SubPageType.ConditionStates),
                label: 'Condition states',
                subPageType: SubPageType.ConditionStates,
                permissions: [Permission.Orchestration],
              },
              {
                route: this.navigationService.getOrganizationSettingsRoute(SubPageType.OrchestrationSettings),
                label: 'Orchestration',
                subPageType: SubPageType.OrchestrationSettings,
                permissions: [Permission.Orchestration],
              },
              {
                route: this.navigationService.getOrganizationSettingsRoute(SubPageType.Simulator),
                label: 'Simulator',
                subPageType: SubPageType.Simulator,
                permissions: [Permission.Simulator],
              },
              {
                route: this.navigationService.getOrganizationSettingsRoute(SubPageType.ReportingSettings),
                label: 'Reporting',
                subPageType: SubPageType.ReportingSettings,
                permissions: [Permission.Reporting, Permission.ExecutiveOverview],
              },
              {
                route: this.navigationService.getOrganizationSettingsRoute(SubPageType.BenchmarkingSettings),
                label: 'Benchmarking',
                subPageType: SubPageType.BenchmarkingSettings,
                //permissions: [Permission.Benchmarking],
                featureFlags: [FeatureFlags.BenchmarkingSettings],
              },
              {
                route: this.navigationService.getOrganizationSettingsRoute(SubPageType.NotificationsSettings),
                label: 'Notifications',
                subPageType: SubPageType.NotificationsSettings,
                permissions: [Permission.OrganizationSettings, Permission.Analytics],
                featureFlags: [FeatureFlags.Alerting],
              },
              {
                route: this.navigationService.getOrganizationSettingsRoute(SubPageType.UsersSettings),
                label: 'Users',
                subPageType: SubPageType.UsersSettings,
              },
            ],
          },
          {
            label: 'Help & feedback',
            icon: 'help-24',
            onClick: () => {
              this.modalService.open(ContactUsModalComponent);
            },
          },
        ],
      },
    ];

    menuSections.forEach(m => (m.items = m.items.filter(i => i.permission == null || this.authService.hasPermission(i.permission))));
    menuSections = menuSections.filter(m => m.items.length > 0);

    if (!dataAvailability.isValid) {
      menuSections = menuSections.filter(m => m.type == 'actions');
    }

    const itemCount = MathHelper.sum(
      menuSections.filter(section => section.type === 'items' || section.type === 'submenu'),
      s => s.items.length,
    );

    this.menuSettingsSubject.next({
      sections: menuSections,
      isMenuExpanded: itemCount < 3 ? false : null,
    });
  }

  public initializeAdminMenuItems(): void {
    const menuSections: MenuSection[] = [
      {
        label: 'Admin',
        type: 'submenu',
        parentRoute: ['/'],
        items: [
          {
            route: this.navigationService.getAdminOrganizationsRoute(),
            label: 'Organizations',
            icon: 'organization-24',
          },
          {
            route: this.navigationService.getAdminUsersRoute(),
            label: 'Users',
            icon: 'account-24',
          },
          {
            route: this.navigationService.getAdminLicensesRoute(),
            label: 'Licenses',
            icon: 'shield-check-24',
          },
        ],
      },
      {
        label: '',
        type: 'actions',
        items: [
          {
            label: 'Help & feedback',
            icon: 'help-24',
            onClick: () => {
              this.modalService.open(ContactUsModalComponent);
            },
          },
        ],
      },
    ];

    this.menuSettingsSubject.next({ sections: menuSections });
  }

  public initializeRuntimeAnalysisMenuItems(processId: string): void {
    const menuSections: MenuSection[] = [
      {
        label: 'Runtime analysis',
        type: 'submenu',
        parentRoute: this.navigationService.getRuntimeAnalysesRoute(),
        items: [
          {
            route: this.navigationService.getOverviewRoute(processId),
            label: 'Overview',
            icon: 'overview-24',
          },
          {
            route: this.navigationService.getActionableInsightsRoute(processId),
            label: 'Actionable insights',
            icon: 'insight-24',
            badge$: this.data.actionableInsightsByStateChanged.pipe(map(() => this.data.masterProcess?.actionableInsightsByState.get('')?.size)),
            badgeType: BadgeType.Count,
          },
          {
            route: this.navigationService.getProcessMapRoute(processId),
            label: 'Process map',
            icon: 'map-24',
          },
          {
            route: this.navigationService.getVariantAnalysisRoute(processId),
            label: 'Variant analysis',
            icon: 'variant-analysis-24',
          },
          {
            route: this.navigationService.getCostAnalysisRoute(processId),
            label: 'Cost analysis',
            icon: 'cost-analysis-24',
          },
        ],
      },
      {
        label: '',
        type: 'actions',
        items: [
          {
            route: [],
            label: 'Assign this analysis',
            icon: 'assign',
            disabled: true,
            hide: true,
          },
          {
            route: [],
            label: 'Analysis settings',
            icon: 'settings',
            disabled: true,
            hide: true,
          },
          {
            label: 'Help & feedback',
            icon: 'help-24',
            onClick: () => {
              this.modalService.open(ContactUsModalComponent);
            },
          },
        ],
      },
    ];

    this.menuSettingsSubject.next({ sections: menuSections });
  }
}
