<shared-modal [modalTitle]="title">
  <ng-container modalBody>
    <form [formGroup]="form" (ngSubmit)="save()" autocomplete="off">
      <p>
        Mark <b>{{userActionableInsight.location.activityLabels[userActionableInsight.location.activityLabels.length-1]}}</b> as a potential place for optimization so you can
        easily come back to it later or pass it on to another team member.
      </p>
      <div class="form-group">
        <b>Title</b>
        <input name="title" class="form-control" formControlName="title" />
      </div>
      <div class="form-group message-area">
        <b>Note</b> (optional - max. 1,000 characters)
        <textarea class="form-control" autofocus-select formControlName="note" maxlength="1000"></textarea>
      </div>
      <div class="btn-group-flex">
        <button type="submit" class="btn btn-primary" [disabled]="form.invalid">Save</button>
      </div>
    </form>
  </ng-container>
</shared-modal>
