import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatOption } from '@angular/material/core';

@Component({
  selector: 'shared-select-all-option',
  templateUrl: './select-all-option.component.html',
  styleUrls: ['./select-all-option.component.scss'],
})
export class SelectAllOptionComponent {
  @Input() text = 'Select all';
  @Input() set selected(value: boolean) {
    if (value) {
      this.option.select();
    } else {
      this.option.deselect();
    }
  }

  @Output() selectedChange = new EventEmitter<boolean>();

  @ViewChild(MatOption, { static: true }) option: MatOption;
}
