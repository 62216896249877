import { ApplicationRef, ComponentRef, Directive, ElementRef, Input, OnChanges, OnInit, SimpleChanges, createComponent } from '@angular/core';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { SVG_CONFIG } from '@ngneat/svg-icon/lib/types';

@Directive({
  selector: 'button[svgIcon]',
})
export class SvgIconDirective implements OnInit, OnChanges {
  @Input() svgIcon = '';
  @Input() iconSize: keyof SVG_CONFIG['sizes'];
  @Input() iconClass: string;

  private iconCmpRef: ComponentRef<SvgIconComponent>;

  constructor(private el: ElementRef, private appRef: ApplicationRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.iconCmpRef == null) {
      return;
    }

    if (changes.iconClass != null) {
      if (changes.iconClass.previousValue != null) {
        this.iconCmpRef.location.nativeElement.classList.remove(changes.iconClass.previousValue);
      }

      if (changes.iconClass.currentValue != null) {
        this.iconCmpRef.location.nativeElement.classList.add(changes.iconClass.currentValue);
      }
    }

    if (changes.iconSize?.currentValue != null) {
      this.iconCmpRef.setInput('size', changes.iconSize.currentValue);
    }

    if (changes.svgIcon?.currentValue != null) {
      this.iconCmpRef.setInput('key', changes.svgIcon.currentValue);
    }
  }

  ngOnInit(): void {
    this.iconCmpRef = createComponent(SvgIconComponent, { environmentInjector: this.appRef.injector });
    this.iconCmpRef.setInput('key', this.svgIcon);
    this.iconCmpRef.setInput('size', this.iconSize);
    if (this.iconClass != null) {
      this.iconCmpRef.location.nativeElement.classList.add(this.iconClass);
    }
    this.appRef.attachView(this.iconCmpRef.hostView);
    (<HTMLElement>this.el.nativeElement).appendChild(this.iconCmpRef.location.nativeElement);
  }
}
