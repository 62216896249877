import { LuxonDateAdapter, MatLuxonDateAdapterOptions } from '@angular/material-luxon-adapter';
import { OrganizationService } from '../services/organization.service';
import { DayOfWeek } from '../enums/generated.enums';
import { Inject } from '@angular/core';

export class CustomDateAdapter extends LuxonDateAdapter {
  private firstDayOfWeek = 1;
  readonly weekDayMap: { [key in DayOfWeek]: number } = {
    [DayOfWeek.Sunday]: 0,
    [DayOfWeek.Monday]: 1,
    [DayOfWeek.Tuesday]: 2,
    [DayOfWeek.Wednesday]: 3,
    [DayOfWeek.Thursday]: 4,
    [DayOfWeek.Friday]: 5,
    [DayOfWeek.Saturday]: 6,
  };
  constructor(
    locale: string,
    options?: MatLuxonDateAdapterOptions,
    @Inject(OrganizationService) protected organizationService?: OrganizationService,
  ) {
    super(locale, options);
    this.setLocale(this.locale);
  }

  getFirstDayOfWeek(): number {
    const weekDay = this.organizationService?.firstDayOfWeek;
    if (weekDay) {
      this.firstDayOfWeek = this.weekDayMap[weekDay];
    }
    return this.firstDayOfWeek;
  }

  getDayOfWeekNames(style: 'long' | 'short' | 'narrow') {
    return super.getDayOfWeekNames(style == 'narrow' ? 'short' : style);
  }
}
