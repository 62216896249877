import { Component } from '@angular/core';
import { BaseModalDialogDirective } from '../../../directives/base-modal-dialog.directive';
import { SubPageType } from '../../../enums/page-type.enum';
import { NavigationService } from '../../../services/navigation.service';

@Component({
  selector: 'app-hotkeys-help-modal',
  templateUrl: './hotkeys-help-modal.component.html',
  styleUrls: ['./hotkeys-help-modal.component.scss'],
})
export class HotkeysHelpModalComponent extends BaseModalDialogDirective {
  get processMapOnly() {
    return this._navigationService.isOnSubPage(SubPageType.ProcessMap);
  }

  constructor(private _navigationService: NavigationService) {
    super();
  }

  keyChars(chars: string): string[] {
    return chars.split('.');
  }
}
