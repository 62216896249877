<div class="pointee-button">
  <button
    type="button"
    class="btn btn-round btn-icon"
    [ngClass]="{'selected': btnSelected, 'disabled': disabled }"
    [ngClass]="[size, btnColor]"
    [tooltip]="btnTooltip"
    placement="bottom"
    [display]="!btnSelected"
    [disabled]="disabled"
    (click)="clickAction()"
  >
    <svg-icon key="{{btnIcon}}"></svg-icon>
  </button>
</div>
