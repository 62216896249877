import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'shared-select-search',
  templateUrl: './select-search.component.html',
  styleUrls: ['./select-search.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, multi: true, useExisting: SelectSearchComponent }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectSearchComponent implements ControlValueAccessor {
  @Input() searchPlaceholder = 'Search...';

  private _filter: string;
  private onChange = (filter: string) => {};
  private onTouched = () => {};
  private touched = false;

  get filter(): string {
    return this._filter;
  }

  set filter(filter: string){
    this.markAsTouched();
    this._filter = filter;
    this.onChange(this._filter);
  }

  writeValue(filter: string): void {
    this._filter = filter;
  }

  registerOnChange(onChange: (filter: string) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  setDisabledState?(isDisabled: boolean): void { }

  markAsTouched(): void {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }
}
