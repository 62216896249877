<!-- INPUT with icon -->
<div class="custom-input-icon">
  <input
    #iconInput
    type="text"
    name="input-icon"
    aria-describedby="custom input text with icon"
    [(ngModel)]="value"
    placeholder="{{placeholder || '...'}}"
    maxlength="100"
    class="input-icon"
    autocomplete="off"
  />
  <span class="custom-icon">
    <svg-icon [key]="icon" size="sm"></svg-icon>
  </span>
  <span class="clear-icon" (click)="onClear($event)">
    <svg-icon key="close" size="sm"></svg-icon>
  </span>
</div>
