<!-- SEARCH INPUT -->
<ng-container *ngIf="dataAvailability">
  <div class="interval-dropdown">
    <button type="button" [disabled]="disabled" [dropdownTriggerFor]="dropdown" (dropdownClosed)="onClose()">
      <svg-icon key="calendar" class="calendar-icon"></svg-icon>
      <span class="label">{{selectedRangeLabel}}</span>
      <span class="chevron-icon">
        <svg-icon key="arrow-button-down"></svg-icon>
      </span>
    </button>
  </div>

  <app-dropdown #dropdown [closeOnClick]="false">
    <shared-calendar-body
      [selectedRange]="selectedRange"
      [dataAvailability]="dataAvailability"
      [activeDate]="activeDate"
      [showTodayButton]="config?.showTodayButton ?? true"
      [showYesterdayButton]="config?.showYesterdayButton ?? true"
      [show7DaysButton]="config?.show7DaysButton ?? true"
      [show30DaysButton]="config?.show30DaysButton ?? true"
      (selectedRangeChange)="onSelectedRangeChanged($event)"
    ></shared-calendar-body>
  </app-dropdown>
</ng-container>
