import { Component, Input } from '@angular/core';
import { DateHelper, DateRangeFormat } from '../../helpers/date.helper';

@Component({
  selector: 'shared-process-interval',
  templateUrl: './process-interval.component.html',
  styleUrls: ['./process-interval.component.scss'],
})
export class ProcessIntervalComponent {
  @Input() from: Date;
  @Input() to: Date;
  @Input() displayIcon = false;

  get formattedInterval(): string {
    if (!this.from || !this.to) {
      return '';
    }
    return DateHelper.formatDateRange(this.from, this.to, DateRangeFormat.DateOnly);
  }
}
