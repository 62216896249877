import { Component, Input } from '@angular/core';

@Component({
  selector: 'shared-info-message-bar',
  templateUrl: './info-message-bar.component.html',
  styleUrls: ['./info-message-bar.component.scss'],
})
export class InfoMessageBarComponent {
  @Input() message: string;
}
